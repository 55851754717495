import React from 'react';
import BTCVCCard from './BTCVCCard';

function BTCLanding(props) {

	return (
		<div className = "card" style = {{marginTop: "130px"}}>
			<div className = "card-body">
				<div className = "row text-center">
					<div className = "col">
						<h2 className = "pt-1">
							Welcome To Blockchain Tradable Certificates
						</h2>
						<BTCVCCard />
					</div>
				</div>
				<div className = "row justify-content-center">
					<div className = "col-6 pt-4">
						<button className = "btn btn-block btn-lg btn-outline-info"
							onClick = {() => props.changePath("/btc/create-new-instrument")}
						>
							Create New Certificate
						</button>
					</div>
				</div>
				<div className = "row justify-content-center pt-4">
					<div className = "col-3">
						<button className = "btn btn-block btn-lg btn-outline-danger"
							onClick = {() => props.changePath("/btc/pending")}
						>
							Pending
						</button>
					</div>
					<div className = "col-3">
						<button className = "btn btn-block btn-lg btn-outline-success"
							onClick = {() => props.changePath("/btc/approved")}
						>
							Approved
						</button>
					</div>
				</div>
				<div className = "row justify-content-center">
					<div className = "col-6 pt-4">
						<button className = "btn btn-block btn-lg btn-outline-info"
							onClick = {() => props.changePath("/btc/analytics")}
						>
							Analytics
						</button>
					</div>
				</div>
				<div className = "row justify-content-center">
					<div className = "col-6 pt-4">
						<button className = "btn btn-block btn-lg btn-outline-info"
							onClick = {() => props.changePath("/btc/settings")}
						>
							Settings
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BTCLanding;