import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import Loader from "react-loader-spinner";
import axios from "axios";
import { Card, Dropdown } from "react-bootstrap";
import { RESTORE_VC } from "../constants/RestoreApiConfig";
import { Col, Row } from "reactstrap";
import ImageModal from "./ImageModal";
import SelfieModal from "./SelfieModal";
import styled from "styled-components";
import { GetImage } from "../services/KycService";
import { Table, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import KycDetails from "./KycDetails";
import PublicKycDetails from './PublicKycDetails';
import { DID_RESTORE_BUCKET_NAME } from "../config.js"

const RestoreVCRequestDetails = (props) => {
  const userData = props.history.location.state.userData;
  const req_id = props.history.location.state.req_id;
  const idToken = props.history.location.state.idToken;
  const [img, setImg] = useState("");
  const [selfieimg, setSelfieImg] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [accBtn, setAccBtn] = useState(false);
  const [rejBtn, setRejBtn] = useState(false);
  const preprocessData = (rowData, reqId = false) => {
    if (reqId) {
      return rowData.filter((data) => data.req_id !== reqId);
    } else {
      return rowData.map((data) => ({
        ...data,
        full_name:
          data.kycType === "PublicDid"
            ? data.entityName
            : `${data["givenName"]} ${data["familyName"]}`
      }));
    }
  };
  const restoreStatus = (action, req_id) => {
    axios
      .post(
        RESTORE_VC,
        {
          action,
          req_id,
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      )
      .then((res) => {
        if (res?.data?.error) {
          setFailed(true);
        } else {
          setData(preprocessData(data, req_id));
          setFailed(false);
        }
      })
      .catch((_) => setFailed(true))
      .finally(
        (_) => setLoading(false)

        // props.history.push("/restore_vc_request")
      );
  };

  const restoreVCAction = (action, req_id) => {
    setLoading(true);
    // console.log(action);
    if (action === "approve") {
      restoreStatus(action, req_id);
      Swal.fire({
        icon: "success",
        title: "Request Approved",
        text: "Restore wallet request approved",
      }).then((result) => {
        props.history.push("/restore_vc_request");
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          restoreStatus(action, req_id);
          Swal.fire({
            icon: "success",
            title: "Request declined",
            text: "Restore wallet request declined",
          }).then((result) => {
            props.history.push("/restore_vc_request");
          });
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    }
    // axios
    //   .post(
    //     RESTORE_VC,
    //     {
    //       action,
    //       req_id,
    //     },
    //     {
    //       headers: {
    //         Authorization: idToken,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     if (res?.data?.error) {
    //       setFailed(true);
    //     } else {
    //       setData(preprocessData(data, req_id));
    //       setFailed(false);
    //     }
    //   })
    //   .catch((_) => setFailed(true))
    //   .finally(
    //     (_) => setLoading(false),

    //       props.history.push("/restore_vc_request")

    //   );
  };

  useEffect(() => {
    const fetchImageLink = async () => {
      const img_url = userData.document_image_url;
      const S3ObjKeyArr = img_url.split("/");
      const S3ObjKey =
        S3ObjKeyArr[S3ObjKeyArr.length - 2] +
        "/" +
        S3ObjKeyArr[S3ObjKeyArr.length - 1];
      //  let key;
      try {
        const path_image = S3ObjKey;
        const profile_image = await GetImage(DID_RESTORE_BUCKET_NAME, path_image);
        setImg(profile_image.message);
      } catch (err) {
        console.log(err);
      }
    };
    fetchImageLink();

    const fetchSelfieLink = async () => {
      const img_url = userData.selfie_image_url;
      const S3ObjKeyArr = img_url.split("/");
      const S3ObjKey =
        S3ObjKeyArr[S3ObjKeyArr.length - 2] +
        "/" +
        S3ObjKeyArr[S3ObjKeyArr.length - 1];
      //  let key;
      try {
        const path_image = S3ObjKey;
        const profile_image = await GetImage(DID_RESTORE_BUCKET_NAME, path_image);
        setSelfieImg(profile_image.message);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSelfieLink();
  }, [userData]);
  const kycDetailsShow = () => {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };
  return (
    <>
      <Body>
        <Container>
          <Wrapper>
            <div style={action_btn}>
              {/* {kycStat === "nil" ? (
                <Loader type="ThreeDots" color="black" />
              ) : ( */}
              <div class="ui buttons">
                <button
                  // disabled={accBtn}
                  class="ui positive button"
                  // onClick={() => onStatChange("A")}
                  onClick={() => restoreVCAction("approve", req_id)}
                >
                  Accept Request
                </button>
                <div class="or"></div>
                <button
                  // disabled={rejBtn}
                  class="ui negative button"
                  // onClick={() => onStatChange("D")}
                  onClick={() => restoreVCAction("decline", req_id)}
                >
                  Reject Request
                </button>
              </div>
              {/* )} */}
            </div>
            <br />
            <br />
            <br />

            <Row>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Restore Reason</Card.Title>

                    <Card.Text>
                      <p>{userData.restore_reason}</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Divider />
            <Row>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Document Details</Card.Title>

                    <Card.Text>
                      <Card style={card_style2}>
                        <p
                          style={img_user}
                          variant="primary"
                          onClick={() => setModalShow2(true)}
                        >
                          <Card.Img style={img_user} variant="top" src={img} />
                        </p>
                        <ImageModal
                          img={img}
                          show={modalShow2}
                          onHide={() => setModalShow2(false)}
                        />
                      </Card>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Selfie</Card.Title>

                    <Card.Text>
                      <Card style={card_style2}>
                        <p
                          style={img_user}
                          variant="primary"
                          onClick={() => setModalShow(true)}
                        >
                          <Card.Img
                            style={img_user}
                            variant="top"
                            src={selfieimg}
                          />
                        </p>
                        <SelfieModal
                          img2={selfieimg}
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </Card>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Divider />
            <Button
              variant="success"
              style={kycButton}
              // onClick={() =>
              //   props.history.push("/kyc_user_details", { uid: userData.uid })
              // }
              onClick={kycDetailsShow}
            >
              {userData.kycType === "PublicDid" ? "View KYB details" : "View KYC details"}
            </Button>
          </Wrapper>
          <div id="myDIV" style={{ display: "none" }}>
            {userData.kycType === "PublicDid" ? <PublicKycDetails uid={userData.uid} /> : <KycDetails uid={userData.uid} />}
          </div>
        </Container>
      </Body>
    </>
  );
};
export default RestoreVCRequestDetails;

const card_style = {
  position: "relative",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
};
const card_style2 = {
  width: "100%",
  height: "100%",
};
const card_title = {
  background: "#a7a7a7",
  textAlign: "center",
  color: "black",
};
const img_user = {
  height: "250px",
  width: "100%",
  cursor: "pointer",
};
const action_btn = {
  position: "relative",
  left: "50%",
  transform: "translate(-15%)",
};
const kycButton = {
  position: "relative",
  left: "50%",
  transform: "translate(-50%)",
  marginTop: 20,
};

const Body = styled.div`
  margin-top: 100px;
  width: 100%;
  height: 100%;
  padding: 40px;
  margin-bottom: 50px;
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
const Wrapper = styled.div`
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  height: 20px;
  background-color: #7e5737;
  margin-top: 10px;
  margin-bottom: 10px;
`;
