import React, {useState, useEffect, useRef} from 'react';
//import VCTable from './VCTable';
import { VCList, getDID, VCBYSDK } from "../services/vcService";
import { utils, token, connection } from "mui-metablockchain-sdk";
import { PAIRWISE_API, netstat, PAIRWISE_WEBRTC } from "../config";
import TokenAdminVC from './VCTypes/TokenAdminVC';
import UndecodedVC from './VCTypes/UndecodedVC';
 
function VCSettings(props) {
	const [subC, setSubC] = useState(1);
  	const [myVC, setMyVC] = useState(null);
	const [sdkVC, setSdkVC] = useState(null);
  	const [selectedVCFromAPI, setSelectedVCFromAPI] = useState(null);
  	const [selectedVCFromSDK, setSelectedVCFromSDK] = useState(null);
  	const [myDID, setMyDID] = useState(null);
  	const webSocket = useRef(null);
  	const [socketMessages, setSocketMessages] = useState([]);
	const [type, setType] = useState(null);
   const [decodedObj, setDecodedObj] = useState(null);
   const [msgStat, setMsgStat] = useState(0)


	useEffect(() => {
    webSocket.current = new WebSocket(PAIRWISE_WEBRTC);

    webSocket.current.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log("1st:", data);

      setSocketMessages((prev) => [...prev, data]);
    };
    webSocket.current.onclose = () => {
      webSocket.current.close();
    };
    return () => webSocket.current.close();
  }, []);

    useEffect(() => {
        decodeObj();
    }, []);

    const decodeObj = async () => {
        let api = await connection.buildConnection(netstat);
        let tokenList = await token.getTokenList(api);
        setDecodedObj(tokenList);
    };

  useEffect(() => {
    let res = getDID();
    res.then(function (v) {
      if (Array.isArray(v)) {
        setMyDID(v[0].did);
      } else {
        setMyDID(v);
      }
    });
  }, []);

  useEffect(() => {
    let arr = [];
    let newArr = [];
    let arrSDK;
    if (myDID !== null) {
      arrSDK = VCBYSDK(myDID);
    }
    if (arrSDK) {
      arrSDK.then(function (v) {
        if (Array.isArray(v)) {
          for (let i = 0; i < v.length; ++i) {
            if (utils.hexToString(v[i].owner) === myDID) {
				      if (v[i].hasOwnProperty("is_vc_used") && v[i].vc_type !== "TokenVC" && v[i].Status === "Active") {
					      if(!v[i].is_vc_used) {
						      newArr.push(v[i]);
					      }
				      }
            }
          }
          if(newArr.length <= 0) {
            setMsgStat(3);
          } 
        } else {
          setMsgStat(1);
        }
        if (newArr.length > 0) {
          setSdkVC(newArr);
        }
      });
    }
    let res = VCList(
      "handleTokenVC",
      JSON.stringify({ action: "get_request" })
    );
    res.then(function (v) {
      if (Array.isArray(v)) {
        for (let i = 0; i < v.length; ++i) {
          if (v[i].token_owner === myDID) {
			  if(v[i].hasOwnProperty("request_status")) {
					 arr.push(v[i]);
			  }
          }
        }
        if(arr.length <= 0) {
          setMsgStat(3);
        }
      } else {
          setMsgStat(1);
        }
      if (arr.length > 0) {
        setMyVC(arr);
      }
    });
  }, [myDID]);

  function statusType(value) {
    if (value.request_status === "P") {
      return (
        <React.Fragment>
          <i
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#cf9823",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></i>
          <span>&nbsp;Pending</span>
        </React.Fragment>
      );
    } else if (value.request_status === "C") {
      return (
        <React.Fragment>
          <i
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#cf2323",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></i>
          <span>&nbsp;Cancelled</span>
        </React.Fragment>
      );
    } else if (value.request_status === "S") {
      return (
        <React.Fragment>
          <i
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#23c6cf",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></i>
          <span>&nbsp;Submitted</span>
        </React.Fragment>
      );
    } else if (value.request_status === "A") {
      if (value.hasOwnProperty("vc_id")) {
        return (
          <React.Fragment>
            <i
              style={{
                height: "1rem",
                width: "1rem",
                backgroundColor: "#23cf26",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></i>
            <span>&nbsp;Accepted</span>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <i
              style={{
                height: "1rem",
                width: "1rem",
                backgroundColor: "#ffff4d",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></i>
            <span>&nbsp;In-Review</span>
          </React.Fragment>
        );
      }
    } else if (value.request_status === "D") {
      return (
        <React.Fragment>
          <i
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#2323cf",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></i>
          <span>&nbsp;Declined</span>
        </React.Fragment>
      );
    } else if (value.request_status === "R") {
      return (
        <React.Fragment>
          <i
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#ffff4d",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></i>
          <span>&nbsp;In-Review</span>
        </React.Fragment>
      );
    } else if (value.hasOwnProperty("vc_status")) {
      return <React.Fragment>{value.vc_status}</React.Fragment>;
    }
  }

  function getAPIRow() {
    if (myVC !== null) {
      return myVC.map((item, index) => (
        <tr key={index}>
          <th>{item.currency_code}</th>
          <td>{item.token_name}</td>
          <td>{statusType(item)}</td>
          <td>Token VC</td>
          <td>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setSubC(2);
                setSelectedVCFromAPI(index);
				        setType("Token");
              }}
            >
              View Details
            </button>
          </td>
        </tr>
      ));
    } else {
      if(msgStat === 0) {
      return (
        <React.Fragment>
          <tr>
            <td>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>&emsp;
              <span>Fetching From Database</span>
            </td>
          </tr>
        </React.Fragment>
      );
      } else if (msgStat === 1) {
        return (
          <React.Fragment>
          <tr>
            <td>
              <span>Something Went Wrong</span>
            </td>
          </tr>
        </React.Fragment>
        );
      } else if (msgStat === 3) {
        return (
          <React.Fragment>
          <tr>
            <td>
              <span>No Token VCs</span>
            </td>
          </tr>
        </React.Fragment>
        );
      }
    }
  }

  function getIDandName(obj) {
    let arr = [];
      if(decodedObj !== null) {
        const ccode = utils.decodeHex(
          obj.vc_property,
          'SlashMintTokens',
        ).currency_code;
        let cc = utils.hexToString(ccode);
        for(let i = 0; i < decodedObj.length; i++) {
           if( cc === decodedObj[i].currencyCode ) {
             arr.push (
               <React.Fragment key = {i}>
                  <th>{decodedObj[i].currencyCode}</th>
                  <td>{decodedObj[i].name}</td>
                </React.Fragment>
             );
           }
        }
      }
    if(arr.length > 0) {
      for (let i = 0; i < arr.length; ++i) {
        return arr[i];
      }
    } else {
      return (
          <React.Fragment>
            <th>Cannot Resolve Currency Code</th>
            <td>Cannot Resolve Currency Name</td>
          </React.Fragment>
      );
    }
  }

  function getSDKRow() {
    if (sdkVC !== null) {
      return sdkVC.map((item, index) => 
        <tr key={index}>
          {getIDandName(item)}
          <td>{item.vc_type}</td>
          <td>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setSubC(2);
                setSelectedVCFromSDK(index);
				        setType("Undecoded")
              }}
            >
              View Details
            </button>
          </td>
        </tr>
      );
    } else {
      if(msgStat === 0) {
      return (
        <React.Fragment>
          <tr>
            <td>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>&emsp;
              <span>Fetching From Blockchain</span>
            </td>
          </tr>
        </React.Fragment>
      );
      } else if (msgStat === 1) {
        return (
          <React.Fragment>
          <tr>
            <td>
              <span>Something Went Wrong</span>
            </td>
          </tr>
        </React.Fragment>
        );
      } else if (msgStat === 3) {
        return (
          <React.Fragment>
          <tr>
            <td>
              <span>No Unused VCs</span>
            </td>
          </tr>
        </React.Fragment>
        );
      }
    }
  }

	function subComponent() {
    	if (subC === 1) {
      		return (
				<div className = "card">
					<div className = "card-body">
						<h6>My Token VC</h6>
						<div className="mt-3">
          					<table className="table">
            					<thead>
              						<tr className="table-primary">
                						<th scope="col">Currency Code</th>
										        <th scope="col">Token Name</th>
										        <th scope="col">Status</th>
                						<th scope="col">VC Type</th>
                						<th scope="col">Action</th>
              						</tr>
            					</thead>
            					<tbody>
              						{getAPIRow()}
            					</tbody>
          					</table>
        				</div>
						<h6 className = "mt-5">My Unused VCs</h6>
						<div className="mt-3">
          					<table className="table">
            					<thead>
              						<tr className="table-primary">
                						<th scope="col">Currency Code</th>
										        <th scope="col">Token Name</th>
                						<th scope="col">VC Type</th>
                						<th scope="col">Action</th>
              						</tr>
            					</thead>
            					<tbody>
              						{getSDKRow()}
            					</tbody>
          					</table>
        				</div>
					</div>
				</div>
			);
    	} else if (subC === 2) {
      		if (type === "Token") {
        		let apiObj = myVC[selectedVCFromAPI];
				return <TokenAdminVC vc = {apiObj} submitForm = {submitForm} myDID = {myDID} issueTokens = {issueTokens}/>
      		} else if (type === "Undecoded") {
				let sdkObj = sdkVC[selectedVCFromSDK];
				return <UndecodedVC vc={sdkObj} vcAction = {vcAction} myDID = {myDID}/>
			}
			
     	}
  	}

    const issueTokens = async (obj, amount, issueDID) => {
    let myArr = [obj];
    Object.assign(myArr[0], { vcDID: issueDID, Amount: amount });
    let names = myDID;
    send({
      type: "vc-create",
      name: names,
      source: "yidindji",
      token: null,
      package: {
        myArr,
        DID: myDID,
      },
    });
    setSubC(1);
  };


	const send = (data) => {
        webSocket.current.send(JSON.stringify(data));
	};

	const submitForm = async (input) => {
		console.log(input);
		let myArr = [myVC[selectedVCFromAPI]];
        Object.assign(myArr[0], { vcDID: input.vcDID.substring(13), Amount: input.Amount, issuerList: input.issuerList });
        let names = myDID;
        send({
           	type: "vc-create",
            name: names,
            source: "yidindji",
            token: input.amount,
            package: {
               	myArr,
            	DID: input.vcDID,
            },
    	});
      setSubC(1);
	}
	
	const vcAction = async (obj, action, did) => {
    	let myArr = [obj];
    	if (action !== "Transfer") {
      		Object.assign(myArr[0], { Action: action });
    	} else {
      		Object.assign(myArr[0], { vcDID: did, Action: action });
    	}
    	const names = myDID;
    	send({
     		type: "vc-action",
      		name: names,
      		source: "yidindji",
      		token: null,
      		package: {
       			myArr,
        		DID: did,
      		},
    	});
      setSubC(1);
  	};


	return (
		<div className = "container-fluid card" style = {{marginTop: "20px"}}>
			<div className = "card-body">
				<div className = "row justify-content-between bg-light">
					<div className = "col">
						<h4 className = "py-2">
							MY CURRENCY
						</h4>
					</div>
					<div className = "col-2 py-2">
						<button className = "btn btn-outline-danger btn-sm" onClick = {() => props.history.push("/vc")}>
							Back To Main
						</button>
					</div>
				</div>
				{subComponent()}
			</div>
		</div>
	);
}

export default VCSettings;