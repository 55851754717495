import React from 'react'
import styled from 'styled-components'

const Homedash = () => {
    return (
        <>
        <Main>
            <h1> Hello world </h1>
        </Main>
            
        </>
    )
}

export default Homedash
const Main = styled.div`
margin-top: 5rem;
`
