import React, {useState} from 'react'
import {Navbar, Nav, Container} from 'react-bootstrap'
import yidindji_logo from "../Assets/icons/yidindji_logo.png";
import styled, { css } from "styled-components";
import { NavLink as Link, useHistory } from "react-router-dom";
import "../styles/HeaderMobile_style.css";
import auth from "../services/loginService";

const HeaderMobile=()=> {
    const [expanded, setExpanded] = useState(false);
    return ( 
    <>
 <Navbar bg="light" expand="lg" style={{paddingTop: '0', width: "100%", position: "fixed", top: "0", zIndex: "100"}} expanded={expanded}>
  <Container>
  
  <Navbar.Brand>
  <Link onClick={() => setExpanded(false)} to="/">
      <Logo>
      <img
        src={yidindji_logo}
        width="60"
        height="60"
        className="div_logo"
        alt="Brand Logo"
      />
       
      </Logo>
      </Link>
      <h6 className="brand_logo_div">Yidindji</h6> 
    </Navbar.Brand>
    
    <Navbar.Toggle aria-controls="basic-navbar-nav" id="navbar-toggler" onClick={() => setExpanded((prevExpanded) => (prevExpanded = !prevExpanded))}/>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="nav_mobile_div">

      <NavLink><Link  className="Nav_Mobile_burger" onClick={() => setExpanded(false)} to="/">KYC Dashboard</Link></NavLink>

<NavLink className="Nav_Mobile_burger"><Link onClick={() => setExpanded(false)} to="/usersignup">User SignUp</Link></NavLink>

<NavLink className="Nav_Mobile_burger"><Link onClick={() => setExpanded(false)} to="/usersignin">User SignIn</Link></NavLink>
<NavLink className="Nav_Mobile_burger">
               <Link
                to="/login"
                onClick={() => {
                  // localStorage.removeItem("login");
                  // localStorage.removeItem("did");
                  auth.logout();
                  window.location = "/login";
                }}
              >  SignOut
               </Link>
              </NavLink>
        
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
</>
    )
}
export default HeaderMobile;

const Logo = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-left: 0rem;
>img  {
display: flex;
justify-content: center;
align-items: center;
}
>h6 {
  color: black;
  font-weight: 600;
  margin-top: 1rem;
  font-size: 1.5rem;
  text-decoration: none;
}
  ${'' /* > img {
    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
      position: relative;
      ${'' /* left: 90%; */}
    }
    @media (max-width: 481px) {
      left: 70%;
    }
    @media (max-width: 400px) {
      left: 50%;
    }
  } */}
`
const NavLink = styled(Nav.Link)`
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    }
  >a {
    font-size: 1.2rem;
    color: black;
    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
    }
  }
`