import React, {useState, useContext} from 'react';
import {myVC} from '../constants/BTCTestData/BTCVC';
import VCContext from './VCContext';

function BTCSettingsChangeVC(props) {

	const [vc, setVc] = useState({});
	const {currentVC, setCurrentVC} = useContext(VCContext)

	function back() {
		props.back();
	}

	function confirmVc() {
		setCurrentVC(vc);
		props.back();
	}

	function selectVC(id, obj) {
		const vcb = document.querySelectorAll(".vcButtons");
		for(let i = 0; i < vcb.length; ++i) {
			vcb[i].classList.remove("active");
		}
		document.getElementById(id).classList.add("active");
		setVc({...vc, ...obj});
	}

	function getVCList() {
		const listItems = myVC.map((item) => 
			<button 
				className = "btn btn-block my-3 btn-outline-dark text-left vcButtons"
				type = "button"
				key = {item.id}
				id = {`vcButtons${item.id}`}
				onClick = {() => selectVC(`vcButtons${item.id}`, {...item})}
			>
				Organisation Name: &nbsp; {item.organisationName}<br/>
				Position: &nbsp; {item.position}<br/>
				Clearance Level: &nbsp; {item.clearanceLevel}<br/>
			</button>
		);

		return (
			<ul className = "p-0">
				{listItems}
			</ul>
		)
	}

	return (
		<React.Fragment>
			<div className = "row justify-content-center">
				<div className = "col-7 pt-4">
					<div className = "accordion accordion-flush" id = "vcAccordion">
						<div className = "accordion-item">
							<h5 className = "accordion-header" id = "vcHeader">
								<button
									className = "accordion-button"
									type = "button"
									data-bs-toggle = "collapse"
									data-bs-target = "#vcList"
								>
									Select VC
								</button>
							</h5>
							<div id = "vcList" className = "accordion-collapse collapse show" aria-labelledby = "vcHeader">
								{getVCList()}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className = "row justify-content-center">
				<div className = "col-3 pt-4">
					<button className = "btn btn-outline-danger btn-block" onClick = {() => back()}>
						Back
					</button>
				</div>
				<div className = "col-3 pt-4">
					<button className = "btn btn-outline-primary btn-block" onClick = {() => confirmVc()}>
						Confirm VC
					</button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default BTCSettingsChangeVC;