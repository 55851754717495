import React from 'react'
import {Modal} from 'react-bootstrap'

function FrontModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center">
            Document Image Front
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <img style={img_doc} src={props.img3} alt="document" />
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
    );
  }
  export default FrontModal;

  const img_doc = {
    width: 'auto',
    height: "30rem",
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
}