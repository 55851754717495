import React, {useEffect, useState, useContext} from 'react';
import Navbar from './Navbar';
import CitizenKycForm from './CitizenKycForm';
import GovernmentKycForm from './GovernmentKycForm';
import AccountContext from './AccountContext';
import { logout } from '../services/loginService';

function RegisterBody(props) {

  const [cg, setCg] = useState(true)
  const {loggedIn} = useContext(AccountContext)

  useEffect(() => {
    if(!loggedIn) {
      props.history.push('/public-kyc-sign-in')
    }
  }, [loggedIn, props.history])

  return (
    <div className = "container bg-light vh-100" style = {{overflow : "auto"}}>
      <div className = "row mx-auto">
        <div className = "card shadow col-lg-6 my-auto mx-auto" style = {{height : "250vh"}}>
        <img src = "/512PX-X-512PX.png" className = "card-img" style = {{opacity: 0.2, height : "inherit"}} alt = ""/>
          <div className = "card-body card-img-overlay">
            <div className = "row text-center mt-5 justify-content-center">
              <div className = "col-12 mb-5">
                <h3>Welcome To Registration Portal</h3>
              </div>
              <div className = "col-6 mb-3">
                <button className = {`btn btn-outline-dark ${ cg ? "active" : ""}`} onClick = {() => setCg(true)}>
                  Citizen KYC Form
                </button>
              </div>
              <div className = "col-6 mb-3">
                <button className = {`btn btn-outline-dark ${!cg ? "active" : ""}`} onClick = {() => setCg(false)}>
                  Government Official KYC Form
                </button>
              </div>
            </div>
            { cg ? <CitizenKycForm history = {props.history}/> : <GovernmentKycForm history = {props.history} />}
            {/* <div className = "row text-center justify-content-center mt-5">
              <div className = "col-6">
                <button className = "btn btn-outline-danger" onClick = {() => {logout(); props.history.push('/public-kyc-sign-in')}}>
                  Sign Out
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

function Register(props) {
  return (
    <div className = "container-fluid vh-100">
        <header>
          <Navbar history = {props.history}/>
        </header>
        <main>
          <RegisterBody history = {props.history}/>
        </main>
      </div>
  );
}

export default Register