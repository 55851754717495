import React from 'react';

function VCCreate(props) {

	return (
		<div className = "container-fluid card" style = {{marginTop: "150px"}}>
			<div className = "card-body">
				<div className = "row justify-content-between bg-light">
					<div className = "col">
						<h4 className = "py-2">
							Create New Digital Currency
						</h4>
					</div>
					<div className = "col-2 py-2">
						<button className = "btn btn-outline-danger btn-sm" onClick = {() => props.history.push("/vc")}>
							Back To Main
						</button>
					</div>
				</div>
				<div className = "row justify-content-center text-center pt-5">
					<div className = "col-6">
						<h4>Select Type Of Form</h4>
					</div>
				</div>
				<div className = "row justify-content-center pt-5">
					<div className = "col-5">
						<button 
							className = "btn btn-outline-primary btn-block btn-lg" 
							type = "button"
							onClick = {() => props.history.push("/vc/create-new-vc/token-admin-vc")} 
						>
							Token Admin VC
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default VCCreate;