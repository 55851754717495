import React from 'react';
import {myVC} from '../constants/BTCTestData/BTCVC';

const defaultVC = myVC[0];

const VCContext = React.createContext({
	currentVC : defaultVC,
	setCurrentVC: () => {}
});

export default VCContext;