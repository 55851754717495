import React, {useState, useEffect} from 'react';
import { Form, Field } from "react-final-form";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../config"
import { GetToken, getS3PresignedData, uploadFileToS3 } from "./kycServices";

function GovernmentKycForm(props) {

  const [docImg, setDocImg] = useState("");
  const [selfie, setSelfie] = useState("");
  const [idFront, setIdFront] = useState("");
  const [idBack, setIdBack] = useState("");
  const [eml, setEml] = useState(null);
  const [pno, setPno] = useState(null);

  useEffect(() => {
    Auth.currentSession().then((data) => {   
      setEml(data.idToken.payload.email);
      setPno(data.idToken.payload.phone_number);
    }); 
  }, [])

  const getImageUrl = async (selectedFileObj, imgType) => {
		const { name, type } = selectedFileObj;
    	//const trimFilename = name.substring(0, 5) + "...";
		let idToken = await GetToken();
		const preSignedObj = await getS3PresignedData(idToken, name, false, type, eml, pno);
    	uploadFileToS3(preSignedObj.data, selectedFileObj)
      	.then((res) => {
    		let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        if(imgType === "DOC") {
        	setDocImg(fileUrl);
        } else if (imgType === "SELFIE") {
          setSelfie(fileUrl)
        } else if (imgType === "ID FRONT") {
          setIdFront(fileUrl)
        } else if (imgType === "ID BACK") {
          setIdBack(fileUrl)
        }
      	})
      	.catch((_err) => imgType === "DOC" ? setDocImg("ERROR") : imgType === "SELFIE" ? setSelfie("ERROR") : imgType === "ID FRONT" ? setIdFront("ERROR") : setIdBack("ERROR"));
	}

	const onSubmit = async (values) => {
    let newV = values;
    for (let item in values) {
      if(values[item].length === 0) {
        newV[item] = "NA"
      }
    }
    if(docImg.length > 0) {
      newV.document_image_url = docImg;
    }
    if(selfie.length > 0) {
      newV.selfie_image_url = selfie;
    }
    if(idFront.length > 0) {
      newV.id_document_front = idFront;
    }
    if(idBack.length > 0) {
      newV.id_document_back = idBack;
    }
    let auth;
     Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data);
      auth = data.idToken.jwtToken
      Object.assign(newV, {
        uid: data.idToken.payload.sub,
        email: data.idToken.payload.email,
        phone_number: data.idToken.payload.phone_number,
      });
      axios({
        method: "post",
        url: `${BASE_URL}saveUserKyc`,
        data: newV,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: auth
        },
      })
        .then((res) => {
          Swal.fire("Success!", "KYC Submitted Successfully", "success");
          props.history.push('/public-kyc-register');
          window.location.reload();
        })
        .catch((err) => console.log("Error During submit KYC...", err));
    });
	}

  const GovtWorkerFormData = {
    kycType: 'GovtWorker',
    givenName : "",
    familyName : "",
    dob : "",
    residentalAddr : "",
    postalAddr : "",
    citizenshipIDNo : "",
    office : "",
    officeLevel : "",
    securityClearanceLevel : "",
    compensationCode : "",
    proWLevel : "",
    proWAuthNo : "",
    dateOfAppointment : "",
    selfie_image_url : "",
    document_image_url : "",
    id_document_name : "",
    id_document_number: "",
    id_document_front: "",
    id_document_back: "",
    id_document_expiry: "",
  };

	return (
		<div>
			
				<Form
					onSubmit = {onSubmit}
					initialValues = {{...GovtWorkerFormData}}
					validate = {(values) => {
						const errors = {};
						if (!values.givenName) {
							errors.givenName = "Cannot be blank";
						} 
            if (!values.familyName) {
							errors.familyName = "Cannot be blank";
						} 
            if (!values.dob) {
							errors.dob = "Cannot be blank";
						}
            if (values.selfie_image_url && selfie.length === 0) {
							errors.selfie_image_url = "Generating Image URL....";
						}
						else if (values.selfie_image_url && selfie === "ERROR") {
							errors.selfie_image_url = "Something Went Wrong. Try Refresh or Re-Sign-In";
						}
            if(!values.document_image_url) {
              errors.document_image_url = "Required";
            } 
            else if (values.document_image_url && docImg.length === 0) {
							errors.document_image_url = "Generating Image URL....";
						}
						else if (values.document_image_url && docImg === "ERROR") {
							errors.document_image_url = "Something Went Wrong. Re-Sign-In";
						}
            if (values.id_document_back && idBack.length === 0) {
							errors.id_document_back = "Generating Image URL....";
						}
						else if (values.id_document_back && idBack === "ERROR") {
							errors.id_document_back = "Something Went Wrong. Re-Sign-In";
						}  
            if (values.id_document_front && idFront.length === 0) {
							errors.id_document_front = "Generating Image URL....";
						}
						else if (values.id_document_back && idFront === "ERROR") {
							errors.id_document_front = "Something Went Wrong. Re-Sign-In";
						} 
            // if (!values.residentalAddr) {
						// 	errors.residentalAddr = "Cannot be blank";
						// } 
            // if (!values.postalAddr) {
						// 	errors.postalAddr = "Cannot be blank";
						// } 
            // if (!values.citizenshipIDNo) {
						// 	errors.citizenshipIDNo = "Cannot be blank";
						// } 
            // if (!values.office) {
						// 	errors.office = "Cannot be blank";
						// } 
            // if (!values.officeLevel) {
						// 	errors.officeLevel = "Cannot be blank";
						// } 
            // if (!values.securityClearanceLevel) {
						// 	errors.securityClearanceLevel = "Cannot be blank";
						// } 
            // if (!values.compensationCode) {
						// 	errors.compensationCode = "Cannot be blank";
						// } 
            // if (!values.proWLevel) {
						// 	errors.proWLevel = "Cannot be blank";
						// } 
            // if (!values.proWAuthNo) {
						// 	errors.proWAuthNo = "Cannot be blank";
						// } 
            // if (!values.dateOfAppointment) {
						// 	errors.dateOfAppointment = "Cannot be blank";
						// } 
            return errors;
					}}
					render = {({handleSubmit, form, submitting, pristine, values }) => (
						<form onSubmit = {handleSubmit}>
						    <Field name = "givenName">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "givenName" className = "form-label">Enter First Name</label>
										<input {...input} 
											type = "text" 
											id="givenName"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "First Name"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
							<Field name = "familyName">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "familyName" className = "form-label">Enter Last Name</label>
										<input {...input} 
											type = "text" 
											id="familyName"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "Last Name"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "dob">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "dob" className = "form-label">Enter Date Of Birth</label>
										<input {...input} 
											type = "text" 
											id="dob"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "DD/MM/YYYY"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "residentalAddr">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "residentalAddr" className = "form-label">Enter Residential Address</label>
										<input {...input} 
											type = "text" 
											id="residentalAddr"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "Residential Address"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "postalAddr">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "postalAddr" className = "form-label">Enter Postal Address</label>
										<input {...input} 
											type = "text" 
											id="postalAddr"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "Postal Address"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "office">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "office" className = "form-label">Enter Office</label>
										<input {...input} 
											type = "text" 
											id="office"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "Office"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "officeLevel">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "officeLevel" className = "form-label">Enter Office Level</label>
										<input {...input} 
											type = "text" 
											id="officeLevel"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "Office Level"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "securityClearanceLevel">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "securityClearanceLevel" className = "form-label">Enter Security Clearance Level</label>
										<input {...input} 
											type = "text" 
											id="securityClearanceLevel"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "SC Level"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "compensationCode">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "compensationCode" className = "form-label">Enter Compensation Code</label>
										<input {...input} 
											type = "text" 
											id="compensationCode"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "Compensation Code"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "proWLevel">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "proWLevel" className = "form-label">Enter Pro-W Level</label>
										<input {...input} 
											type = "text" 
											id="proWLevel"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "Pro-W Level"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
							<Field name = "proWAuthNo">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "proWAuthNo" className = "form-label">Enter Pro-W Auth Number</label>
										<input {...input} 
											type = "text" 
											id="proWAuthNo"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "Pro-W Auth Number"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "dateOfAppointment">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "dateOfAppointment" className = "form-label">Enter Date Of Appointment</label>
										<input {...input} 
											type = "text" 
											id="dateOfAppointment"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "Enter Date Of Appointment"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "document_image_url">
								{({ input: {value, onChange, ...input}, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "document_image_url" className = "form-label">Upload Image of Identity Document</label>
										<input {...input} 
											type = "file" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="document_image_url"
											accept="image/*"
  											onChange = {({target}) => {
												  onChange(target.files[0]);
												  if(target.files[0]) {  
														getImageUrl(target.files[0], "DOC");
												  }
											}}
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">{docImg.length === 0 ? "No Image Provided" : "Image Uploaded Successfully"}</div>}	
									</div>
								)}
							</Field>
              <Field name = "selfie_image_url">
								{({ input: {value, onChange, ...input}, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "selfie_image_url" className = "form-label">Upload Image of Selfie</label>
										<input {...input} 
											type = "file" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="selfie_image_url"
											accept="image/*"
  											onChange = {({target}) => {
												  onChange(target.files[0]);
												  if(target.files[0]) {  
														getImageUrl(target.files[0], "SELFIE");
												  }
											}}
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">{selfie.length === 0 ? "No Image Provided" : "Image Uploaded Successfully"}</div>}	
									</div>
								)}
							</Field>
              <Field name = "id_document_name">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "id_document_name" className = "form-label">Enter ID Document Name</label>
										<input {...input} 
											type = "text" 
											id="id_document_name"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "ID DOC Name"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "id_document_number">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "id_document_number" className = "form-label">Enter ID Document Number</label>
										<input {...input} 
											type = "text" 
											id="id_document_number"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "ID DOC No"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "id_document_expiry">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "id_document_expiry" className = "form-label">Enter ID Document Expiry</label>
										<input {...input} 
											type = "text" 
											id="id_document_expiry"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "DD/MM/YYYY"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
              <Field name = "id_document_front">
								{({ input: {value, onChange, ...input}, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "id_document_front" className = "form-label">Upload Front Image Of ID Document</label>
										<input {...input} 
											type = "file" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="id_document_front"
											accept="image/*"
  											onChange = {({target}) => {
												  onChange(target.files[0]);
												  if(target.files[0]) {  
														getImageUrl(target.files[0], "ID FRONT");
												  }
											}}
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">{idFront.length === 0 ? "No Image Provided" : "Image Uploaded Successfully"}</div>}	
									</div>
								)}
							</Field>
              <Field name = "id_document_back">
								{({ input: {value, onChange, ...input}, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "id_document_back" className = "form-label">Upload Front Image Of ID Document</label>
										<input {...input} 
											type = "file" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="id_document_back"
											accept="image/*"
  											onChange = {({target}) => {
												  onChange(target.files[0]);
												  if(target.files[0]) {  
														getImageUrl(target.files[0], "ID BACK");
												  }
											}}
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">{idBack.length === 0 ? "No Image Provided" : "Image Uploaded Successfully"}</div>}	
									</div>
								)}
							</Field>
							<div className = "row justify-content-center">
								<div className = "col-3 mt-3">
									<button 
										className = "btn btn-outline-primary btn-block"
										type = "submit"
										disabled = {submitting}
									>
										Proceed
									</button>
								</div>
							</div>
						</form>
					)}
				/>		
			</div>
	)
}

export default GovernmentKycForm;