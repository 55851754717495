import {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';

export default function useCognitoUser(): {
  cognitoUser: any;
  isRootAdmin: string;
} {
  const [cognitoUser, setCognitoUser] = useState('');
  const [isRootAdmin, setIsRootAdmin] = useState('');
  useEffect(() => {
    getAdminInfo();
    getUserInfo();
  }, []);
  async function getUserInfo() {
    const user = await Auth.currentAuthenticatedUser();
    console.log('attributes:', user.attributes);
    setCognitoUser(user.attributes);
  }
  async function getAdminInfo() {
    await Auth.currentSession()
      .then((data: any) => {
        const RAdmin = data.accessToken.payload['cognito:groups'][0];
        setIsRootAdmin(RAdmin);
      })
      .catch((error) => {
        console.log('Not Root Admin');
      });
  }
  return {
    cognitoUser,
    isRootAdmin,
  };
}