import React, { useState } from 'react';
import styled from 'styled-components'
import { Row, Col, Form } from 'react-bootstrap'
import Swal from 'sweetalert2'
import '../styles/Signup_style.css'
import { Auth } from 'aws-amplify';

const UserSignup = (props) => {


    const [userName, setUserName] = useState();
    const [email, setEmail] = useState('');
    const [mobileNo, setMobileNo] = useState('')
    const [dob, setDob] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [signUpType, setSignUpType] = useState(false);


    const updateName = event => {
        setUserName(event.target.value)

    }
    const updateEmail = event => {
        setEmail(event.target.value)

    }
    const updateMobile = event => {
        setMobileNo(event.target.value)

    }
    const updateDob = event => {
        setDob(event.target.value)

    }
    const updatePassword = event => {
        setPassword(event.target.value)
    }
    const updateConfirmPassword = event => {
        setConfirmPassword(event.target.value)
    }
    console.log(userName)
    console.log(email)
    console.log(mobileNo)

    const emailRegex =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
       
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const nameRegex = new RegExp("^[a-zA-Z][\sa-zA-Z]*"); 

    const handleSubmit = async () => {

     try {

        const initialValidation =
        
        email || (mobileNo &&
            userName &&
            dob &&
            password &&
            confirmPassword &&
            password === confirmPassword);
        if (initialValidation) {

            const { user } = await Auth.signUp({
                username: email || mobileNo,
                password: password,
                attributes: {
                    name: userName, // optional
                    birthdate: dob, // optional - E.164 number convention
                    // other custom attributes
                  },
            });
                
            console.log("userData", user);
            console.log(user.attributes)
            Swal.fire(

                'Success!',
                'Please check given Email or Mobile Number and verify OTP',
                'success',
            )
            props.history.push("/otpscreen")
        }
    
        else {
            Swal.fire(

                "Oops...",
                "Please Fill all fields correctly!",
                "error"
            )
        }
    }catch(error) {
        if (error.code === 'UsernameExistsException') {
            Swal.fire(

                "Oops...",
                "Email or phone number already exists, Please try with different Email or phone number",
                "error"
            )
        }
    }

    };
    const renderEmailValidation = () => {
        if (email==='') {
          return null;
        }
        return !emailRegex.test(email) && (
          <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}>
            {'Please enter valid Email'}
          </h1>
        )
      };

      const renderNameValidation = () => {
          if(userName === '') {
              return null;
          }
          return !nameRegex.test(userName) &&
          (
              <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Name can't start with space.</h1> 
          )
      }

    const renderPasswordComparision = () => {
        if (!password || !confirmPassword) {
            return null;
        }
       
        return password === confirmPassword ? (
            <h1 style={{ color: "green", fontSize: 15, marginTop: 5 }}>Password matches </h1>
        ) : (
            <h2 style={{ color: "red", fontSize: 15, marginTop: 5 }}>Password do not match </h2>
        );
    };
    const passValid = () => {
        if (password==='') {
          return null;
        } 
   
        return  !strongRegex.test(password) &&
        (
            <h1 className="valid_login_div" style={{ color: "red", fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5 }}>*Minimum 8 charcaters must be there.Include lowercase, uppercase,numbers and special charcaters </h1> 
        ) 
        };

        // const setDate = (date) => {

        //     // first convert date object to string
        //     let date_as_string = date.toString();
          
        //     // split the string and get the date part
        //     let date = date.split("T")[0];
          
        //     // return the date string
        //     return date;
        //   }


//    const  passValidation = () => {
   
//     return !passRegex.test(password) ?

//     (
//         <h1 style={{ color: "#880808", fontSize: 15, marginTop: 5 }}>*Minimum 8 charcaters must be there.Include Lowercase, Uppercase,Numbers and Special charcaters </h1> 
//     ) : (
//         <h2 style={{ color: "green", fontSize: 15, marginTop: 5 }}> Your password is valid</h2>
//     ) 
//    };

    const btnReset = () => {
        setUserName("");
        setEmail("");
        setDob("");
        setPassword("");
        setConfirmPassword("");
    }
    const toggleSignUpType = () => {
        setSignUpType(!signUpType);
    };
   

    return (
        <>

            <Input>
                <Row>
                    <Col>

                        <div className="signup-div">
                            <Heading> <h1> Sign Up Form</h1> 
                            <button  onClick={toggleSignUpType}>{
                                !signUpType ?  'Sign Up with Mobile' : 'Sign Up with Email'
                            }</button>
                            </Heading>
                            <Form.Group className="mb-3 form-div">
                                <Row>
                                    <Form.Label className="is_name_div">Full Name</Form.Label>
                                    <Col md={12}>
                                        <Form.Control
                                            className="input1"
                                            type="text"
                                            id="fullname"
                                            name="fullname"
                                            autoComplete="off"
                                            value={userName}
                                            onChange={updateName}
                                            placeholder="Enter Full Name"
                                            required />
                                            {renderNameValidation()}
                                    </Col>
                                </Row>
                            </Form.Group>
                            {!signUpType ? (
                                <Form.Group className="mb-3 form-div">
                                    <Row>
                                        <Form.Label className="is_name_div">Email</Form.Label>
                                        <Col md={12}>
                                            <Form.Control
                                                className="input1"
                                                type="email"
                                                id="email"
                                                name="email"
                                                autoComplete="off"
                                                value={email}
                                                onChange={updateEmail}
                                                placeholder="Enter Email"
                                                required />
                                            {renderEmailValidation()}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            ) : (

                                <Form.Group className="mb-3 form-div">
                                    <Row>
                                        <Form.Label className="is_name_div">Mobile Number</Form.Label>
                                        <Col md={12}>
                                            <Form.Control
                                                className="input1"
                                                type="text"
                                                id="mobile"
                                                name="mobile"
                                                autoComplete="off"
                                                value={mobileNo}
                                                onChange={updateMobile}
                                                placeholder="Enter Mobile Number"
                                                required />

                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}
                            <Form.Group className="mb-3 form-div">
                                <Row>
                                    <Form.Label className="is_name_div">Date of Birth</Form.Label>
                                    <Col md={12}>
                                        <Form.Control 
                                        className="input1"
                                            type="date"
                                            id="dob"
                                            name="dob"
                                            placeholder="Date of Birth"
                                            value={dob}
                                            onChange={updateDob}
                                            // maxDate={new Date()}
                                            max={new Date().toISOString().split("T")[0]}
                                            required />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3 form-div">
                                <Row>
                                    <Form.Label className="is_name_div">Password</Form.Label>
                                    <Col md={12}>
                                        <Form.Control
                                            className="input1"
                                            type="password"
                                            id="password"
                                            name="password"
                                            autoComplete="off"
                                            value={password}
                                            onChange={updatePassword}
                                            placeholder="Enter Password"
                                            required />
                                            {passValid()}
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3 form-div">
                                <Row>
                                    <Form.Label className="is_name_div"> Confirm Password</Form.Label>
                                    <Col md={12}>
                                        <Form.Control
                                            className="input1"
                                            type="password"
                                            id="cnfpassword"
                                            name="cnfpassword"
                                            autoComplete="off"
                                            value={confirmPassword}
                                            onChange={updateConfirmPassword}
                                            placeholder="Enter Password Again"
                                            required />
                                        {renderPasswordComparision()}
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Main>
                                <Button1 type="submit" onClick={handleSubmit} className="btn1"> Submit</Button1>
                                <Button2 type="reset" className="btn2" variant="secondary" onClick={btnReset}> Reset </Button2>
                            </Main>

                        </div>
                    </Col>
                </Row>
            </Input>



        </>
    )
};
export default UserSignup;
const Main = styled.div`
display: flex;
flex-direction: row;
justify-content: space-around;
`

const Input = styled.div`
width: 35rem;
height: 55rem;
border: 1px solid black;
border-radius: 1rem;
margin-top: 7rem;
margin-bottom: 3rem;
margin-left: 5rem;
padding: 3rem;
@media screen and (min-width: 360px) and (max-width: 767px) {
    margin-top: 15rem;
    width: 22rem;
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
   margin-left: 10rem;
}
@media screen and (min-width: 1201px) {
    margin-left: 10rem;
}
`
const Heading = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
position: relative;
margin-top: -4rem;
margin-bottom: 2rem;
text-align: center;
align-items: center;
>h1 {
    text-align: center;
    margin-top: 2rem;
}
>button {
    width: 15rem;
    height: 2.5rem;
    background-color: #2ec4b6;
    border-radius: 1rem;
    border: none;
    margin-top: 2rem;
    font-size: 1.2rem;
    color: #fff;
}
`
const Button1 = styled.div`
width: 8rem;
height: 2.5rem;
background-color: #2ec4b6;
font-size: 1.5rem;
    color: #fff;
    text-align: center;
    border-radius: 1rem;
    margin-top: 2rem;
`
const Button = styled.div`
`
const Button2 = styled.div`
width: 8rem;
height: 2.5rem;
font-size: 1.5rem;
 text-align: center;
 background-color: #2ec4b6;
  color: #fff;
 border-radius: 1rem;
 margin-top: 2rem;
`
const Well = styled.div`

`