import React, {useEffect, useState} from 'react'
import {Card, Row, Col} from 'react-bootstrap'
import styled from 'styled-components'
import {GetCall} from '../services/KycService'
import NewRequests from '../Assets/icons/New Requests.png'
import RejectedKYC from '../Assets/icons/Rejected KYC.png'
import TotalUsers from '../Assets/icons/Total Users.png'
import "../styles/Sidemenu_style.css";

const StatusCard = () => {
  const[totalLen, setTotalLen] =useState('')
  const[submittedLen, setSubmittedLen] =useState('')
  const[rejectedLen, setRejectedLen] =useState('')


  useEffect(()=>{
    fetchNumber()    
  },[])

  const fetchNumber= async () => {
    const res = await GetCall('getKycList');
    const len = res.message.length;
    setTotalLen(len)
    const dummy = res.message.filter(item => item.hasOwnProperty('kyc_status'))
    const submittedUsers = dummy.filter((e) => e.kyc_status.includes("S"));
    setSubmittedLen(submittedUsers.length)

    const declinedUsers = dummy.filter((e) => e.kyc_status.includes("D"));
    setRejectedLen(declinedUsers.length)
      }
    


    const card_style={
        width: '100%',
        background: '#fff',
        color: '#000',
        border:'none',
        outline:'none',
        borderRadius: '15px',
        boxShadow: '0px 23px 44px rgba(176, 183, 195, 0.14)',
    }
    const card_title = {
      marginLeft: '20px',
      marginTop: '15px'
    }
    const card_text = {
        fontSize: '35px',
        fontWeight: '600'
    }
    const card_img = {
        width: '40px',
        height: 'auto',
        marginTop:'12px',
        marginLeft: '4px'
    }
    const img_div = {
      border: '1px solid #2EC4B6',
      boxSizing: 'border-box',
      borderRadius: '15px',
      width: '49px',
      height: '49px'
    }
    return(
        <>
        <Wrapper>
          <Row>
            <Col>
              <Card className="card_div" style={card_style}>
                <Card.Body>
                  <div className="status_div" style= {{display: 'flex', flexDirection: 'row'}}>
                    <div style={img_div}>
                      <Card.Img src={NewRequests} style={card_img} />
                    </div>
                    <Card.Title className="req_div" style= {card_title}>New Requests</Card.Title>
                  </div>
                  <Card.Text className="text-center dis_num" style={card_text}>
                    {submittedLen}
                  </Card.Text>
                </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <div style= {{display: 'flex', flexDirection: 'row'}}>
                      <div style={img_div}>
                        <Card.Img src={RejectedKYC} style={card_img} />
                      </div>
                      <Card.Title style= {card_title}>Rejected KYC</Card.Title>
                    </div>
                    <Card.Text className="text-center" style={card_text}>
                      {rejectedLen}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="total_div" style={card_style}>
                  <Card.Body className="total_status_div">
                    <div style= {{display: 'flex', flexDirection: 'row'}}>
                      <div style={img_div}>
                        <Card.Img src={TotalUsers} style={card_img}/>
                      </div>
                      <Card.Title style= {card_title}>Total Users</Card.Title>
                    </div>
                    <Card.Text className="text-center" style={card_text}>
                      {totalLen}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
          </Row>
        </Wrapper>
        </>
    )
}
export default StatusCard

const Wrapper = styled.div`
margin-top: 100px;
width: 100%;
background-color:#FAFBFC;
border-radius:20px;
padding:30px;
@media screen and (min-width: 360px) and (max-width: 767px) {
  margin-top: 14rem;
}
`