import React, { useEffect, useState } from "react";
import PublicKycDashboard from "./PublicDidDashboard";

const PublicDIDKyc = (props) => {
  return(
    <React.Fragment>
      <PublicKycDashboard history = {props.history}/>
    </React.Fragment>
  )

  
};
export default PublicDIDKyc;


