import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import Loader from "react-loader-spinner";
import axios from "axios";

import { Table, Button } from "react-bootstrap";
import { RESTORE_VC } from "../constants/RestoreApiConfig";

const RestoreVCRequests = (props) => {
  const { idToken } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const preprocessData = (rowData, reqId = false) => {
    if (reqId) {
      return rowData.filter((data) => data.req_id !== reqId);
    } else {
      return rowData.map((data) => ({
        ...data,
        full_name:
          data.kycType === "PublicDid"
            ? data.entityName
            : `${data["givenName"]} ${data["familyName"]}`
      }));
    }
  };
  useEffect(() => {
    if (idToken) {
      axios
        .post(
          RESTORE_VC,
          { action: "fetch_pending" },
          {
            headers: {
              Authorization: idToken,
            },
          }
        )
        .then((res) => {
          if (res?.data?.error) {
            setFailed(true);
          } else {
            setData(preprocessData(res.data.data));

            setFailed(false);
          }
        })
        .catch((_) => setFailed(true))
        .finally((_) => setLoading(false));
    }
  }, [idToken]);
  // const restoreVCAction = (action, req_id) => {
  //   setLoading(true);
  //   axios
  //     .post(
  //       RESTORE_VC,
  //       {
  //         action,
  //         req_id,
  //       },
  //       {
  //         headers: {
  //           Authorization: idToken,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res?.data?.error) {
  //         setFailed(true);
  //       } else {
  //         setData(preprocessData(data, req_id));
  //         setFailed(false);
  //       }
  //     })
  //     .catch((_) => setFailed(true))
  //     .finally((_) => setLoading(false));
  // };
  window.scrollTo({ top: 0 });

  const formattimest = (time) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      // hour: "2-digit",
      // minute: "2-digit",
      // hour12: true,
    };
    return new Date(time).toLocaleDateString(undefined, options);
  };

  return (
    <Container style={{ marginTop: 50 }}>
      <div className="jumbotron">
        <div className="container">
          <br />
          <h2 className="display-4">Restore VC Requests</h2>
        </div>
      </div>
      <Table striped bordered variant="light" responsive="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email / Phone Number</th>
            <th>DID</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td align="left" colSpan="6">
                <Loader
                  type="ThreeDots"
                  color="#3f51b5"
                  style={{ textAlign: "center" }}
                />
              </td>
            </tr>
          )}
          {!loading && failed && (
            <tr>
              <td align="left" colSpan="6">
                <p style={{ textAlign: "center", color: "red" }}>
                  Failed to fetch request list.
                </p>
              </td>
            </tr>
          )}
          {!loading && !failed && !data?.length && (
            <tr>
              <td align="left" colSpan="6">
                <p style={{ textAlign: "center" }}>No records found</p>
              </td>
            </tr>
          )}
          {!loading &&
            data
              .sort((a, b) => new Date(b.create_time) - new Date(a.create_time))
              .map((row) => (
                <tr key={row.email}>
                  <td>{row.full_name}</td>
                  <td>{row.email ? row.email : row.phone_number}</td>
                  <td>{row.did}</td>
                  <td>{formattimest(row.create_time)}</td>
                  {/* <td>{row.create_time}</td> */}
                  <td>
                    {/* <Button
                      style={{ marginRight: 5 }}
                      onClick={() => restoreVCAction("approve", row.req_id)}
                      variant="primary"
                    >
                      Accept
                    </Button>
                    <Button
                      onClick={() => restoreVCAction("decline", row.req_id)}
                      variant="danger"
                    >
                      Decline
                    </Button> */}
                    <Button
                      onClick={() =>
                        props.history.push("/restore_vc_details", {
                          userData: row,
                          req_id: row.req_id,
                          idToken,
                        })
                      }
                      variant="primary"
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default RestoreVCRequests;
