import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import "../styles/Newkyc_style.css";
import Swal from "sweetalert2";
import { Auth } from "aws-amplify";
import axios from "axios";
import useCognitoUser from "../services/useCognitoUser"
import { getS3PresignedData, uploadFileToS3 } from "../services/s3one";
import { GetCall, PostCall, GetImage } from "../services/KycService";
import { BASE_URL } from "../config"

const Newkyc = (props) => {
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [residentalAddr, setResidentalAddr] = useState("");
  const [postalAddr, setPostalAddr] = useState("");
  const [vehicalClass, setVehicalClass] = useState("");
  const [vehicalLicenseNo, setVehicalLicenseNo] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [healthCardNo, setHealthCardNo] = useState("");
  const [housingNo, setHousingNo] = useState("");
  const [citizenshipIDNo, setCitizenshipIDNo] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentNo, setDocumentNo] = useState("");
  const [docExpiry, setDocExpiry] = useState("");
  const [selfieUrl, setSelfieUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [selfieName, setSelfieName] = useState("");
  const {cognitoUser} = useCognitoUser();
  const [kycType,setKycType] = useState(false);
  const [userName, setUserName] = useState("")

  
  const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  const nameRegex = new RegExp("^[a-zA-Z][sa-zA-Z]*");

  const handleSubmit = (event) => {
    event.preventDefault();
    // if(phone_number[0] === "+") 
    // return phone_number, 
    // if (email){
    //   setUserName(email)
    //  } else {
    //    setUserName(phone_number)
    //  }
    //  console.log(userName, "username")
    const CitizenFormData = {
      kycType: "Citizen",
      givenName,
      familyName,
      dob,
      residentalAddr,
      postalAddr,
      citizenshipIDNo,
      vehicalClass,
      vehicalLicenseNo,
      passportNumber,
      healthCardNo,
      housingNo,
      id_document_name: documentName,
      id_document_number: documentNo,
      id_document_expiry: docExpiry,
      document_image_url: fileUrl,
      selfie_image_url: selfieUrl,
    };
    console.log(CitizenFormData, "citizen data");
    return Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data);
      if(email) {
        Object.assign(CitizenFormData, {
          // uid: data.idToken.payload.sub,       
           email: email,
        });
      }else {
        Object.assign(CitizenFormData, {
          // uid: data.idToken.payload.sub,       
           phone_number: phone_number,
        });
      }
     
      axios({
        method: "post",
        url: `${BASE_URL}saveUserKycAdmin`,
        data: CitizenFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: data.idToken.jwtToken,
        },
      })
        .then((res) => {
          Swal.fire("Success!", "KYC Submitted Successfully", "success");
        })
        .catch((err) => console.log("Error During submit KYC...", err));
    });
  };

  const btnReset = () => {
    setGivenName("");
    setFamilyName("");
    setDob("");
    setEmail("");
    setPhone_number("");
    setVehicalClass("");
    setVehicalLicenseNo("");
    setPostalAddr("");
    setResidentalAddr("");
    setPassportNumber("");
    setHealthCardNo("");
    setHousingNo("");
    setCitizenshipIDNo("");
    setDocumentName("");
    setDocumentNo('');
    setDocExpiry("");
  };
  const handleLogout = async () => {
    try {
      await Auth.signOut();
      props.history.push("/login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const selectSelfieImage = (e) => {
    setSelectedFile(e.target.files[0]);
    uploadSelfieImage(e.target.files[0]);
  };

  const uploadSelfieImage = async (selectedFileObj, docType) => {
    console.log("Selected Image Object are as follows.....", selectedFileObj);
    const { name, type } = selectedFileObj;
    const trimFilename = name.substring(0, 5) + "...";
    // if (docType === "document") {
    //   setSelectedFileName(trimFilename);
    // } else {
    //   setSelfie(trimFilename);
    // }
    setSelfieName(trimFilename);
    // setSelfieName(trimFileName);
    console.log("Filename is....", name);
    console.log("File type is....", type);
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "ssid-user-kyc-documents",
      false,
      type,
      email,
      phone_number,
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setSelfieUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  console.log("url......", fileUrl);

  const selectImage = (e) => {
    setSelectedFile(e.target.files[0]);
    uploadImage(e.target.files[0]);
  };
  const uploadImage = async (selectedFileObj, docType) => {
    console.log("Selected Image Object are as follows.....", selectedFileObj);
    const { name, type } = selectedFileObj;
    const trimFilename = name.substring(0, 5) + "...";
    // if (docType === "document") {
    //   setSelectedFileName(trimFilename);
    // } else {
    //   setSelfie(trimFilename);
    // }
    setSelectedFileName(trimFilename);
    console.log("Filename is....", name);
    console.log("File type is....", type);
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "ssid-user-kyc-documents",
      false,
      type,
      email,
      phone_number,
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setFileUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  console.log("url......", fileUrl);

  const renderNameValidation = () => {
    if (givenName === "") {
      return null;
    }
    return (
      !nameRegex.test(givenName) && (
        <h1
          className="valid_login_div"
          style={{
            color: "red",
            fontSize: 15,
            marginTop: 5,
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          {" "}
          "ERROR" Name can't start with space or digits
        </h1>
      )
    );
  };
  const renderFamilyNameValidation = () => {
    if (familyName === "") {
      return null;
  }
  return !nameRegex.test(familyName) &&
  (
      <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Name can't start with space or digits.</h1> 
  ) 
  
}
const renderEmailValidation = () => {
  if (email==='') {
    return null;
  }
  return !emailRegex.test(email) && (
    <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}>
      {'Please enter valid Email'}
    </h1>
  )
};
// const renderAddValidation = () => {
//   if(residentalAddr === '') {
//       return null;
//   }
//   return !nameRegex.test(residentalAddr) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Address can't start with space.</h1> 
//   ) 
  
// }
// const renderAdd1Validation = () => {
//   if(postalAddr === '') {
//       return null;
//   }
//   return !nameRegex.test(postalAddr) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Address can't start with space.</h1> 
//   ) 
  
// }
// const renderVehicleValidation = () => {
//   if(vehicleClass === '') {
//       return null;
//   }
//   return !nameRegex.test(vehicleClass) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" License class can't start with space.</h1> 
//   ) 
  
// }
// const renderVehicle1Validation = () => {
//   if(vehicalLicenseNo === '') {
//       return null;
//   }
//   return !nameRegex.test(vehicalLicenseNo) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" License number can't start with space.</h1> 
//   ) 
  
// }
// const renderPassportValidation = () => {
//   if(passportNumber === '') {
//       return null;
//   }
//   return !nameRegex.test(passportNumber) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Passport number can't start with space.</h1> 
//   ) 
  
// }
// const renderHealthValidation = () => {
//   if(healthCardNo === '') {
//       return null;
//   }
//   return !nameRegex.test(healthCardNo) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Health card number can't start with space.</h1> 
//   ) 
  
// }
// const renderHouseValidation = () => {
//   if(housingNo === '') {
//       return null;
//   }
//   return !nameRegex.test(housingNo) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Housing number can't start with space.</h1> 
//   ) 
  
// }
// const renderCitizenshipValidation = () => {
//   if(citizenshipIDNo === '') {
//       return null;
//   }
//   return !nameRegex.test(citizenshipIDNo) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Citizenship ID can't start with space.</h1> 
//   ) 
  
// }

const toggleKycType = () => {
  setKycType(!kycType);
};
  return (
    <>
      <Container className="kyc_container_div" style={{ marginTop: "50px" }}>
        <Row>
          <Col>
          <Heading> <h1> Yidindji Citizenship KYC Form</h1>
            <button  onClick={toggleKycType}>{
                                !kycType ?  'Mobile' : 'Email'
                            }</button> </Heading>

            <div className="kycform_div">
              <Form onSubmit={handleSubmit} id="contact-form">
                <Form.Group className="mb-3">
                  <Row>
                    <Form.Label className="is_name_div">Name</Form.Label>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="givenName"
                        name="givenName"
                        placeholder="Enter Given Name"
                        value={givenName}
                        onChange={(event) => setGivenName(event.target.value)}
                        required
                      />
                      {renderNameValidation()}
                    </Col>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="familyName"
                        name="familyName"
                        placeholder="Enter Family Name"
                        value={familyName}
                        onChange={(event) => setFamilyName(event.target.value)}
                        required
                      />
                      {renderFamilyNameValidation()}
                    </Col>
                  </Row>
                </Form.Group>
                {!kycType ?
                ( <Form.Group className="mb-3">
                                <Row>
                                    <Form.Label className="is_name_div">Email</Form.Label>
                                    <Col md={6}>
                                        <Form.Control
                                            className="input_div"
                                            type="email"
                                            id="email"
                                            name="email"
                                            autoComplete="off"
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            placeholder="Enter Email"
                                            required />
                                            {renderEmailValidation()}
                                    </Col>
                                </Row>
                            </Form.Group> ) : (
                              

<Form.Group className="mb-3">
    <Row>
        <Form.Label className="is_name_div">Mobile Number</Form.Label>
        <Col md={6}>
            <Form.Control
                className="input_div"
                type="text"
                id="mobile"
                name="mobile"
                autoComplete="off"
                value={phone_number}
                onChange={(event) => setPhone_number(event.target.value)}
                placeholder="Enter Mobile Number"
                required />

        </Col>
    </Row>
</Form.Group>
                            )}
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Date of Birth
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="date"
                        id="dob"
                        name="dob"
                        placeholder="Date of Birth"
                        editable={cognitoUser.birthdate ? false : true}
                        value={dob}
                        onChange={(event) => setDob(event.target.value)}
                        max={new Date().toISOString().split("T")[0]}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Form.Label className="is_name_div">Address</Form.Label>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="resAdd"
                        name="resAdd"
                        placeholder="Enter Residential Address"
                        value={residentalAddr}
                        onChange={(event) => setResidentalAddr(event.target.value)}
                        required
                      />
                  
                    </Col>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="posAdd"
                        name="posAdd"
                        placeholder="Enter Postal Address"
                        value={postalAddr}
                        onChange={(event) => setPostalAddr(event.target.value)}
                        required
                      />
                      
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Form.Label className="is_name_div">Vehicle </Form.Label>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="classLicense"
                        name="classLicense"
                        placeholder="Vehicle class license"
                        value={vehicalClass}
                        onChange={(event) => setVehicalClass(event.target.value)}
                        required
                      />
                     
                    </Col>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="license"
                        name="license"
                        placeholder="Vehicle license number"
                        value={vehicalLicenseNo}
                        onChange={(event) => setVehicalLicenseNo(event.target.value)}
                        required
                      />
                     
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Passport Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="passport"
                        name="passport"
                        placeholder="Passport Number"
                        value={passportNumber}
                        onChange={(event) => setPassportNumber(event.target.value)}
                        required
                      />
                     
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Health Card Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="health"
                        name="health"
                        placeholder="Health Card Number"
                        value={healthCardNo}
                        onChange={(event) => setHealthCardNo(event.target.value)}
                        required
                      />
                     
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Housing Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="house"
                        name="house"
                        placeholder="Housing Number"
                        value={housingNo}
                        onChange={(event) => setHousingNo(event.target.value)}
                        required
                      />
                     
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Citizenship ID Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="citizen"
                        name="citizen"
                        placeholder="Citizenship ID Number"
                        value={citizenshipIDNo}
                        onChange={(event) => setCitizenshipIDNo(event.target.value)}
                        required
                      />
                      
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Document Name
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="document name"
                        name="document name"
                        placeholder="Document name"
                        value={documentName}
                        onChange={(event) => setDocumentName(event.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Document Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="document number"
                        name="document number"
                        placeholder="Document Number"
                        value={documentNo}
                        onChange={(event) => setDocumentNo(event.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                    Document expiry Date
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="date"
                        id="doc expiry"
                        name="doc expiry"
                        min={new Date().toISOString().split("T")[0]}
                        value={docExpiry}
                        onChange={(event) => setDocExpiry(event.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Upload Document
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="file"
                        name="file"
                        onChange={selectImage}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Upload Selfie
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="file"
                        name="file"
                        onChange={selectSelfieImage}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <ButtonSubmit className="btn-div" type="submit">
                  Submit
                </ButtonSubmit>
                <ButtonReset
                  onClick={btnReset}
                  className="btn_div"
                  type="reset"
                >
                  Reset
                </ButtonReset>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Newkyc;

 const Heading = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
align-items: center;
margin-top: 5rem;
margin-bottom: 3rem;
margin-right: 6rem;
>h1 {
  font-size: 2.5rem;
  text-align: center;
  padding-bottom: 2rem;
}
>button {
  width: 7rem;
  height: 3rem;
  font-size: 1.5rem;
  background-color: #2ec4b6;
  color: #fff;
  border: none;
  border-radius: 1rem;
}
  @media screen and (min-width: 769px) and (max-width: 991px) {
    font-size: 2rem;
    margin-left: -3.2rem;
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    font-size: 2rem;
    margin-left: 2rem;
  }
  @media screen and (min-width: 375px) and (max-width: 768px) {
    font-size: 1.5rem;
    margin-left: -11rem;
  }
  @media screen and (width: 768px) {
    margin-left: -5rem;
  }
`;
const FormControl = styled(Form.Control)`
  background: #f1f2f3;
  border-radius: 10px;
`;
const ButtonSubmit = styled(Button)`
  background: #2ec4b6;
  border: none;
  border-radius: 10px;
  :hover {
    background: #2ec4b6;
  }
`;
const ButtonReset = styled(Button)`
  background: #2ec4b6;
  border: none;
  border-radius: 10px;
  color: #fff;
  :hover {
    background: #2ec4b6;
    color: #fff;
  }
`;

const Out = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 4rem;
  margin-left: 43rem;

  > button {
    width: 10rem;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    font-size: 1.5rem;
    background-color: red;
    color: #fff;
    text-align: center;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 2rem;
    margin-left: -2rem;
  }
`;
