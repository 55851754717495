import React from 'react'
import {Modal} from 'react-bootstrap'

function SelfieModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Selfie Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <img style={img_selfie} src={props.img2} alt="selfie" />
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
    );
  }
  export default SelfieModal;

  const img_selfie = {
      width: 'auto',
      height: "30rem",
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block'
  }