import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "react-bootstrap";
const CreateToken = () => {
    return (
        <div style={{ marginTop: "137px" }}>
        <Container className="kyc_container_div">
          <Row>
            <Col>
              <div className="kycform_div">
                <Form id="contact-form">
                  <Form.Group className="mb-3">
                    <Row>
                      <Form.Label className="is_name_div">
                        Token Reciever DID
                      </Form.Label>
                      <Col md={6}>
                        {" "}
                        <Form.Control
                          className="input_div"
                          type="name"
                          placeholder="Name of the did, where the token will be issued"
                          required
                        />
                      </Col>
                      {/* <Col md={10}>
                        {" "}
                        <Form.Control
                          className="input_div"
                          type="name"
                          id="givenName"
                          name="givenName"
                          // onChange={(e) => setSender(`did:ssid:${e.target.value}`)}
                          
                          placeholder="Enter Your Own DID"
                          required
                        />
                      </Col> */}
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Form.Label className="is_name_div">
                        Token Name
                      </Form.Label>

                   
                      <Col md={8}>
                        {" "}
                        <Form.Control
                          className="input_div"
                          type="text"
                          id="resAdd"
                          name="resAdd"
                          placeholder="Enter Name of Token"
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Row>
                      <Form.Label className="is_name_div">
                        Token Symbol
                      </Form.Label>

                      <Col md={10}>
                        {" "}
                        <Form.Control
                          className="input_div"
                          type="text"
                          id="resAdd"
                          name="resAdd"
                          placeholder="Enter Token Synmbol (ex: MUI)"
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row>
                      <Form.Label className="is_name_div">Token Issuance Amount</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          className="input_div"
                          type="text"
                          id="passport"
                          name="passport"
                          placeholder="Ex:  1000000"
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Button
                    type="button"
                    className="btn-div"
                    variant="primary"
                    type="submit"
                  >
                    Request
                  </Button>
                  <Button
                    type="button"
                    className="btn_div"
                    variant="secondary"
                    type="reset"
                  >
                    Reset
                  </Button>
                  <p style={{ marginTop: "20px" }}>
                    <strong>
            
                    </strong>
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        </div>
    );
};

export default CreateToken;