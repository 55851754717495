import React, { useState, useEffect } from "react";
import UserDataService from "../services/API_urls";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";


const AddUser = (props) => {
  console.log(props);

  useEffect(() => {}, [props.data]);

  const initialUserState = {
    id: null,
    name: "",
    email: "",
    number: "",
    published: false,
  };
  const [user, setUser] = useState(initialUserState);
  // const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };
  const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

  const saveUser = () => {
    var data = {
      name: user.name,
      email: user.email,
      number: user.number,
    };

    UserDataService.create(data)
      .then((response) => {
        setUser({
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          number: response.data.number,
          published: response.data.published,
        });
        // setSubmitted(true);
        // console.log(response.data);
        Swal.fire("Success!", "KYC Status Changed!", "success");
        props.onHide();
        props.func();
        setUser("");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const emailValid = () => {
    return !emailRegex.test(user.email) ?
        (
            <h1 style={{ color: "red", fontSize: 15, marginTop: 5 }}> *please enter your valid Email</h1>
        ) : (
            <h2 style={{ color: "green", fontSize: 15, marginTop: 5 }}> This email is Valid </h2>
        );

};

  // const newUser = () => {
  //   setUser(initialUserState);
  //   setSubmitted(false);
  // };

  return (
    <>
      <div className="submit-form">
        <CustomModal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Container className="container3_div"> Add New Admin </Container>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  required
                  value={user.name}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  required
                  value={user.email}
                  onChange={handleInputChange}
                  name="email"
                />
                {emailValid()}
              </div>

              <div className="form-group">
                <label htmlFor="number">Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  required
                  value={user.number}
                  onChange={handleInputChange}
                  name="number"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={saveUser} className="btn btn-success">
              Submit
            </button>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </CustomModal>
      </div>
    </>
  );
};

export default AddUser;

const Container = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-left: 19.5rem;
  color: #7e5737;
  text-align: center;
  @media screen and (min-width: 769px) and (max-width: 991px) {
    margin-left: 10rem;
    text-align: center;
  }
`;
const CustomModal = styled(Modal)`
  .modal-content {
    border-radius: 2rem;
    border: "none";
    > div {
      border: none;
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
`;
