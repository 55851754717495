import React, { useState, useRef, useEffect } from "react";
import { login as LogsIn } from "../services/loginService.js";
import { toast } from "react-toastify";
import { isDev } from "../config";
import styled from "styled-components";
import yidindji_logo from "../Assets/icons/yidindji_logo.png";
import ToggleSwitch from "../constants/Switch";
import { PAIRWISE_API, netstat, PAIRWISE_WEBRTC } from "../config";
import { connection as CSK, did } from "mui-metablockchain-sdk";
import BG from "../Assets/BG.png";
import { withRouter, Link} from "react-router-dom";
import "../styles/Sidemenu_style.css";
import useIsMobile from "../components/useIsMobile.jsx";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";
import { DID_ADMIN_TOOL } from "../config.js";

const configuration = {
  iceServers: [{ urls: "stun:stun.1.google.com:19302" }],
};

const Login = (props) => {
  let connection = new RTCPeerConnection(configuration);
  const [socketMessages, setSocketMessages] = useState([]);
  const [name, setName] = useState("");
  const [users, setUsers] = useState([]);
  const connectedRef = useRef();
  const webSocket = useRef(null);
  const messagesRef = useRef({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login_switch, setSwitch] = useState(false);
  const [events, setEvents] = useState("");
  const isMobile = useIsMobile();

  const [valid, setValid] = useState(false)

  useEffect(() => {
    webSocket.current = new WebSocket(PAIRWISE_WEBRTC);

    webSocket.current.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log("1st:", data);

      setSocketMessages((prev) => [...prev, data]);
    };
    webSocket.current.onclose = () => {
      webSocket.current.close();
    };
    return () => webSocket.current.close();
  }, []);

  useEffect(() => {
    let data = socketMessages.pop();
    console.log("2st:", data);

    if (data) {
      switch (data.type) {
        case "connect":
          checkLoginStatus();
          break;
        case "client-login":
          onLogin(data);
          break;
        case "response-login":
          // onLogin(data);
          localStorage.setItem("login", data.token);
          localStorage.setItem("did", name);

          pairwiseLogin();
          break;
        case "updateUsers":
          updateUsersList(data);
          break;
        case "removeUser":
          // removeUser(data);
          break;
        case "offer":
          onOffer(data);
          break;
        case "answer":
          onAnswer(data);
          break;
        case "candidate":
          onCandidate(data);
          break;
        case "leave":
          console.log(
            "webrtc connection closed because the other peer leave the data channel..."
          );
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [socketMessages]);
  const send = (data) => {
    webSocket.current.send(JSON.stringify(data));
  };
  const checkLoginStatus = () => {
    send({
      type: "login",
      name: DID_ADMIN_TOOL,
    });
  };
  const handleLogin = async (data, event) => {
    setName(data);
    setEvents(event);
    let provider = await CSK.buildConnection(netstat);
    let convertName = await did.resolveDIDToAccount({identifier: data}, provider);
    console.log("AccoiuntID:", convertName);
    // if()
    send({
      type: "client-login",
      name: data,
      source: "yidindji",
    });
    // if (convertName !== null) {
    //   await fetch(`${PAIRWISE_API}/vc-check`, {
    //     method: "post",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       did: data,
    //       source: "ssid",
    //     }),
    //   })
    //     .then((res) => res.json())
    //     .then((res) => {
    //       if (res === true) {
    //         console.log("Login user status:", res);
    //         send({
    //           type: "client-login",
    //           name: data,
    //           source: "ssid",
    //         });
    //       } else {
    //         console.log("Login user status:", res);
    //         return;
    //       }
    //     })
    //     .catch((err) => console.log("API ERROR", err));
    // }
    return;
  };
  const updateUsersList = ({ user }) => {
    setUsers((prev) => [...prev, user]);
  };

  const onLogin = ({ success, message, users: loggedIn }) => {
    if (success) {
      console.log("its a login success");
      setUsers(loggedIn);
      let localConnection = new RTCPeerConnection(configuration);
      //when the browser finds an ice candidate we send it to another peer
      localConnection.onicecandidate = ({ candidate }) => {
        let connectedTo = connectedRef.current;

        if (candidate && !!connectedTo) {
          send({
            name: connectedTo,
            type: "candidate",
            candidate,
          });
        }
      };
      localConnection.ondatachannel = (event) => {
        console.log("Data channel is created!");
        let receiveChannel = event.channel;
        receiveChannel.onopen = () => {
          console.log("Data channel is open and ready to be used.");
        };
        receiveChannel.onmessage = handleDataChannelMessageReceived;
        // updateChannel(receiveChannel);
      };
      // updateConnection(localConnection);
    } else {
      console.log("its a login fail");
    }
  };

  const handleDataChannelMessageReceived = ({ data }) => {
    const message = JSON.parse(data);
    const { name: user } = message;
    let messages = messagesRef.current;
    let userMessages = messages[user];
    if (userMessages) {
      userMessages = [...userMessages, message];
      let newMessages = Object.assign({}, messages, { [user]: userMessages });
      messagesRef.current = newMessages;
    } else {
      let newMessages = Object.assign({}, messages, { [user]: [message] });
      messagesRef.current = newMessages;
    }
  };

  const onOffer = ({ offer, name }) => {
    connectedRef.current = name;

    connection
      .setRemoteDescription(new RTCSessionDescription(offer))
      .then(() => connection.createAnswer())
      .then((answer) => connection.setLocalDescription(answer))
      .then(() =>
        send({ type: "answer", answer: connection.localDescription, name })
      )
      .catch((e) => {
        console.log({ e });
      });
  };

  //when another user answers to our offer
  const onAnswer = ({ answer }) => {
    connection.setRemoteDescription(new RTCSessionDescription(answer));
  };

  //when we got ice candidate from another user
  const onCandidate = ({ candidate }) => {
    connection.addIceCandidate(new RTCIceCandidate(candidate));
  };

  const pairwiseLogin = () => {
    window.location = "/";
    props.history.push("/");
  };

  const Switches = (data) => {
    setSwitch(data);
  };

  const handleSubmit = () => {
    LogsIn(email, password)
      .then((user) => {
        console.log("user", user);
        Auth.currentSession()
          .then((data) => {
            const Admin = data.accessToken.payload["cognito:groups"][0];
            if (Admin === "kyc-admins" || "ssid-admin") {
              window.location = "/";
              props.history.push("/");
            }
          })
          .catch((err) => {
            console.log("You are not authorised!");
            toast.error(
              "The combination of email and password is wrong. Please try again using a valid email address and password."
            );
            //   Swal.fire(

            //     "Oops...",
            //     "Email and Password combination is incorrect. Please try with Valid Email and Password",
            //     "error"
            // )
          });
      })
      .catch((err) => {
        console.log(err);
        // Swal.fire("Oops...", "Login failed. Please try again!!", "error");
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    if (input.name === "email") {
      setEmail(input.value);
    }
    if (input.name === "password") {
      setPassword(input.value);
    }
  };

  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  const renderEmailValidation = () => {
    if (email === "") {
      return null;
    }
    return (
      !emailRegex.test(email) && (
        <h1
          className="valid_login_div"
          style={{
            color: "red",
            fontSize: 15,
            marginTop: 5,
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          {"Please enter valid Email"}
        </h1>
      )
    );
  };

  const passValid = () => {
    if (password === "") {
      return null;
    }

    return (
      !strongRegex.test(password) && (
        <h1
          className="valid_login_div"
          style={{
            color: "red",
            fontSize: 15,
            marginTop: 5,
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          *Minimum 8 charcaters must be there.Include lowercase,
          uppercase,numbers and special charcaters{" "}
        </h1>
      )
    );
  };

  return (
    <>
      {!isMobile ? (
        <div
          className="login_image"
          style={{ left: "0", bottom: "0", position: "absolute" }}
        >
          <Img src={BG} alt="Image" />
        </div>
      ) : null}

      <Main>
        <p className="bold text-center">
          {isDev ? <strong>DEVELOPMENT</strong> : <strong>PRODUCTIONS</strong>}
        </p>
        <div className="body_login_div" style={body}>
          {/* <img style={img_logo} src={yidindji_logo} alt="" /> */}
          <div className="login_user_div" style={form}>
            <br />
            {/* <h2 className="text-center">Yidindji KYC Admin Tool</h2> */}
            {/* <hr /> */}
            <h2 className="text-center sign_text">Sign In</h2>
            <P>Welcome back, you’ve been missed!</P>
            <div style={{ marginLeft: "155px" }}>
              <ToggleSwitch func={(data) => Switches(data)} />
            </div>
            {!login_switch && (
              <div>
                <div className="form-group">
                  <label className="label_login_div" htmlFor="user-email">
                    Email
                  </label>
                  <input
                    value={email}
                    onChange={handleChange}
                    name="email"
                    id="user-email"
                    type="email"
                    className="form-control input_login_form"
                    required
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #377DFF",
                      boxSizing: "borderBox",
                      boxShadow: "0px 12px 23px rgba(55, 125, 255, 0.06)",
                      borderRadius: "10px",
                      width: "540px",
                      height: "40px",
                    }}
                    // autoFocus
                  />
                  {valid && renderEmailValidation()}
                </div>
                <div className="form-group">
                  <label className="label_login_div" htmlFor="user-password">
                    Password
                  </label>
                  <input
                    value={password}
                    onChange={handleChange}
                    name="password"
                    id="user-password"
                    type="password"
                    className="form-control input_login_form"
                    required
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #377DFF",
                      boxSizing: "borderBox",
                      boxShadow: "0px 12px 23px rgba(55, 125, 255, 0.06)",
                      borderRadius: "10px",
                      width: "540px",
                      height: "40px",
                    }}
                  />
                  {valid && passValid()}
                  <br />
                  <button
                    type="submit"
                    className="btn btn-primary submit_login_div"
                    style={{
                      background: "#2EC4B6",
                      borderRadius: "10px",
                      border: "none",
                      width: "540px",
                      padding: "10px 0px 10px 0px",
                    }}
                    onClick={() => {handleSubmit(); setValid(true);}}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}

            {login_switch && (
              <div>
                <div className="form-group">
                  <label className="label_login_div" htmlFor="user-did">
                    DID
                  </label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    name="did"
                    id="user-did"
                    type="text"
                    className="form-control input_login_form"
                    required
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #377DFF",
                      boxSizing: "borderBox",
                      boxShadow: "0px 12px 23px rgba(55, 125, 255, 0.06)",
                      borderRadius: "10px",
                      width: "540px",
                      height: "40px",
                    }}
                  />
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    onClick={(e) => handleLogin(name, e)}
                    className="btn btn-primary submit_login_div"
                    style={{
                      background: "#2EC4B6",
                      borderRadius: "10px",
                      border: "none",
                      width: "540px",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    Submit DID
                  </button>
                </div>
              </div>
            )}

            <div className = "row">
              <div className = "col-5">
                <Link to = "/public-kyc-landing" style = {{textDecoration : "none"}}><b>Public KYC Portal</b></Link>
              </div>
            </div>

            <p style={{ marginBottom: "10px" }} className="text-center swn_div">
              Powered By{" "}
              <a
                style={{ fontWeight: "500", textDecoration: "none" }}
                href="https://swnglobal.com/"
                target="_blank"
              >
                SWN Global
              </a>{" "}
            </p>
          </div>
        </div>
      </Main>
    </>
  );
};

export default withRouter(Login);

const Main = styled.div`
@media screen and (min-width: 768px) and (max-width: 991px) {
  width: 200px !important;
  @media screen and (min-width: 360px) and (max-width: 767px) {
    
  }
`;

const Img = styled.img`
  width: 40rem;
  height: auto;
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 25rem;
    height: auto;
  }
  ${
    "" /* @media (max-width: 1366px) {
    width: 25rem;
    height: auto;
  } */
  }
  ${
    "" /* @media screen and (min-width: 992px) and (max-width: 1200px) {
      width: 70%;
      height: auto;
      padding: 5rem;
    } */
  }
`;
const P = styled.p`
  text-align: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #8a94a6;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-right: 10rem;
  }
`;
const body = {
  backgroundColor: "#fff",
  // border: '1px solid grey',
  // borderRadius: '40px',
  width: "700px",
  position: "absolute",
  left: "60%",
  top: "50%",
  transform: "translate(-50%, -50%)",
};
const form = {
  width: "80%",
  position: "relative",
  left: "50%",
  transform: "translate(-50%)",
};
