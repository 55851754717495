import React, {useState} from 'react';
import { Form, Field } from "react-final-form";
import {FavVC} from '../../constants/BTCTestData/BTCVC';
import { connection, did } from "mui-metablockchain-sdk";
import {netstat} from "../../config";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";
import { TokenAdmin, GetToken, getS3PresignedData, uploadFileToS3 } from "../../services/vcService";

function TokenVCForm(props) {

	const [fileURL, setFileURL] = useState("");

	const onSubmit = async (values) => {
		let modData = {...values, token_symbol: fileURL};
		
		let res = TokenAdmin('handleTokenVC', JSON.stringify({...modData, action : "add_request"}));
		// 	res.then(function(response) {
    //   if( response.hasOwnProperty('request_id')) {
		// 		Swal.fire(`Your request for creating a token is successful.`);
		// 		props.history.push("/vc/my-vc");
    //   } else {
    //     console.log(response.msg)
    //   }
		// });
    res.then(function(v) {
     if(v === "success") {
       Swal.fire("New Currency creation request submitted successfully");
       props.history.push("/vc/my-vc");
     } else {
       Swal.fire(v)
     }
    })
	}

	let formData = {};

	const resolve = async (data) => {
		let provider = await connection.buildConnection(netstat);
    let convertName = await did.resolveDIDToAccount({identifier: data.token_owner}, provider);
		if (!convertName) {
			return { token_owner : "Not a valid did"};
		}
	}

	const getImageUrl = async (selectedFileObj) => {
		const { name, type } = selectedFileObj;
    	//const trimFilename = name.substring(0, 5) + "...";
		let idToken = await GetToken();
		const preSignedObj = await getS3PresignedData(idToken, name, false, type);
    	uploadFileToS3(preSignedObj.data, selectedFileObj)
      	.then((res) => {
    		let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        	setFileURL(fileUrl);
      	})
      	.catch((_err) => setFileURL("ERROR"));
	}

	// function getFavApprovers() {
	// 	let favApprovers = [];

	// 	FavVC.map((approver, index) => {
		
	// 				favApprovers.push(
	// 					<div className = "mb-2 border-bottom" key = {index}>
	// 						<div className = "row justify-content-left">
	// 							<div className = "col-1">
	// 								<Field 
	// 									name = "approvers"
	// 									component = "input"
	// 									type = "checkbox"
	// 									value = {approver.did}
	// 									id = {approver.did}
	// 								/>
	// 							</div>
	// 							<div className = "col-3">
	// 								<label>
	// 									<strong>
	// 										&nbsp;{approver.position}<br/>
	// 										&nbsp;{approver.did}
	// 									</strong>
	// 								</label>
	// 							</div>
	// 						</div>
	// 					</div>		
	// 				);
			
	// 	});

	// 	return (
	// 		<ul className = "p-0">
	// 			{favApprovers}
	// 		</ul>
	// 	)
	// }
	
	return (
		<div className = "container-fluid card" style = {{marginTop: "180px"}}>
			<div className = "card-body">
				<div className = "row justify-content-between bg-light">
					<div className = "col">
						<h4 className = "py-2">
							Create New Digital Currency
						</h4>
					</div>
					<div className = "col-2 py-2">
						<button className = "btn btn-outline-danger btn-sm" onClick = {() => props.history.push("/vc/create-new-vc")}>
							Back To Main
						</button>
					</div>
				</div>
				<div className = "row justify-content-center mb-3 text-center">
					<div className = "col-6">
						<h4>Token Admin VC Form</h4>
					</div>
				</div>
				<Form
					onSubmit = {onSubmit}
					initialValues = {{...formData}}
					validate = {(values) => {
						const errors = {};
						if (!values.token_name) {
							errors.token_name = "Token Name cannot be blank.";
						}
						else if (values.token_name && values.token_name.length > 16) {
							errors.token_name = "Token Name can be 16 characters long at max.";
						}
						if (!values.currency_code) {
							errors.currency_code = "Currency code name cannot be blank.";
						} else if (values.currency_code && values.currency_code > 8 && values.currency_code < 3) {
							errors.currency_code = "Currency Code must be between 3 and 8 characters long.";
						} else if (values.currency_code.toUpperCase() !== values.currency_code) {
              errors.currency_code = "Capital Letters Only";
            } else if ( /\d/.test(values.currency_code)) {
              errors.currency_code = "No numbers allowed";
            } else if (values.currency_code.includes(' ')) {
              errors.currency_code = "No spaces allowed"
            }
						if (!values.decimal) {
							errors.decimal = "Decimal cannot be blank.";
						} else if(isNaN(values.decimal)) {
							errors.decimal = "Must be a number.";
						} else if(values.decimal > 6) {
							errors.decimal = "Must be less than 6.";
						}
						if (!values.description) {
							errors.description = "Notes cannot be blank.";
						}
						if (!values.token_owner) {
							errors.token_owner = "DID cannot be blank.";
						} 
						if (!values.token_symbol) {
							errors.token_symbol = "A Logo is needed for your token.";
						} else if (values.token_symbol && fileURL.length === 0) {
							errors.token_symbol = "Generating Image URL....";
						}
						else if (values.token_symbol && fileURL === "ERROR") {
							errors.token_symbol = "Something Went Wrong. Try Refresh or Re-Sign-In";
						}
						return Object.keys(errors).length ? errors : resolve(values);
					}}
					render = {({handleSubmit, form, submitting, pristine, values }) => (
						<form onSubmit = {handleSubmit}>
						    <Field name = "description">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "description" className = "form-label">Enter Notes</label>
										<input {...input} 
											type = "text" 
											className =  {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"}
											id="description" 
											placeholder = "Enter some text to describe this transaction"
										/>
                    {meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
							<Field name = "token_owner">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "token_owner" className = "form-label">Enter DID to which the tokens will be issued</label>
										<input {...input} 
											type = "text" 
											className =  {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"}
											id="token_owner" 
											placeholder = "Enter DID"
										/>
                    {meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
							<Field name = "token_name">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "token_name" className = "form-label">Enter Name Of The Currency (e.g Yidindji Dollar)</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="token_name" 
											placeholder = "Token Name"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
							<Field name = "decimal">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "decimal" className = "form-label">Enter Allowed Decimal Places</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="decimal" 
											placeholder = "Enter Number"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
							<Field name = "currency_code">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "token_name" className = "form-label">Enter Short Name For Your Currency</label>
										<br />
										<input {...input }
										 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="currency_code" 
											placeholder = "Token Short Name - No numbers, No spaces, Capital Only, Between 3 to 8 characters long"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
							<Field name = "token_symbol">
								{({ input: {value, onChange, ...input}, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "token_symbol" className = "form-label">Upload Image For Your Logo</label>
										<input {...input} 
											type = "file" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="token_symbol"
											accept="image/*"
  											onChange = {({target}) => {
												  onChange(target.files[0]);
												  if(target.files[0]) {  
														getImageUrl(target.files[0]);
												  }
											}}
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Image Uploaded Successfully</div>}	
									</div>
								)}
							</Field>
							{/* <div className = "mb-3">
								<div className = "accordion" id = "sponsorAccordion">
									<div className = "accordion-item">
										<h5 className = "accordion-header" id = "approverHeader">
											<button 
												className = "accordion-button" 
												type = "button" 
												data-bs-toggle = "collapse"
												data-bs-target = "#favApprovers"
											>
												Select Required Approvers (Optional)
											</button>
										</h5>
										<div id = "favApprovers" className = "accordion-collapse collapse" aria-labelledby = "approverHeader">
											<div className = "accordion-body">
												{getFavApprovers()}
											</div>
										</div>
									</div>
								</div>
							</div> */}
							<div className = "row justify-content-center">
								<div className = "col-3 mt-3">
									<button 
										className = "btn btn-outline-danger btn-block"
										onClick = {() => props.history.push("/vc/create-new-vc")}
									>
										Back
									</button>
								</div>
								<div className = "col-3 mt-3">
									<button 
										className = "btn btn-outline-primary btn-block"
										type = "submit"
										disabled = {submitting}
									>
										Proceed
									</button>
								</div>
							</div>
						</form>
					)}
				/>		
			</div>
		</div>
	)
}

export default TokenVCForm;