import React, {useContext, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import AccountContext from './AccountContext';
import { logout } from '../services/loginService';

function Navbar(props) {
  
  const {loggedIn, setLoggedIn} = useContext(AccountContext);

  useEffect(() => {
    let token = localStorage.getItem('publicKycLogin')
    if(token) {
      setLoggedIn(true)
      props.history.push('/public-kyc-register')
    } else {
      setLoggedIn(false)
    }
  }, [props.history, setLoggedIn])

	return (
		<nav className="navbar navbar-expand-lg fixed-top bg-success">
			<div className="container-fluid">
        <span className="navbar-brand text-white">
          <img src="/64PX-x-64PX.png" alt="" width="30" height="30" className="d-inline-block align-text-top" />
				  &nbsp;Yidindji KYC Registration
        </span>
        <div className="d-flex">
            <NavLink 
						  className="nav-link text-white bg-transparent" 
						  to="/public-kyc-register"
					  >
						  Forms
					  </NavLink>
            <NavLink 
						  className={`nav-link text-white bg-transparent ${ loggedIn ? "" : "d-none"}`}
						  to="/public-kyc-sign-in"
              onClick = {() => {localStorage.removeItem("publicKycLogin"); logout()}}
					  >
						  Sign Out
					  </NavLink>
        </div>
			</div>
		</nav>
	);
}

export default Navbar;