import React, { useState, useEffect } from "react";
import { GetImage } from "../../services/vcService";
import CreateVCForm from "../BTCForms/CreateVCForm";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

function TokenAdminVC(props) {
  const [myVC, setMyVC] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [bgColor, setBgColor] = useState(null);
  const [formType, setFormType] = useState("");
  const [rstat, setRstat] = useState(null);
  const [statDC, setStatDC] = useState(null);
  const [cnd, setCnd] = useState("d-none");
  const [issueBal, setIssueBal] = useState(null);
  const [issueDID, setIssueDID] = useState(null);

  useEffect(() => {
    setMyVC(props.vc);
  }, [props.vc]);

  useEffect(() => {
    if (myVC !== null) {
      if (myVC.request_status === "P") {
        setRstat("P");
      } else if (myVC.request_status === "C") {
        setBgColor("#cf2323");
        setStatDC("Cancelled");
        setCnd("");
        setRstat("C");
      } else if (myVC.request_status === "S") {
        setRstat("S");
      } else if (myVC.request_status === "A") {
        if (myVC.hasOwnProperty("vc_id")) {
          setRstat("A");
        } else {
          setRstat("S");
        }
      } else if (myVC.request_status === "R") {
        setRstat("R");
      } else if (myVC.request_status === "D") {
        setBgColor("#2323cf");
        setStatDC("Declined");
        setCnd("");
        setRstat("D");
      }
    }
  }, [myVC]);

  useEffect(() => {
    if (myVC !== null && myVC.hasOwnProperty("token_symbol")) {
      const pathArray = myVC.token_symbol.split('/');
      const path_image = pathArray[pathArray.length -2] + '/' + pathArray[pathArray.length -1]
      let res = GetImage(path_image);
      res.then(function (v) {
        if (v.hasOwnProperty("message")) {
          setNewUrl(v.message);
        }
      });
    }
  }, [myVC]);

  function timeStamp(value) {
    if (value) {
      let utcTime = "2017-02-02 08:00:13.567";
      var offset = moment(value).utcOffset();
      let time = moment(value).utc(utcTime).utcOffset(offset).format('MMMM Do YYYY, h:mm:ss a')
    
      return time; 
     }
  }
  function getHeader() {
    if (myVC !== null) {
      return (
        <div className="card">
          <div className="row border-bottom m-0">
            <div className="col-4 border-right my-auto">
              <img
                className="m-auto"
                src={newUrl}
                alt="Not Available"
                style={{ maxHeight: "290px", maxWidth: "290px" }}
              />
            </div>
            <div className="col-8">
              <div className="row py-2">
                <div className="col-4 text-right border p-2">
                  <b>Token Name</b>
                </div>
                <div className="col-8 border p-2">{myVC.token_name}</div>
              </div>
              <div className="row mb-2">
                <div className="col-4 text-right border p-2">
                  <b>Created On</b>
                </div>
                <div className="col-8 border p-2">
                  {timeStamp(myVC.timestamp)}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4 text-right border p-2">
                  <b>Organisation</b>
                </div>
                <div className="col-8 border p-2">{myVC.client}</div>
              </div>
              <div className="row mb-2">
                <div className="col-4 text-right border p-2">
                  <b>Allowed Decimals</b>
                </div>
                <div className="col-8 border p-2">{myVC.decimal}</div>
              </div>
              <div className="row mb-2">
                <div className="col-4 text-right border p-2">
                  <b>Currency Code</b>
                </div>
                <div className="col-8 border p-2">{myVC.currency_code}</div>
              </div>
              <div className="row mb-2">
                <div className="col-4 text-right border p-2">
                  <b>UID</b>
                </div>
                <div className="col-8 border p-2">{myVC.uid}</div>
              </div>
              <div className="row mb-2">
                <div className="col-4 text-right border p-2">
                  <b>Request ID</b>
                </div>
                <div className="col-8 border p-2">{myVC.request_id}</div>
              </div>
              <div className="row mb-2">
                <div className="col-4 text-right border p-2">
                  <b>SWN DID Submitter And Approver</b>
                </div>
                <div className="col-8 border p-2">{myVC.submitter_did}</div>
              </div>
              <div className="row mb-2">
                <div className="col-4 text-right border p-2">
                  <b>Token Owner</b>
                </div>
                <div className="col-8 border p-2">{myVC.token_owner}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function getDetails() {
    if (myVC !== null) {
      if (rstat === "C" || rstat === "D") {
        return (
          <div className={`p-5 border ${cnd}`}>
            <div className="row m-0 text-center">
              <div className="col-4 mb-2 py-2 border">
                <b>Current Status</b>
              </div>
              <div
                className="col-4 mb-2 py-2 rounded text-white"
                style={{ backgroundColor: bgColor }}
              >
                {statDC}
              </div>
            </div>
            <div className="row mx-0 mt-3 text-center">
              <div className="col-4 mb-2 py-2 border">
                <b>Message</b>
              </div>
              <div className="col mb-2 py-2 border">
                VC Request has been {statDC}
              </div>
            </div>
          </div>
        );
      } else if (rstat === "P") {
        return (
          <div className="p-5 border">
            <div className="row m-0 text-center">
              <div className="col-4 mb-2 py-2 border">
                <b>Current Status</b>
              </div>
              <div
                className="col-4 mb-2 py-2 rounded"
                style={{ backgroundColor: "#cf9823" }}
              >
                Pending
              </div>
            </div>
            <div className="row mx-0 mt-3 text-center">
              <div className="col-4 mb-2 py-2 border">
                <b>Message</b>
              </div>
              <div className="col mb-2 py-2 border">
                VC Request sent to admin for Review. Waiting for Admin to set
                Reservable Fund
              </div>
            </div>
          </div>
        );
      } else if (rstat === "R") {
        return (
          <div className="p-5 border">
            <div className="row m-0 text-center">
              <div className="col-4 mb-2 py-2 border">
                <b>Current Status</b>
              </div>
              <div
                className="col-4 mb-2 py-2 rounded"
                style={{ backgroundColor: "#ffff4d" }}
              >
                In Review
              </div>
            </div>
            <div className="row mx-0 mt-3 text-center">
              <div className="col-4 mb-2 py-2 border">
                <b>Message</b>
              </div>
              <div className="col mb-2 py-2 border">
                Reservable fund has been set. Waiting for your VC to be Ready For Voting.
              </div>
            </div>
            <div className="row m-0 text-center">
              <div className="col-4 mt-3 mb-2 py-2 border">
                <b>Reservable Fund</b>
              </div>
              <div className="col-4 mt-3 mb-2 py-2 border">
                {myVC.reservable_fund}
              </div>
            </div>
          </div>
        );
      } else if (rstat === "S") {
        return (
          <div className="p-5 border">
            <div className="row m-0 text-center">
              <div className="col-4 mb-2 py-2 border">
                <b>Current Status</b>
              </div>
              <div
                className="col-4 mb-2 py-2 rounded"
                style={{ backgroundColor: "#23c6cf" }}
              >
                Submitted
              </div>
            </div>
            <div className="row mx-0 mt-3 text-center">
              <div className="col-4 mb-2 py-2 border">
                <b>Message</b>
              </div>
              <div className="col mb-2 py-2 border">
                Council is voting on your VC request
              </div>
            </div>
            <div className="row m-0 text-center">
              <div className="col-4 mt-3 mb-2 py-2 border">
                <b>Proposal Hash</b>
              </div>
              <div className="col mt-3 mb-2 py-2 border">
                {myVC.hasOwnProperty('proposal_hash') ? myVC.proposal_hash : "Proposal Hash Not Generated Yet"}
              </div>
            </div>
            <div className="row m-0 text-center">
              <div className="col-4 mt-3 mb-2 py-2 border">
                <b>Transaction Hash</b>
              </div>
              <div className="col mt-3 mb-2 py-2 border">
                {myVC.hasOwnProperty('transaction_hash') ? myVC.txn_hash : "Transaction Hash Not Generated Yet"}
              </div>
            </div>
            <div className="row m-0 text-center">
              <div className="col-4 mt-3 mb-2 py-2 border">
                <b>Proposal Index</b>
              </div>
              <div className="col mt-3 mb-2 py-2 border">
                {myVC.hasOwnProperty('proposal_index') ? myVC.proposal_index : "Proposal Index Not Generated Yet"}
              </div>
            </div>
            <div className="row m-0 text-center">
              <div className="col-4 mt-3 mb-2 py-2 border">
                <b>Proposal Closed By</b>
              </div>
              <div className="col mt-3 mb-2 py-2 border">
                {myVC.hasOwnProperty('closer_did') ? myVC.closer_did : "Proposal Voting haven't been closed yet"}
              </div>
            </div>
          </div>
        );
      } else if (rstat === "A") {
        return (
          <div className="p-5 border">
            <div className="row m-0 text-center">
              <div className="col-4 mb-2 py-2 border">
                <b>Current Status</b>
              </div>
              <div
                className="col-4 mb-2 py-2 rounded"
                style={{ backgroundColor: "#23cf26" }}
              >
                Accepted
              </div>
            </div>
            <div className="row mx-0 mt-3 text-center">
              <div className="col-4 mb-2 py-2 border">
                <b>Message</b>
              </div>
              <div className="col mb-2 py-2 border">VC has been accepted.</div>
            </div>
            <div className="row m-0 text-center">
              <div className="col-4 mt-3 mb-2 py-2 border">
                <b>VC ID</b>
              </div>
              <div className="col mt-3 mb-2 py-2 border">{myVC.vc_id}</div>
            </div>
            <div className="row m-0 text-center">
              <div className="col-4 mt-3 mb-2 py-2 border">
                <b>Currency Code</b>
              </div>
              <div className="col mt-3 mb-2 py-2 border">
                {myVC.currency_code}
              </div>
            </div>
            <div className="row m-0 text-center">
              <div className="col-4 mt-3 mb-2 py-2 border">
                <b>Available Actions</b>
              </div>
              {availableActions()}
            </div>
          </div>
        );
      }
    }
  }

  function availableActions() {
    if (myVC !== null) {
      if (props.myDID === myVC.token_owner) {
        if (myVC.hasOwnProperty("is_token_issued")) {
          if(myVC.is_token_issued) {
            return (
              <React.Fragment>
                <div className="col-4 mt-3 mb-2 py-2 border">
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => showForm()}
                  >
                    Create Mint Slash VC
                  </button>
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <div className="col-4 mt-3 mb-2 py-2 border">
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => showForm()}
                  >
                    Issue Tokens
                  </button>
                </div>
              </React.Fragment>
            );
          }
        }
      } else return (
        <React.Fragment>
          <div className="col mt-3 mb-2 py-2 border">
            No available actions for you
          </div>
        </React.Fragment>
      );
    }
  }

  function showForm() {
    if (myVC.hasOwnProperty("vc_id") && myVC.request_status === "A") {
      if (myVC.hasOwnProperty("currency_id")) {
        setFormType("MintSlash");
      } else {
        setFormType("IssueTokens");
      }
    }
  }

  function getForm() {
    if (formType === "MintSlash") {
      return (
        // <MintAndSlashForm
        // 	header = {"Mint and Slash Form"}
        // 	submitForm = {submitForm}
        // 	vcId = {myVC.vc_id}
        // 	currencyId = {myVC.currency_id}
        // />
        <CreateVCForm submitForm={props.submitForm} />
      );
    } else if (formType === "IssueTokens") {
      return (
        <div className="card my-5">
          <TextField
            id="outlined-basic"
            label="DID"
            variant="outlined"
            onChange={(e) => setIssueBal(e.target.value)}
          />

          <TextField
            id="outlined-basic"
            label="Amount"
            variant="outlined"
            onChange={(e) => setIssueDID(e.target.value)}
          />
          <button
            className="btn btn-outline-dark"
            onClick={() => props.issueTokens(myVC, issueDID, issueBal)}
          >
            Proceed Pairwise
          </button>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      {getHeader()}
      {getDetails()}
      {getForm()}
      <div style={{ height: "300px" }}></div>
    </React.Fragment>
  );
}

export default TokenAdminVC;
