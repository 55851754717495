import React, {useEffect, useState} from 'react';
import { Clist } from '../constants/BTCTestData/BTCVC';
import BTCCertificate from './BTCCertificate';

function BTCSettingsCreatedByYou(props) {

	const [filter, setFilter] = useState("");
	useEffect(() => {
		if(filter.length === 0) {
			setFilter("Choose")
		}
	}, [filter.length]);

	function changeFilter(value) {
		setFilter(value);
	}

	function dropdown() {
		return (
			<div className = "dropdown">
				<button className="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
					{filter}
				</button>
				<ul className = "dropdown-menu" aria-labelledby="filterDropdown">
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("Choose")}>None</button></li>
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("Date Issued")}>Date Issued</button></li>
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("General Business")}>General Business</button></li>
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("Special Appropriations")}>Special Appropriations</button></li>
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("Primary Signatories")}>Primary Signatories</button></li>
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("Sponsor Signatories")}>Sponsor Signatories</button></li>
				</ul>
			</div>
		);
	}

	function getCreated() {
		const listItems = Clist.map((item) => 
			<div>
				<BTCCertificate data = {item} />
				<div className = "row justify-content-center text-center">
					<div className = "col-3">
						<button
							type = "button"
							className = "btn btn-info btn-lg btn-block my-2"
						>
							View Details
						</button>
					</div>
				</div>
			</div>
		);

		return (
			<ul className = "p-0">{listItems}</ul>
		);
	}

	return (
		<React.Fragment>
			<div className = "row justify-content-between">
				<div className = "col-6 pt-5">
					<h5>Cerificates Created By You</h5>
				</div>
				<div className = "col-4 pt-5">
					{dropdown()}
				</div>
				<div className = "clearfix">
					<button className = "btn btn-outline-danger" 
						onClick = {() => props.back()}
						style = {{position: "fixed", bottom: "30px", right: "30px"}}
					>
						Back
					</button>
				</div>
			</div>
			{getCreated()}
		</React.Fragment>
	);
}

export default BTCSettingsCreatedByYou;