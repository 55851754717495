import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import IdCard from "../components/IdCard";
import { GetCall, PostCall, GetImage } from "../services/KycService";
import ImageModal from "./ImageModal";
import SelfieModal from "./SelfieModal";
import FrontModal from "./FrontModal";
import BackModal from "./BackModal";
import { data } from "jquery";
import {KYC_BUCKET_NAME,BASE_URL} from '../config';
import { Auth } from "aws-amplify";
import { getS3PresignedData, uploadFileToS3 } from "../services/s3two";
import axios from "axios";
import PublicIdCard from "../components/PublicIdCard";
import moment from "moment";
const PublicKycDetails = (props) => {
  const [uid, setUid] = useState("");
  useEffect(() => {
    if (props.history) {
      setUid(props.history.location.state.uid);
    } else {
      setUid(props.uid);
    }
  });
  // const uid = props.history.location.state.uid;
  const [user, setUser] = useState([]);
  const [kycStat, setStat] = useState("nil");
  const [accBtn, setAccBtn] = useState(false);
  const [rejBtn, setRejBtn] = useState(false);
  const [frontImg, setFrontImg] = useState("");
  const [backImg, setBackImg] = useState("");
  const [img, setImg] = useState("");
  const [selfieImg, setSelfieImg] = useState("");
  const [dob, setDob] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
 
// const [updateIcUrl, setUpdatedIcUrl] = useState (null);
const [updateDiUrl, setUpdatedDiUrl] = useState (null);
const [updateSiUrl, setUpdatedSiUrl] = useState (null);
const [updateDFUrl, setUpdatedDFUrl] = useState (null);
const [updateDBUrl, setUpdatedDBUrl] = useState (null);
// console.log(updateDFUrl)
  // console.log("user", user);
  useEffect(() => {
    const fetchIndividualKyc = async () => {
      const res = await GetCall("getUserKycDetails/?uid=" + uid);
      // console.log("DOB = ",res.message.dob)
      console.log(res.message)
      // const publicdid = 
      const dateString = res.message.dob;
      const formatDate = (dateString) => {
        const options = { year: "numeric", month: "numeric", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
      };
      const dob = formatDate(dateString);
      setDob(dob);

      const timest = res.message.timestamp;
      let timesty;
      if(timest !== undefined) {
        timesty = timest.replace("T", " ")
        timesty = new Date(`${timesty} UTC`)
      }
      const formattimest = (timest) => {
        const options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
        return new Date(timest).toLocaleDateString(undefined, options);
      };
      const timestamp = formattimest(timesty);
      setTimestamp(timestamp);
      if (res.message) {
        setUser(res.message);
        if (res.message.kyc_status === "A") {
          setStat("Accepted");
          setAccBtn(true);
        }
        if (res.message.kyc_status === "D") {
          setStat("Rejected");
          setRejBtn(true);
        }
        if (res.message.kyc_status === "S") {
          setStat("Submitted");
          setAccBtn(false);
          setRejBtn(false);
        }
        if (!res.message.hasOwnProperty("kyc_status")) {
          setStat("error");
          setAccBtn(true);
          setRejBtn(true);
        }
      } else {
        throw new Error("API is not returning the data");
      }
    };
    fetchIndividualKyc();
  }, [uid, kycStat]);

  useEffect(() => {
    const fetchImageLink = async () => {
      const img_url = user.document_image_url;
      //  let key;
      try {
        //const path_image = img_url.substring(43, img_url.length);
        const pathArray = img_url.split('/');
        const path_image = pathArray[pathArray.length -2] + '/' + pathArray[pathArray.length -1];
        const profile_image = await GetImage(KYC_BUCKET_NAME, path_image);
        setImg(profile_image.message);
      } catch (err) {
        console.log(err);
      }
    };
    fetchImageLink();

    // const fetchFrontImageLink = async () => {
    //   const img_url = user.id_document_front;
    //   //  let key;
    //   try {
    //     const path_image = img_url.substring(49, img_url.length);
    //     const profile_image = await GetImage(
    //       `getKycPresignedURL?bucket=user-kyc-document&path=${path_image}`
    //     );
    //     setFrontImg(profile_image.message);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };
    // fetchFrontImageLink();

    // const fetchBackImageLink = async () => {
    //   const img_url = user.id_document_back;
    //   //  let key;
    //   try {
    //     const path_image = img_url.substring(49, img_url.length);
    //     const profile_image = await GetImage(
    //       `getKycPresignedURL?bucket=user-kyc-document&path=${path_image}`
    //     );
    //     setBackImg(profile_image.message);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };
    // fetchBackImageLink();


    const fetchSelfieLink = async () => {
      const img_url = user.selfie_image_url;
      //  let key;
      try {
        //const path_image = img_url.substring(43, img_url.length);
        const pathArray = img_url.split('/');
        const path_image = pathArray[pathArray.length -2] + '/' + pathArray[pathArray.length -1];
        const profile_image = await GetImage(KYC_BUCKET_NAME, path_image);
        setSelfieImg(profile_image.message);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSelfieLink();
    if (user.kyc_status === "A") {
      setRejBtn(false);
      setAccBtn(true);
    } else if (user.kyc_status === "D") {
      setRejBtn(true);
      setAccBtn(false);
    } else {
      setRejBtn(false);
      setAccBtn(false);
    }
  }, [user]);

  const onStatChange = async (input_data) => {
    // if(res){
    let changeData = input_data === "A" ? "Accepted" : "Rejected";
    console.log(changeData);
    if (input_data === "A") {
      const info = { uid: user.uid, kyc_status: input_data };
      const res = await PostCall("updateUserKyc/", info);
      console.log(res);
      setAccBtn(true);
      setRejBtn(false);
      setStat("Accepted");
      Swal.fire("Success!","This KYB is approved." , "success");
    } else {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Rejection Note",
        inputPlaceholder: "Type Rejection Note...",
        inputAttributes: {
          "aria-label": "Type Rejection Note",
        },
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value.length > 0) {
              resolve()
            } else {
              resolve('You need to enter the rejection note.')
            }
          })
        }
      });
      if (text) {
        const info = {
          uid: user.uid,
          kyc_status: input_data,
          rejection_note: text,
        };
        const res = await PostCall("updateUserKyc/", info);
        console.log(res);
        setStat("Rejected");
        setRejBtn(true);
        Swal.fire("Success!", "This KYB is rejected. Please resubmit the KYB!", "success");
      }
    }
    // }
  };
  // const updateIdImage = async (selectedFileObj, docType) => {
  //   const { name, type } = selectedFileObj;
  //   let idToken;
  //   await Auth.currentSession().then((data) => {
  //     console.log("JWTTOKEN", data.idToken.jwtToken);
  //     idToken = data.idToken.jwtToken;
  //   });
  //   const preSignedObj = await getS3PresignedData(
  //     idToken,
  //     name,
  //     "ssid-user-kyc-documents",
  //     false,
  //     type,
  //     user.email,
  //     user.phoneNumber || user.phone_number,
  //   );
  //   uploadFileToS3(preSignedObj.data, selectedFileObj)
  //     .then((res) => {
  //       let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
  //       setUpdatedIcUrl(fileUrl);
  //       // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
  //     })
  //     .catch((_err) => alert("Upload Failed, Please try again..", _err));
  // };

  const updateSelfImage = async (selectedFileObj, docType) => {
    const { name, type } = selectedFileObj;
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "user-kyc-document",
      false,
      type,
      user.email,
      user.phoneNumber || user.phone_number,
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setUpdatedSiUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };

const updateDocImage = async (selectedFileObj, docType) => {
    const { name, type } = selectedFileObj;
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "user-kyc-document",
      false,
      type,
      user.email,
      user.phoneNumber || user.phone_number,
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setUpdatedDiUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  // const updateFrontImage = async (selectedFileObj, docType) => {
  //   const { name, type } = selectedFileObj;
  //   let idToken;
  //   await Auth.currentSession().then((data) => {
  //     console.log("JWTTOKEN", data.idToken.jwtToken);
  //     idToken = data.idToken.jwtToken;
  //   });
  //   const preSignedObj = await getS3PresignedData(
  //     idToken,
  //     name,
  //     "user-kyc-document",
  //     false,
  //     type,
  //     user.email,
  //     user.phoneNumber || user.phone_number,
  //   );
  //   uploadFileToS3(preSignedObj.data, selectedFileObj)
  //     .then((res) => {
  //       let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
  //       setUpdatedDFUrl(fileUrl);
  //       // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
  //     })
  //     .catch((_err) => alert("Upload Failed, Please try again..", _err));
  // };
  // const updateBackImage = async (selectedFileObj, docType) => {
  //   const { name, type } = selectedFileObj;
  //   let idToken;
  //   await Auth.currentSession().then((data) => {
  //     console.log("JWTTOKEN", data.idToken.jwtToken);
  //     idToken = data.idToken.jwtToken;
  //   });
  //   const preSignedObj = await getS3PresignedData(
  //     idToken,
  //     name,
  //     "user-kyc-document",
  //     false,
  //     type,
  //     user.email,
  //     user.phoneNumber || user.phone_number,
  //   );
  //   uploadFileToS3(preSignedObj.data, selectedFileObj)
  //     .then((res) => {
  //       let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
  //       setUpdatedDBUrl(fileUrl);
  //       // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
  //     })
  //     .catch((_err) => alert("Upload Failed, Please try again..", _err));
  // };
  // console.log(user)
    const imageUpdateHandler= async (type)=> {
    let CitizenFormData = {}
     let suid, semail

      await Auth.currentSession().then((data) => {
      suid = user.uid;
      semail= user.email;
    
      });
     
      CitizenFormData = {
      kyc_status:user.kyc_status,
      uid:suid,
      }
      if (type === "docimg") {
        CitizenFormData = updateDiUrl !== null ? {...CitizenFormData, document_image_url:updateDiUrl} : CitizenFormData
      } else if(type === "selfiimg"){
      CitizenFormData = updateSiUrl !== null ? {...CitizenFormData, selfie_image_url:updateSiUrl} : CitizenFormData
      }
      // else if (type === "frontselfiimg") {
      //   CitizenFormData = updateDFUrl !== null ? {...CitizenFormData, id_document_front:updateDFUrl} : CitizenFormData
      // }else if (type === "backselfiimg"); {
      // CitizenFormData = updateDBUrl !== null ? {...CitizenFormData, id_document_back:updateDBUrl} : CitizenFormData
      // }
      
    console.log(CitizenFormData, "citizen data");
    return Auth.currentSession().then((data) => {
      //console.log("JWTTOKEN", data); 
      axios({
        method: "post",
        url: `${BASE_URL}updateUserKyc`,
        data: CitizenFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: data.idToken.jwtToken,
        },
      })
        .then((res) => {
          Swal.fire("Success!", "File Uploaded successfully", "success");
        })
        .catch((err) => console.log("Error During Upload File...", err));
    });
  }
  return (
    <>
      <Body>
        <Container>
          <Wrapper>
            <div style={action_btn}>
              {kycStat === "nil" ? (
                <Loader type="ThreeDots" color="black" />
              ) : (
                <div class="ui buttons">
                  <button
                    disabled={accBtn}
                    class="ui positive button"
                    onClick={() => onStatChange("A")}
                  >
                    Accept KYB
                  </button>
                  <div class="or"></div>
                  <button
                    disabled={rejBtn}
                    class="ui negative button"
                    onClick={() => onStatChange("D")}
                  >
                    Reject KYB
                  </button>
                </div>
              )}
            </div>
            <br />
            <br />
            <div className="setUserStamp">
              <div className="row  justify-content-center">
                {/* ADD approved by or rejected by user in front of name in the getName function */}
                {user &&
                  user.kyc_status === "A" &&
                  user.hasOwnProperty("approvedBy") &&
                  user.approvedBy && (
                    <table className="table table-bordered">
                      <thead>
                        <tr style={{ padding:"20px" }} >
                          <th scope="col-4" >
                            <h5 className="text-center">
                              <span style={{ color: "green" }}>
                                KYB Accepted by: &nbsp;
                              </span>
                              {user?.approvedBy.firstName +
                                " " +
                                user?.approvedBy.lastName}
                            </h5>
                          </th>
                          <th scope="col-4">
                            <h5 className="text-center">
                              At: &nbsp; {moment.utc(user?.approvedBy.updatedAt).local().format("LLL")}
                            </h5>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  )}
                {user &&
                  user.kyc_status === "D" &&
                  user.hasOwnProperty("rejectedBy") &&
                  user.rejectedBy && (
                    <table className="table table-bordered" >
                      <thead>
                        <tr style={{ padding:"20px" }} >
                          <th scope="col-4" >
                            <h5 className="text-center">
                              <span style={{ color: "red" }}>
                                KYB Rejected by: &nbsp;
                              </span>
                              {user?.rejectedBy.firstName +
                                " " +
                                user?.rejectedBy.lastName}
                            </h5>
                          </th>
                          <th scope="col-4">
                            <h5 className="text-center">
                              At: &nbsp; {moment.utc(user?.rejectedBy.updatedAt).local().format("LLL")}
                            </h5>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  )}
              </div>
            </div>
            <br />
            <PublicIdCard data={user} img={selfieImg}/>
            <br />
            <br />
            <br />
            { kycStat === "Submitted" && 
              <div className = "row">
                {/* <div className = "col-6 mt-1">
                  <label htmlFor = "idCImgUp" className = "form-label">Id card Image update</label>
                  <div className = "row ml-1">
                    <input accept = "image/*" className = "col-9 form-control" type = "file" id = "idCImgUp" onChange = {(e) => updateIdImage(e.target.files[0])}/>
                    <button className = "btn btn-primary col-3" onClick = {(e) => imageUpdateHandler("idcardimg")}>submit</button>
                  </div>
                </div> */}
                {/* <div className = "col-6 mt-1">
                  <label htmlFor = "idCImgUp" className = "form-label">Document Image update</label>
                  <div className = "row ml-1">
                    <input accept = "image/*" className = "col-9 form-control" type = "file" id = "idCImgUp" onChange = {(e) => updateDocImage(e.target.files[0])}/>
                    <button className = "btn btn-primary col-3" onClick = {(e) => imageUpdateHandler("docimg")}>Submit</button>
                  </div>
                </div>
                <div className = "col-6 mt-1">
                  <label htmlFor = "idselImgUp" className = "form-label">Selfi Image update</label>
                  <div className = "row ml-1">
                    <input accept = "image/*" className = "col-9 form-control" type = "file" id = "idselImgUp" onChange = {(e) => updateSelfImage(e.target.files[0])}/>
                    <button className = "btn btn-primary col-3" onClick = {(e) => imageUpdateHandler("selfiimg")}>Submit</button>
                  </div>
                </div> */}
               
                 {/* <div className = "col-6 mt-1">
                  <label htmlFor = "idcocImgUp" className = "form-label">Document Image front update</label>
                  <div className = "row ml-1">
                    <input accept = "image/*" className = "col-9 form-control" type = "file" id = "idcocImgUp" onChange = {(e) => updateFrontImage(e.target.files[0])}/>
                    <button className = "btn btn-primary col-3" onClick = {(e) => imageUpdateHandler("frontselfiimg")}>Submit</button>
                  </div>
                </div>
                <div className = "col-6 mt-1">
                  <label htmlFor = "idbackImgUp" className = "form-label">Document Image back update</label>
                  <div className = "row ml-1">
                    <input accept = "image/*" className = "col-9 form-control" type = "file" id = "idbackImgUp" onChange = {(e) => updateBackImage(e.target.files[0])}/>
                    <button className = "btn btn-primary col-3" onClick = {(e) => imageUpdateHandler("backselfiimg")}>Submit</button>
                  </div>
                </div> */}
              </div>
            }
            <Divider />
            <Row>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Basic Details</Card.Title>

                    <Card.Text>
                      <p>
                        {user.email ? "Email : " : "Phone Number : "}
                        <b>{user.email ? user.email : user.phoneNumber || user.phone_number}</b>
                      </p>
                      <p>
                      Entity Name :{" "}
                        <b>
                          {user.entityName}
                        </b>
                      </p>
                      {/* <p>
                        Last Name :{" "}
                        <b>
                          {user.last_name}
                        </b>
                      </p> */}
                      <p>
                      Entity Number : <b>{user.entityNumber}</b>
                      </p>
                      <p>
                        Phone No : <b>{user.phoneNumber || user.phone_number}</b>
                      </p>
                      <p>
                        Contact person : <b>{user.contactPerson}</b>
                      </p>
                      <p>
                        Fax : <b>{user.fax}</b>
                      </p>
                      <p>
                      Sponsor: <b>{user.sponsor}</b>
                      </p>
        
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>KYC Details</Card.Title>

                    <Card.Text>
                      <p>
                        KYC Status : <b>{kycStat}</b>
                      </p>
                      <p>
                        KYC Submission Date : <b>{timestamp}</b>
                      </p>
                      <br />
                      {user.kyc_status === "D" || kycStat === "Rejected" ? (
                        <p style={{ color: "red" }}>
                          Rejection Note : <b>{user.rejection_note}</b>
                        </p>
                      ) : null}
                      {/* <p style={{color: 'red'}}>Rejection Note : <b>{user.rejection_note}</b></p>  */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Address</Card.Title>

                    <Card.Text>
                      <p>
                        {user.housingNo ? "Housing Number :" : null}{" "}
                        <b>{user.housingNo ? user.housingNo : ""}</b>
                      </p>
                      <p>
                         Address :{" "}
                        <b>
                          {user.residentalAddr
                            ? user.residentalAddr
                            : user.address}
                        </b>
                      </p>
                      <p>
                      Country :{" "}
                        <b>
                          { user.country}
                        </b>
                      </p>
                      <p>
                      Business Type:{" "}
                        <b>
                          { user.business}
                        </b>
                      </p>
                      <p>
                      Website :{" "}
                        <b>
                          { user.website}
                        </b>
                      </p>
                      
                      <p></p>
                      {/* <p>
                        City :{" "}
                        <b>
                          {user.hasOwnProperty('city') ? user.city : ""}
                        </b>
                      </p>
                      <p>
                        Country :{" "}
                        <b>
                          {user.hasOwnProperty('country') ? user.country : ""}
                        </b>
                      </p>
                      <p>
                        Province :{" "}
                        <b>
                          {user.hasOwnProperty('province') ? user.province : ""}
                        </b>
                      </p>
                      <p>
                        ZIP code :{" "}
                        <b>
                          {user.hasOwnProperty('zip_code') ? user.zip_code : ""}
                        </b>
                      </p> */}
                     
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              {user.office ? (
                <>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        {/* <Card.Title style={card_title}>
                          Document Details of Govt. Worker
                        </Card.Title> */}

                        {/* <Card.Text>
                          <p>
                            Date of official apointment :{" "}
                            <b> {user.dateOfAppointment} </b>
                          </p>
                        </Card.Text> */}
                        <Card.Text>
                          <p>
                            Document Name:
                            <b> {user.document_type} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Document Number :
                            <b> {user.document_number} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Document Expiry Date :
                            <b> {user.document_expiry_date} </b>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          Document Details
                        </Card.Title>

                        {/* <Card.Text>
                
                          <p>
                            Passport Number : <b>{user.document_number}</b>
                          </p>
                          </Card.Text> */}
                          <Card.Text>
                          <p>
                            Document Name:
                            <b> {user.document_type} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Document Number :
                            <b> {user.document_number} </b>
                          </p>
                        </Card.Text>
                        <Card.Text>
                          <p>
                            Document Expiry Date :
                            <b> {user.document_expiry_date} </b>
                          </p>
                          <p>
                          Registration Name: <b>{user.registrationName}</b>
                          </p>
                          <p>
                          Licence Number: <b>{user.licenceNumber}</b>
                          </p>
                          <p>
                          Incorporation: <b>{user.incorporation}</b>
                          </p>
                          <p>
                          Additional Details: <b>{user.additionalDetails}</b>
                          </p>
                        </Card.Text>
                          
                          <br />
                          {/* <p>UID : {user.uid}</p>  */}
                        
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
            <Divider />
           {/*} {user.office ? (
              <>
                <Row>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          Government Office
                        </Card.Title>

                        <Card.Text>
                          <p>
                            Office or Department : <b>{user.office}</b>
                          </p>
                          <p>
                            Office Level : <b>{user.officeLevel}</b>
                          </p>
                          <p>
                            Security Clearence Level :{" "}
                            <b>{user.securityClearanceLevel}</b>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          {" "}
                          Other Government Details
                        </Card.Title>

                        <Card.Text>
                          <p>
                            Compensation Code : <b>{user.compensationCode}</b>
                          </p>
                          <p>
                            Pro-W Level : <b>{user.proWLevel}</b>
                          </p>
                          <p>
                            Pro-W Authorization No : <b> {user.proWAuthNo} </b>
                          </p>
                          <br />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          Vehicle Class License
                        </Card.Title>

                        <Card.Text>
                          <p>
                            Vehicle Class License : <b>{user.vehicalClass}</b>
                          </p>
                          <p>
                            Vehicle License Number :
                            <b>{user.vehicalLicenseNo}</b>
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card style={card_style}>
                      <Card.Body>
                        <Card.Title style={card_title}>
                          {" "}
                          Other Details
                        </Card.Title>

                        <Card.Text>
                          <p>
                            Doc Type : <b>Health Card</b>
                          </p>
                          <p>
                            Health Card Number : <b>{user.healthCardNo}</b>
                          </p>
                          <br />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            <Divider />*/}
            {/* {
              !user.id_document_front ? (
              null
              ) : ( <>
                <Row>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Document Front</Card.Title>

                    <Card.Text>
                      <Card style={card_style2}>
                        <p
                          style={img_user}
                          variant="primary"
                          onClick={() => setModalShow3(true)}
                        >
                          <Card.Img style={img_user} variant="top" src={frontImg} />
                        </p>
                        <FrontModal
                          img3={frontImg}
                          show={modalShow3}
                          onHide={() => setModalShow3(false)}
                        />
                      </Card>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Document Back</Card.Title>

                    <Card.Text>
                      <Card style={card_style2}>
                        <p
                          style={img_user}
                          variant="primary"
                          onClick={() => setModalShow4(true)}
                        >
                          <Card.Img
                            style={img_user}
                            variant="top"
                            src={backImg}
                          />
                        </p>
                        <BackModal
                          img4={backImg}
                          show={modalShow4}
                          onHide={() => setModalShow4(false)}
                        />
                      </Card>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

             </> )

            }  */}
            <Row>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Document Details</Card.Title>

                    <Card.Text>
                      <Card style={card_style2}>
                        <p
                          style={img_user}
                          variant="primary"
                          onClick={() => setModalShow2(true)}
                        >
                          <Card.Img style={img_user} variant="top" src={img} />
                        </p>
                        <ImageModal
                          img={img}
                          show={modalShow2}
                          onHide={() => setModalShow2(false)}
                        />
                        <div className = "col-10 mt-1">
                  <label htmlFor = "idCImgUp" className = "form-label">Update Document Image</label>
                  <div className = "row ml-0">
                    <input accept = "image/*" className = "col-9 form-control" type = "file" id = "idCImgUp" onChange = {(e) => updateDocImage(e.target.files[0])}/>
                    <button className = "btn btn-primary col-3" onClick = {(e) => imageUpdateHandler("docimg")} 
                    disabled = { updateDiUrl === null ? true : false}
                    >Submit</button>
                  </div>
                </div>
                      </Card>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card style={card_style}>
                  <Card.Body>
                    <Card.Title style={card_title}>Selfie</Card.Title>

                    <Card.Text>
                      <Card style={card_style2}>
                        <p
                          style={img_user}
                          variant="primary"
                          onClick={() => setModalShow(true)}
                        >
                          <Card.Img
                            style={img_user}
                            variant="top"
                            src={selfieImg}
                          />
                        </p>
                        <SelfieModal                
                          img2={selfieImg}
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className = "col-10 mt-1">
                  <label htmlFor = "idselImgUp" className = "form-label">Update Logo Image</label>
                  <div className = "row ml-0">
                    <input accept = "image/*" className = "col-9 form-control" type = "file" id = "idselImgUp" onChange = {(e) => updateSelfImage(e.target.files[0])}/>
                    <button className = "btn btn-primary col-3" onClick = {(e) => imageUpdateHandler("selfiimg")} 
                     disabled = { updateSiUrl === null ? true : false}
                    >Submit</button>
                  </div>
                </div>
                      </Card>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Divider />
          </Wrapper>
        </Container>
      </Body>
    </>
  );
};
export default PublicKycDetails;

const card_style = {
  position: "relative",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
};
const card_style2 = {
  width: "100%",
  height: "100%",
};
const card_title = {
  background: "#a7a7a7",
  textAlign: "center",
  color: "black",
};
const img_user = {
  height: "250px",
  cursor: "pointer",
  width: "auto",
  textAlign:"center"
};
const action_btn = {
  position: "relative",
  left: "50%",
  transform: "translate(-15%)",
};

const Body = styled.div`
  margin-top: 100px;
  width: 100%;
  height: 100%;
  padding: 40px;
  margin-bottom: 50px;
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
const Wrapper = styled.div`
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  height: 20px;
  background-color: #7e5737;
  margin-top: 10px;
  margin-bottom: 10px;
`;
