import React from 'react';
import { Form, Field } from "react-final-form";
import {InstrumentDB} from '../../constants/BTCTestData/BTCVC';

function GeneralForm(props) {

	const onSubmit = async (values) => {
		props.submitForm(values);
	}

	let formData = {...props.formData};

	function getSponsorSignatories() {
		let sponsors = [];

		InstrumentDB.map((item) => {
			if(item.instrumentType === "General Business") {
				item.sponsorSignatories.map((signatory) => {
					sponsors.push(
						<div className = "mb-2 border-bottom" key = {signatory.id}>
							<div className = "row justify-content-left">
								<div className = "col-1">
									<Field 
										name = "sponsorSignatories"
										component = "input"
										type = "checkbox"
										value = {signatory.did}
										id = {signatory.did}
									/>
								</div>
								<div className = "col-3">
									<label>
										<strong>
											&nbsp;{signatory.position}<br/>
											&nbsp;{signatory.did}
										</strong>
									</label>
								</div>
							</div>
						</div>		
					);
				});
			} else {
				return null;
			}
		});

		return (
			<ul className = "p-0">
				{sponsors}
			</ul>
		)
	}

	return (
		<div className = "card my-5 bg-light">
			<div className = "card-body">
				<div className = "row justify-content-center mb-3 text-center">
					<div className = "col-6">
						<h4>{props.header}</h4>
					</div>
				</div>
				<Form
					onSubmit = {onSubmit}
					initialValues = {{...formData}}
					validate = {(values) => {
						const errors = {};
						if (!values.serialCode) {
							errors.serialCode = "Serial Number cannot be blank";
						}
						else if (values.serialCode && values.serialCode.length < 9) {
							errors.serialCode = "Serial Number must be 9 letters long";
						}
						if (!values.exchangeTo) {
							errors.exchangeTo = "Recepient Address cannot be blank";
						}
						if (!values.amount) {
							errors.amount = "Amount cannot be blank";
						} else if(isNaN(values.amount)) {
							errors.amount = "Must be a number";
						}
						if( values.sponsorSignatories.length === 0 ) {
							errors.sponsorSignatories = "Select at least one Sponsor";
						}
						return errors;
					}}
					render = {({handleSubmit, form, submitting, pristine, values }) => (
						<form onSubmit = {handleSubmit}>
						<Field name = "notes">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "notes" className = "form-label">Notes</label>
										<input {...input} 
											type = "text" 
											className = "form-control" 
											id="notes" 
											placeholder = {formData.notes}
											value = {formData.notes}
											readOnly
											disabled
										/>	
									</div>
								)}
							</Field>
							<Field name = "serialCode">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "serialCode" className = "form-label">Enter Serial Number</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="serialCode" 
											placeholder = "Serial Code"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
							<Field name = "amount">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "amount" className = "form-label">Enter Amount</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="amount" 
											placeholder = "Enter Amount"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
							<Field name = "exchangeTo">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "exchangeTo" className = "form-label">Enter Recepient Address</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="exchangeTo" 
											placeholder = "Enter DID to which this will be minted"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
							<Field name = "primarySignatory">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "primarySignatory" className = "form-label">Primary Signatory</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="primarySignatory" 
											placeholder = {formData.primarySignatory}
											value = {formData.primarySignatory}
											readOnly
											disabled
										/>	
									</div>
								)}
							</Field>
							<div className = "mb-3">
								<div className = "accordion" id = "sponsorAccordion">
									<div className = "accordion-item">
										<h5 className = "accordion-header" id = "sponsorHeader">
											<button 
												className = "accordion-button" 
												type = "button" 
												data-bs-toggle = "collapse"
												data-bs-target = "#sponsorSignatoriesList"
											>
												Select Sponsor Signatories (At least one)
											</button>
										</h5>
										<div id = "sponsorSignatoriesList" className = "accordion-collapse collapse show" aria-labelledby = "sponsorHeader">
											<div className = "accordion-body">
												{getSponsorSignatories()}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className = "row justify-content-center">
								<div className = "col-3 mt-3">
									<button 
										className = "btn btn-outline-danger btn-block"
										onClick = {() => props.back()}
									>
										Back
									</button>
								</div>
								<div className = "col-3 mt-3">
									<button 
										className = "btn btn-outline-primary btn-block"
										type = "submit"
										disabled = {submitting}
									>
										Proceed
									</button>
								</div>
							</div>
						</form>
					)}
				/>		
			</div>
		</div>
	)
}

export default GeneralForm;