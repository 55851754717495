import React, {useState} from  'react';
import {withRouter} from 'react-router-dom';
import BTCLanding from '../components/BTCLanding';
import BTCCreate from '../components/BTCCreate';
import BTCApproved from '../components/BTCApproved';
import BTCPending from '../components/BTCPending';
import BTCAnalytics from '../components/BTCAnalytics';
import BTCSettings from '../components/BTCSettings';
import {myVC} from '../constants/BTCTestData/BTCVC';
import VCContext from '../components/VCContext';

function BTC(props) {

	const [path, setPath] = useState("/btc");
	const [currentVC, setCurrentVC] = useState(myVC[0]);
	const value = {currentVC, setCurrentVC};

	function changePath(pa) {
		setPath(pa);
	}

	function selectedPath() {

		if (path === "/btc/create-new-instrument") {
			return <BTCCreate changePath = {changePath}/>;
		} else if (path === "/btc/analytics") {
			return <BTCAnalytics changePath = {changePath}/>;
		} else if (path === "/btc/approved") {
			return <BTCApproved changePath = {changePath}/>;
		} else if (path === "/btc/pending") {
			return <BTCPending changePath = {changePath}/>;
		} else if (path === "/btc/settings") {
			return <BTCSettings changePath = {changePath}/>;
		} else  {
			return <BTCLanding changePath = {changePath}/>
		}
	}

	return (
		<VCContext.Provider value = {value}>
			<div className = "container">
				{selectedPath()}
			</div>
		</VCContext.Provider>
	)
}
export default BTC;