import React, {useState, useEffect} from 'react';
import TextField from "@material-ui/core/TextField";
import { utils } from "mui-metablockchain-sdk";

function UndecodedVC(props) {
    const [myVC, setMyVC] = useState(null);
    const [transferDID, setTransferDID] = useState(null);
   
    useEffect(() => {
        setMyVC(props.vc);
    }, [props.vc]);


    function getDetails() {
    if (myVC !== null) {
      return Object.keys(myVC).map(function (item, index) {
        if (item === "is_vc_used") {
            return (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>
                <b>{`${item}`.replace("_", " ").toUpperCase()}</b>
              </td>
              <td>{myVC[item].toString()}</td>
            </tr>
          );
        }
        if (item === "issuers_list") {
            return (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>
                <b>{`${item}`.replace("_", " ").toUpperCase()}</b>
              </td>
              <td>{myVC[item].toString().replace("/,/g"," ")}</td>
            </tr>
          );
        }
        if(item === "timestamp") {
            return (
                <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>
                        <b>CREATED ON</b>
                    </td>
                    <td>{timeStamp(myVC[item].toString())}</td>
                </tr>
            );
        }
        if(item === "owner") {
             return (
                <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>
                        <b>OWNER</b>
                    </td>
                    <td>{utils.hexToString(myVC[item])}</td>
                </tr>
            );
        }
        if(item === "vc_property") {
             return (
                <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>
                        <b>PROPERTIES</b>
                    </td>
                    <td>{decodeVCProperty(myVC[item])}</td>
                </tr>
            );
        } else {
            return (
                <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>
                        <b>{`${item}`.replace("_", " ").toUpperCase()}</b>
                    </td>
                    <td>{myVC[item]}</td>
                </tr>
            );
        }
      } 
      )}
  }

    function decodeVCProperty(item) {
        const cid = utils.decodeHex(item,'SlashMintTokens');
            return (
                <React.Fragment>
                    <b>Currency Id</b>&emsp;{cid.currency_id} <br />
                    <b>Amount</b>&emsp;{cid.amount}
                </React.Fragment>
            );
    }

  function timeStamp(value) {
        if(value) {
            let ind = value.indexOf('T');
            let date = value.substring(0, ind);
            let time = value.substring(ind+1);
            return (
                <React.Fragment><b>Date: </b>{date}&emsp;<b>Time: </b>{time}</React.Fragment>
            );
        }
    }

    function getButtonType() {
        if(myVC !== null && myVC.hasOwnProperty('is_vc_used')) {
            if (myVC.vc_type === "MintTokens") {
                 return (
                    <button
                        className="btn btn-outline-dark"
                        onClick={() => props.vcAction(myVC, "Mint")}
                    >
                        Mint Tokens
                    </button>
                );
            } else if (myVC.vc_type === "SlashTokens") {
                return (
                    <button
                        className="btn btn-outline-dark"
                        onClick={() => props.vcAction(myVC, "Slash")}
                    >
                        Slash Tokens
                    </button>
                );
            } else if (myVC.vc_type === "TokenTransferVC") {
                return (
                    <React.Fragment>
                        <TextField
                            id="outlined-basic"
                            label="DID"
                            variant="outlined"
                            onChange={(e) => setTransferDID(e.target.value)}
                        />
                        <button
                            className="btn btn-outline-dark"
                            onClick={() => props.vcAction(myVC, "Transfer", transferDID)}
                        >
                            Transfer Tokens
                        </button>
                    </React.Fragment>
                );
            }
        }
    }

    return (
        <React.Fragment>
            <div className="mt-3">
                <table className="table">
                    <thead>
                        <tr className="table-info">
                            <th scope="col">S.No</th>
                            <th scope="col">Field Name</th>
                            <th scope="col">Field Value</th>
                        </tr>
                    </thead>
                    <tbody>{getDetails()}</tbody>
                </table>
                <div className="row mt-3 justify-content-center">
                    <div className="col-3 btn-block">{getButtonType()}</div>
                </div>
             </div>
        </React.Fragment>
    );
}

export default UndecodedVC;