import React, {useContext} from 'react';
import {myVC} from '../constants/BTCTestData/BTCVC';
import VCContext from './VCContext';

function BTCVCCard(props) {
	const {currentVC, setCurrentVC} = useContext(VCContext);

	return (
		<React.Fragment>
			<div className = "row justify-content-center">
				<div className = "col-7 pt-3">
					<div className = "card bg-light">
						<div className = "card-body">
							<p className = "text-center">
								Organisation Name: &nbsp; {currentVC.organisationName}<br/>
								Position: &nbsp; {currentVC.position}<br/>
								Clearance Level: &nbsp; {currentVC.clearanceLevel}<br/>
							</p>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default BTCVCCard;
