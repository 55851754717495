import React from 'react'
import styled from "styled-components";

const KycType = (props) => {

    const setComp = () => {
        props.history.push("/new_kyc")
    }
    const setGovtKyc = () => {
        props.history.push("/govt_kyc")
    }
    return (
        <>
        <Heading>
        <h1> Click to select any one of the following to proceed further </h1>
        </Heading>
        <Choose>
            <button onClick={setComp}>
                Yidindji Citizenship KYC Form 
            </button>
            <button onClick={setGovtKyc}> Yidindji Govt. Employee KYC Form </button>

        </Choose>
        
        </>
    )
}

export default KycType
const Choose = styled.div`
margin-top: 10rem;
display: flex;
justify-content: space-around;
align-items: center;
>button {
    height: 3.5rem;
    border: none;
    border-radius: 1rem;
    color: #fff;
    background-color: #2ec4b6;
    font-size: 1.2rem;
}
`
const Heading = styled.div`
margin-top: 8rem;
> h1 {
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
}
`
