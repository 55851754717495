import React from 'react';

function BTCAnalytics(props) {
	return (
		<div className = "container-fluid card" style = {{marginTop: "150px"}}>
			<div className = "card-body">
				<div className = "row justify-content-between bg-light">
					<div className = "col">
						<h4 className = "py-2">
							Analytics
						</h4>
					</div>
					<div className = "col-2 py-2">
						<button className = "btn btn-outline-danger btn-sm" onClick = {() => props.changePath("/btc")}>
							Back To Main
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BTCAnalytics;