import React, {useEffect, useState} from 'react';
import BTCCertificate from './BTCCertificate';
import { Clist } from '../constants/BTCTestData/BTCVC';

function BTCApproved(props) {

	const [filter, setFilter] = useState("");
	useEffect(() => {
		if(filter.length === 0) {
			setFilter("Choose")
		}
	}, [filter]);

	function changeFilter(value) {
		setFilter(value);
	}

	function dropdown() {
		return (
			<div className = "dropdown">
				<button className="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
					{filter}
				</button>
				<ul className = "dropdown-menu" aria-labelledby="filterDropdown">
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("Choose")}>None</button></li>
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("Date Issued")}>Date Issued</button></li>
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("General Business")}>General Business</button></li>
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("Special Appropriations")}>Special Appropriations</button></li>
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("Primary Signatories")}>Primary Signatories</button></li>
					<li><button className="dropdown-item" type="button" onClick = {() => changeFilter("Sponsor Signatories")}>Sponsor Signatories</button></li>
				</ul>
			</div>
		);
	}

	function getApproved() {
		const listItems = Clist.map((item) => 
			<div>
				<BTCCertificate data = {item} />
				<div className = "row justify-content-center my-2">
					<div className = "col-3">
						<button
							type = "button"
							className = "btn btn-info btn-lg btn-block"
						>
							View Details
						</button>
					</div>
				</div>
			</div>
		);

		return (
			<ul className = "p-0">{listItems}</ul>
		);
	}

	return (
		<div className = "container-fluid card" style = {{marginTop: "150px"}}>
			<div className = "card-body">
				<div className = "row justify-content-between bg-light">
					<div className = "col">
						<h4 className = "py-2">
							Approved Certificates
						</h4>
					</div>
					<div className = "col-6 row justify-content-end py-2">
						<div className = "col-5">
							{dropdown()}	
						</div>
						<div className = "col-4">
							<button className = "btn btn-outline-danger" onClick = {() => props.changePath("/btc")}>
								Back To Main
							</button>
						</div>
					</div>
				</div>
				{getApproved()}
			</div>
		</div>
	);
}

export default BTCApproved;