import React, { Component } from "react";
import { Card, Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { GetCall } from "../services/KycService";
import "../styles/Sidemenu_style.css";
import MobileClass from "./MobileClass";
import search_icon from "../Assets/icons/Search.png";



class TableGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_kyc_status: "S",
      selected_button: "S",
      R_Button: false,
      S_Button: true,
      D_Button: false,
      A_Button: false,
      query: "",
      searchString: [],
      users: [],
      filteredUsers: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    const res = await GetCall("getKycList");
    if (res.message) {
      // this.setState({ users: res.message.filter(item => item.hasOwnProperty('kyc_status')), isLoading: false });
      const filterUsers = res.message.filter(item => item.hasOwnProperty('kyc_status') && 
                          (item.hasOwnProperty ("kycType") ? (item.kycType !== "PublicDid" ? true : false ) : true))
      this.setState({ users: filterUsers, isLoading: false });
      const { users } = this.state;
      const submittedUsers = users.filter((e) => e.kyc_status.includes("S"));
      this.setState({ filteredUsers: submittedUsers });
    } else {
      throw new Error("API is not returning the data");
    }
  }

  changeButtonstatus(data) {
    if (data === "A") {
      this.setState({
        A_Button: true,
        S_Button: false,
        D_Button: false,
        R_Button: false,
        selected_button: "A",
      });
      const { users } = this.state;
      const acceptedUsers = users.filter((e) => e.kyc_status.includes("A"));
      // console.log(acceptedUsers);
      this.setState({ filteredUsers: acceptedUsers });
    } else if (data === "D") {
      this.setState({
        A_Button: false,
        S_Button: false,
        D_Button: true,
        R_Button: false,
        selected_button: "D",
      });
      const { users } = this.state;
      const declinedUsers = users.filter((e) => e.kyc_status === "D");
      // console.log(declinedUsers);
      this.setState({ filteredUsers: declinedUsers });
    } else if (data === "S") {
      this.setState({
        A_Button: false,
        S_Button: true,
        D_Button: false,
        R_Button: false,
        selected_button: "S",
      });
      const { users } = this.state;
      const submittedUsers = users.filter((e) => e.kyc_status.includes("S"));
      // console.log(submittedUsers);
      this.setState({ filteredUsers: submittedUsers });
    } else {
      this.setState({
        A_Button: false,
        S_Button: false,
        D_Button: false,
        R_Button: true,
        selected_button: "R",
      });
      const { users } = this.state;
      const reviewUsers = users.filter((e) => e.kyc_status.includes("R"));
      console.log(reviewUsers);
      this.setState({ filteredUsers: reviewUsers });
    }
  }
  handleInputChange = (event) => {
    this.setState({
        query: event.target.value
})
}
   renderTable = () => {
    
      let searchString = this.state.query;
    
     
          // console.log(responseData[i].name);
          // responseData = responseData.filter(searchString);
  
    return this.state.filteredUsers
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
      .map((user, index) => {
        if( user.hasOwnProperty('givenName') && user.givenName.toLowerCase().includes(searchString.toLowerCase()) 
        || user.email === searchString || user.phone_number === searchString
        ){
        const dateString = user.timestamp;
        const indexNumber = index + 1;
        const formatDate = (dateString) => {
          const options = { year: "numeric", month: "numeric", day: "numeric" };
          return new Date(dateString).toLocaleDateString(undefined, options);
        };
        const decodeString = (string) =>{
          if(string) {
            if(string.indexOf('&amp;') !== -1) {
            let newS =  string.replace(/&amp;/g, '&');
            if(newS.indexOf('&amp;') !== -1) {
              decodeString(newS)
            } else {
              return newS;
            }
          } else {
            return string;
          }
          }
        }
    
        const date = formatDate(dateString);
        return (
          <>
            <tbody style={{ textAlign: "center" }}>
              <tr>
                <td>{indexNumber}</td>
                <td>
                  {user.givenName ? decodeString(user.givenName) : decodeString(user.first_name)}{" "}
                  {user.familyName ? decodeString(user.familyName) : decodeString(user.last_name)}
                </td>
                <td> {user.email ? user.email : user.phone_number} </td>
                <td>{date}</td>
                <td>
                  <button
                    className="view_btn"
                    style={btn_details}
                    onClick={() => this.userClick(user.uid)}
                  >
                    View
                  </button>
                </td>
              </tr>
            </tbody>

          </>
        );
      }
      else if(searchString === "") {
        const dateString = user.timestamp;
        const indexNumber = index + 1;
        const formatDate = (dateString) => {
          const options = { year: "numeric", month: "numeric", day: "numeric" };
          return new Date(dateString).toLocaleDateString(undefined, options);
        };
        const decodeString = (string) =>{
          if(string) {
            if(string.indexOf('&amp;') !== -1) {
            let newS =  string.replace(/&amp;/g, '&');
            if(newS.indexOf('&amp;') !== -1) {
              decodeString(newS)
            } else {
              return newS;
            }
          } else {
            return string;
          }
          }
        }
      
    
        const date = formatDate(dateString);
        return (
          <>
            <tbody style={{ textAlign: "center" }}>
              <tr>
                <td>{indexNumber}</td>
                <td>
                  {user.givenName ? decodeString(user.givenName) : decodeString(user.first_name)}{" "}
                  {user.familyName ? decodeString(user.familyName) : decodeString(user.last_name)}
                </td>
                <td> {user.email ? user.email : user.phone_number} </td>
                <td>{date}</td>
                <td>
                  <button
                    className="view_btn"
                    style={btn_details}
                    onClick={() => this.userClick(user.uid)}
                  >
                    View
                  </button>
                </td>
              </tr>
            </tbody>
            </>
        )
      }
      });
  

  };

  userClick = (data) => {
    this.props.history.push("/kyc_user_details", { uid: data });
  };
  render() {
    return (
      <>
        <ButtonST className="btn_st_div">
          <Button
            className="btn_status_div4"
            style={btn_status}
            toggle
            active={this.state.S_Button}
            onClick={() => this.changeButtonstatus("S")}
          >
            SUBMITTED
          </Button>
          <Button
            className="btn_status_div4"
            style={btn_status}
            toggle
            active={this.state.D_Button}
            onClick={() => this.changeButtonstatus("D")}
          >
            DECLINED
          </Button>
          <Button
            className="btn_status_div4"
            style={btn_status}
            toggle
            active={this.state.A_Button}
            onClick={() => this.changeButtonstatus("A")}
          >
            ACCEPTED
          </Button>
        </ButtonST>

        <br />
        <br />
        <div className="nav_input-name">
            
              <input
                className="input_search"
                type="text"
                placeholder="Search Given Name or Email"
                value={this.state.query}
                onChange={this.handleInputChange}
              /> 
              <img src={search_icon} alt="search" className="search_icon_img" /> </div>
        <Wrapper>
          {/* <Card style={cardTable}>
            <Card.Body>
              <Row>
                <Col xs={1}>
                  <p className="para_div">#</p>
                </Col>
                <Col>
                  <p className="para_div">Name</p>
                </Col>
                <Col>
                  <p className="para_div">Email/Mobile Number</p>
                </Col>
                <Col xs={2}>
                  <p className="para_div">Date</p>
                </Col>
                <Col xs={2}>
                  <p className="para_div">Action</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br /> */}
          <Table striped bordered variant="light" responsive="sm">
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>#</th>
                <th>Full Name</th>
                <th>Email/ Mobile Number</th>
                <th>Date</th>
                <th>Look Up</th>
              </tr>
            </thead>
            {this.renderTable()}
          </Table>
        </Wrapper>
      </>
    );
  }
  
}
export default TableGrid;

const cardTable = {
  borderRadius: "15px",
  marginTop: "5px",
  border: "none",
  outline: "none",
};
const Wrapper = styled.div`
  width: 100%;
  background-color: #fafbfc;
  border-radius: 20px;
  padding: 30px;
  @media screen and (min-width: 360px) and (max-width: 767px) {
    font-size: 1rem;
  }
`;
const dropdown_menu = {
  background: "#F2F2F2",
  height: "43px",
  borderRadius: "15px",
  border: "none",
  outline: "none",
};
const btn_status = {
  width: "151px",
  height: "43px",
  background: "#2EC4B6",
  borderRadius: "15px",
  color: "white",
};

const btn_details = {
  background: "#0D9E35",
  borderRadius: "10px",
  width: "100px",
  color: "#fff",
  fontSize: "15px",
  fontWeight: "600",
  border: "none",
  outline: "none",
  padding: "5px",
};
const ButtonST = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (min-width: 360px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
  }
`;
