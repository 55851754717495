import React, {useState, useContext} from 'react';
import GeneralForm from './BTCForms/GeneralForm';
import BTCVCAvailableForms from './BTCVCAvailableForms';
import BTCCertificate from './BTCCertificate';
import VCContext from './VCContext';

function BTCCreate(props) {

	const {currentVC, setCurrentVC} = useContext(VCContext);
	const [page, setPage] = useState(1);
	const defaultForm = {
		instrumentType: "",
		notes: "Pay to the order of Yidindji Department of the Treasury the sum of",
		createdOn: "",
		serialCode: "",
		amount: "",
		exchangeTo: "",
		primarySignatory: currentVC.did,
		sponsorSignatories: []
	}
	const [form, setForm] = useState({...defaultForm});

	function formSelected(name) {
		setForm({...form, instrumentType: name});
		setPage(2);
	}

	function submitForm(input) {
		setForm({...form, ...input});
		setPage(3);
	}

	function back() {
		setPage(1);
		setForm({...form, ...defaultForm});
	}

	function editForm() {
		setPage(2);
	}

	function mintForm() {
		window.alert(JSON.stringify(form));
		back();
	}

	function activePage() {
		if(page === 1) {
			return (
				<React.Fragment>
					<div className = "row justify-content-center text-center pt-5">
						<div className = "col-6">
							<h4>Select Type Of Instrument</h4>
						</div>
					</div>
					<BTCVCAvailableForms formSelected = { formSelected } />;
				</React.Fragment>
			);
		} else if (page === 2) {
			return (
				<GeneralForm 
					back = {back} 
					formData = {form} 
					header = {form.instrumentType + " Certificate"}
					submitForm = {submitForm} 
				/>
			);
		} else if (page === 3) {
			return (
				<React.Fragment>
					<BTCCertificate data = {form} />
					<div className = "row justify-content-center mt-3">
						<div className = "col-3">
							<button
								type = "button"
								className = "btn btn-outline-info btn-lg btn-block"
								onClick = {() => editForm()}
							>
								Edit
							</button>
						</div>
						<div className = "col-3">
							<button
								type = "button"
								className = "btn btn-outline-success btn-lg btn-block"
								onClick = {() => mintForm()}
							>
								Submit
							</button>
						</div>
					</div>
				</React.Fragment>
			);
		}
	}

	return (
		<div className = "container-fluid card" style = {{marginTop: "150px"}}>
			<div className = "card-body">
				<div className = "row justify-content-between bg-light">
					<div className = "col">
						<h4 className = "py-2">
							Create New Instrument
						</h4>
					</div>
					<div className = "col-2 py-2">
						<button className = "btn btn-outline-danger btn-sm" onClick = {() => props.changePath("/btc")}>
							Back To Main
						</button>
					</div>
				</div>
				{activePage()}
			</div>
		</div>
	);
}

export default BTCCreate;