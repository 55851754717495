import React, {useState, useContext } from 'react';
import {Link} from 'react-router-dom';
import {login} from './login';
import Swal from "sweetalert2";
import {Auth} from 'aws-amplify';
import AccountContext from './AccountContext';
import Navbar from './Navbar'

function SSignIn(props) {

  const { setLoggedIn } = useContext(AccountContext)

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [valid, setValid] = useState(false);


  const clickHandler = async() => {
    if(email !== null && password !== null) {
      login(email, password)
      .then((user) => {
        Auth.currentSession()
          .then((data) => {
            if (data.idToken.payload.email_verified) {
              console.log(data)
              localStorage.setItem("publicKycLogin", data.idToken.jwtToken);
              setLoggedIn(true);
              props.history.push("/public-kyc-register");
            }
          })
          .catch((err) => {
            console.log("You are not authorised!");
            Swal.fire(
              "The combination of email and password is wrong. Please try again using a valid email address and password."
            );
          });
      })
      .catch((err) => {
        Swal.fire("Oops...", "Login failed. Please try again!!", "error");
      });
    }
  }
  
  return (
    <div className = "container bg-light">
      <div className = "row mx-auto vh-100">
        <div className = "card shadow col-lg-6 my-auto mx-auto" style = {{height : "600px"}}>
        <img src = "/512PX-X-512PX.png" className = "card-img" style = {{opacity: 0.2, height : "inherit"}} alt = ""/>
          <div className = "card-body card-img-overlay">
            <div className = "row text-center mt-5">
              <div className = "col-12 mb-5">
                <h3>Welcome To Registration Portal</h3>
              </div>
              <div className = "col-12">
                <h3>Sign in to continue...</h3>
              </div>
            </div>
            <div style = {{height : "50px"}}></div>
            <div className = "row mt-3 mb-3 justify-content-center">
              <div className = "col-6">
                <label className = "form-label" htmlFor = "email">Email</label>
                <input type="text" className="form-control" id="email" placeholder = "Enter your email" onChange = {(e) => setEmail(e.target.value)} />
              </div>
            </div>
            <div className = "row mb-3 justify-content-center">
              <div className = "col-6">
                <label className = "form-label" htmlFor = "password">Password</label>
                <input type="password" className="form-control" id="password" placeholder = "Enter your password" onChange = {(e) => setPassword(e.target.value)} />
              </div>
            </div>
            <div className = "row mb-3 justify-content-center text-center">
              <div className = "col-6">
                <button
                  className = "btn btn-outline-primary"
                  type = "button"
                  onClick = {() => clickHandler()}
                  disabled = {email === null || password === null ? true : false}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className = "row mb-2 justify-content-center text-center">
              <div className = "col-6">
                <h5>Or</h5>
              </div>
            </div>
            <div className = "row mb-3 justify-content-center text-center">
              <div className = "col-6">
                <Link to = "/public-kyc-sign-up" style = {{textDecoration : "none"}}><b>Sign Up</b></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function SignIn(props) {
  return (
    <div className = "container-fluid vh-100">
        <header>
          <Navbar history = {props.history}/>
        </header>
        <main>
          <SSignIn history = {props.history} />
        </main>
      </div>
  );
}

export default SignIn