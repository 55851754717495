import  { useState, useEffect } from 'react'

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767)

    useEffect(() => {
      const onSize = () => setIsMobile(window.innerWidth <= 767)
  
      window.addEventListener('resize', onSize)
      return () => {
        window.removeEventListener('resize', onSize)
      }
    },[])
    return isMobile
}
export default useIsMobile;