import React, {useState} from 'react';
import AccountContext from './AccountContext';
import Navbar from './Navbar';
import SignUp from "./SignUp";
import Register from "./Register";
import SignIn from "./SignIn";

function LLanding(props) {
  return (
      <div className = "container bg-light">
        <div className = "row mx-auto vh-100">
          <div className = "card shadow col-lg-6 my-auto mx-auto" style = {{height : "600px"}}>
          <img src = "/512PX-X-512PX.png" className = "card-img" style = {{opacity: 0.2, height : "inherit"}} alt = ""/>
            <div className = "card-body card-img-overlay">
              <div className = "row text-center mt-5">
                <div className = "col-12 mb-5">
                  <h3>Welcome To Registration Portal</h3>
                </div>
                <div className = "col-12 my-5">
                  <button className = "btn btn-outline-primary" onClick = {() => props.history.push('/public-kyc-sign-in')}>Sign In</button>
                </div>
                <div className = "col-12 mb-3">
                  <button className = "btn btn-outline-primary" onClick = {() => props.history.push('/public-kyc-sign-up')}>Sign up</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
  )
}

function Landing(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  const value = {loggedIn, setLoggedIn};
  
  function components() {
    if (props.location.pathname === "/public-kyc-landing") {
      return <LLanding history = {props.history} />
    } else if (props.location.pathname === "/public-kyc-sign-in") {
      return <SignIn history = {props.history} />
    } else if (props.location.pathname === "/public-kyc-sign-up") {
      return <SignUp history = {props.history} />
    } else if (props.location.pathname === "/public-kyc-register") {
      return <Register history = {props.history} />
    }
  }
  
  return (
    <div className = "container-fluid vh-100">
      <AccountContext.Provider value = {value}>
        <header>
          <Navbar history = {props.history} />
        </header>
        <main>
          {components()}
        </main>
      </AccountContext.Provider>
    </div>
  );
}

export default Landing