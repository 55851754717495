import React, { useContext } from 'react';
import {InstrumentDB} from '../constants/BTCTestData/BTCVC';
import VCContext from './VCContext';

function BTCVCAvailableForms(props) {

	const { currentVC, setCurrentVC } = useContext(VCContext);
	let listItems = [];

	InstrumentDB.map((item) => {
		item.primarySignatories.map((signatory) => {
			if(signatory.position === currentVC.position) {
				listItems.push (
					<div className = "row justify-content-center pt-5" key = {item.id}>
						<div className = "col-5">
							<button 
								className = "btn btn-outline-info btn-block btn-lg" 
								type = "button"
								onClick = {() => props.formSelected(item.instrumentType)} 
							>
								{item.instrumentType}
							</button>
						</div>
					</div>
				);
			}
		});
	});
	return <ul className = "p-0">{listItems}</ul>
}

export default BTCVCAvailableForms;