import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Form } from 'react-bootstrap'
import {Auth} from 'aws-amplify';
import Swal from 'sweetalert2'

const UserSignin = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const updateEmail = event => {
        setEmail(event.target.value)

    }
    const updatePassword = event => {
        setPassword(event.target.value)
    }

    const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

     async function handleSubmit(e) {
       
        try {
            e.preventDefault();
           const user =  await Auth.signIn({
                 username: email,
                 password: password
             });
            //  console.log(user);
             props.history.push("/kyctype")

        } catch (error) {
            if (error.code === 'UserNotConfirmedException') {
                return (
                    Swal.fire(

                        "Oops...",
                        "Please verify your SignUp before login ",
                        "error"
                    )
                //  <h1 style={{ color: "red", fontSize: 15, marginTop: 5 }}> Please verify your SignUp before login </h1> 
                )
            } else if (error.code === 'NotAuthorizedException') {
                return (
                    Swal.fire(

                        "Oops...",
                        " Email or Password combination is not correct",
                        "error"
                    )
                    // <h2 style={{ color: "red", fontSize: 15, marginTop: 5 }}>
                    //     Email or Password combination is not correct
                    // </h2>
                )
            }
            console.log('error signing in', error);
        }
    }

    // const emailValid = () => {
    //     return !emailRegex.test(email) ?
    //         (
    //             <h1 style={{ color: "red", fontSize: 15, marginTop: 5 }}> *please enter your valid Email</h1>
    //         ) : (
    //             <h2 style={{ color: "green", fontSize: 15, marginTop: 5 }}> This email is Valid </h2>
    //         );

    // };
    const passValid = () => {
        if (password==='') {
          return null;
        }
          
        return  !strongRegex.test(password) &&
        (
            <h1 className="valid_login_div" style={{ color: "red", fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5 }}>*Minimum 8 charcaters must be there.Include lowercase, uppercase,numbers and special charcaters </h1> 
        ) 
        };

    return (
        <>
            <Input>
                <Row>
                    <Col>

                        <div className="signup-div">
                            <Heading> <h1> Sign In</h1> </Heading>

                            <Form.Group className="mb-3 form-div">
                                <Row>
                                    <Form.Label className="is_name_div">Email / Mobile Number</Form.Label>
                                    <Col md={12}>
                                        <Form.Control
                                            className="input_form_div"
                                            type="text"
                                            id="email"
                                            name="email"
                                            autoComplete="off"
                                            value={email}
                                            onChange={updateEmail}
                                            placeholder="Enter Email or Mobile Number"
                                            required />
                                            {/* {emailValid()} */}
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3 form-div">
                                <Row>
                                    <Form.Label className="is_name_div">Password</Form.Label>
                                    <Col md={12}>
                                        <Form.Control
                                            className="input1"
                                            type="password"
                                            id="password"
                                            name="password"
                                            autoComplete="off"
                                            value={password}
                                            onChange={updatePassword}
                                            placeholder="Enter your Password"
                                            required />
                                            {passValid()}
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Button1 type="submit" onClick={handleSubmit}> Login</Button1>
                        </div>
                    </Col>
                </Row>
            </Input>

        </>
    )
}

export default UserSignin;
const Input = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 35rem;
height: 30rem;
border: 1px solid black;
border-radius: 1rem;
margin-top: 10rem;
padding: 3rem;
@media screen and (min-width: 360px) and (max-width: 767px) {
    margin-top: 15rem;
    width: 22rem;
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
   margin-left: 10rem;
}
@media screen and (min-width: 1201px) {
    margin-left: 10rem;
}
`
const Heading = styled.div`
display: flex;
justify-content: center;
margin-top: 2rem;
margin-bottom: 2rem;
>h1 {
    text-align: center;
    font-size: 2rem;
}
`
const Button1 = styled.div`
width: 8rem;
height: 2.5rem;
background-color: #2ec4b6;
font-size: 1.5rem;
    color: #fff;
    text-align: center;
    border-radius: 1rem;
    margin-top: 2rem;
`
