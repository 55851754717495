export const InstrumentDB = [
	{
		id: 1,
		instrumentType: "General Business",
		primarySignatories: [
			{
				id: 1,
				did: "did:ssid:John",
				position: "Finance Minister",
				clearanceLevel: 4
			},
			{
				id: 2,
				did: "did:ssid:Sara",
				position: "Treasury Minister",
				clearanceLevel: 4
			},
			{
				id: 3,
				did: "did:ssid:Frank",
				position: "Health Minister",
				clearanceLevel: 3
			},
			{
				id: 4,
				did: "did:ssid:Rambo",
				position: "Defence Minister",
				clearanceLevel: 5
			}
		],
		sponsorSignatories: [
			{
				id: 1,
				did: "did:ssid:Modi",
				position: "Chief Minister",
				clearanceLevel: 2
			},
			{
				id: 2,
				did: "did:ssid:Amit",
				position: "Home Minister",
				clearanceLevel: 2
			},
			{
				id: 3,
				did: "did:ssid:Krishna",
				position: "Education Minister",
				clearanceLevel: 2
			},
			{
				id: 4,
				did: "did:ssid:Kisan",
				position: "Agriculture Minister",
				clearanceLevel: 2
			},
			{
				id: 5,
				did: "did:ssid:Adam",
				position: "Governor",
				clearanceLevel: 1
			},
			{
				id: 6,
				did: "did:ssid:Snowden",
				position: "Technology Minister",
				clearanceLevel: 3
			}
		]
	},
	{
		id: 2,
		instrumentType: "Special Appropriations",
		primarySignatories: [
			{
				id: 1,
				did: "did:ssid:John",
				position: "Finance Minister",
				clearanceLevel: 4
			},
			{
				id: 2,
				did: "did:ssid:Sara",
				position: "Treasury Minister",
				clearanceLevel: 4
			},
			{
				id: 3,
				did: "did:ssid:Frank",
				position: "Health Minister",
				clearanceLevel: 3
			},
			{
				id: 4,
				did: "did:ssid:Rambo",
				position: "Defence Minister",
				clearanceLevel: 5
			},
			{
				id: 4,
				did: "did:ssid:Kisan",
				position: "Agriculture Minister",
				clearanceLevel: 2
			},
		],
		sponsorSignatories: [
			{
				id: 1,
				did: "did:ssid:Modi",
				position: "Chief Minister",
				clearanceLevel: 2
			},
			{
				id: 2,
				did: "did:ssid:Amit",
				position: "Home Minister",
				clearanceLevel: 2
			},
			{
				id: 3,
				did: "did:ssid:Krishna",
				position: "Education Minister",
				clearanceLevel: 2
			},
			{
				id: 4,
				did: "did:ssid:Kisan",
				position: "Agriculture Minister",
				clearanceLevel: 2
			},
			{
				id: 5,
				did: "did:ssid:Adam",
				position: "Governor",
				clearanceLevel: 1
			},
			{
				id: 6,
				did: "did:ssid:Snowden",
				position: "Technology Minister",
				clearanceLevel: 3
			}
		]
	},
	{	
		id: 3,
		instrumentType: "Food Procurement",
		primarySignatories: [
			{
				id: 1,
				did: "did:ssid:John",
				position: "Finance Minister",
				clearanceLevel: 4
			},
			{
				id: 2,
				did: "did:ssid:Sara",
				position: "Treasury Minister",
				clearanceLevel: 4
			},
			{
				id: 3,
				did: "did:ssid:Frank",
				position: "Health Minister",
				clearanceLevel: 3
			},
			{
				id: 4,
				did: "did:ssid:Kisan",
				position: "Agriculture Minister",
				clearanceLevel: 2
			},
		],
		sponsorSignatories: [
			{
				id: 1,
				did: "did:ssid:Modi",
				position: "Chief Minister",
				clearanceLevel: 2
			},
			{
				id: 2,
				did: "did:ssid:Amit",
				position: "Home Minister",
				clearanceLevel: 2
			},
			{
				id: 3,
				did: "did:ssid:Krishna",
				position: "Education Minister",
				clearanceLevel: 2
			},
			{
				id: 4,
				did: "did:ssid:Kisan",
				position: "Agriculture Minister",
				clearanceLevel: 2
			},
			{
				id: 5,
				did: "did:ssid:Adam",
				position: "Governor",
				clearanceLevel: 1
			},
			{
				id: 6,
				did: "did:ssid:Snowden",
				position: "Technology Minister",
				clearanceLevel: 3
			}
		]
	}
];

export const myVC = [
	{
		id: 1,
		did: "did:ssid:John",
		organisationName: "Ministry Of Finance",
		position: "Finance Minister",
		clearanceLevel: 4,
		tokenRole: ["Token Admin", "Token Minter", "Token Slasher"]
	},
	{
		id: 2,
		did: "did:ssid:Kisan",
		organisationName: "Ministry Of Food And Agriculture",
		position: "Agriculture Minister",
		clearanceLevel: 2,
		tokenRole: ["Token Minter", "Token Slasher"]
	},
	{
		id: 3,
		did: "did:ssid:Rambo",
		organisationName: "Ministry Of Defence",
		position: "Defence Minister",
		clearanceLevel: 5,
		tokenRole: ["Token Minter", "Token Slasher"]
	}
];

export const Clist = [
	{
		instrumentType: "General Business",
		notes: "Certificate generated by Meta MUI of value",
		createdOn: "25/7/2021",
		serialCode: "875gb4895",
		amount: "500000",
		exchangeTo: "did:ssid:Emily",
		primarySignatory: "did:ssid:Tilly",
		sponsorSignatories: ["did:ssid:Emily","did:ssid:Tilly"]
	},
	{
		instrumentType: "Special Appropriation",
		notes: "Certificate generated by Meta MUI of value",
		createdOn: "25/7/2021",
		serialCode: "875gb4895",
		amount: "500000",
		exchangeTo: "did:ssid:Emily",
		primarySignatory: "did:ssid:Tilly",
		sponsorSignatories: ["did:ssid:Emily","did:ssid:Tilly"]
	},
	{
		instrumentType: "Food Procurement",
		notes: "Certificate generated by Meta MUI of value",
		createdOn: "25/7/2021",
		serialCode: "875gb4895",
		amount: "500000",
		exchangeTo: "did:ssid:Emily",
		primarySignatory: "did:ssid:Tilly",
		sponsorSignatories: ["did:ssid:Emily","did:ssid:Tilly"]
	}
]

export const VCFormsType = [
	{
		role: "Token Admin",
		notes: "",
		tokenName: "",
		tokenSymbol: "",
		approvers: []
	},
	{
		role: "Token Minter",
		notes: "",
		amount: "",
		approvers: []
	},
	{
		role: "Token Slasher",
		notes: "",
		amount: "",
		approvers: []
	}
]

export const FavVC = [
	{
		id: 1,
		did: "did:ssid:John",
		position: "Finance Minister",
		clearanceLevel: 4
	},
	{
		id: 2,
		did: "did:ssid:Sara",
		position: "Treasury Minister",
		clearanceLevel: 4
	},
	{
		id: 3,
		did: "did:ssid:Frank",
		position: "Health Minister",
		clearanceLevel: 3
	},
	{
		id: 4,
		did: "did:ssid:Rambo",
		position: "Defence Minister",
		clearanceLevel: 5
	},
	{
		id: 5,
		did: "did:ssid:Modi",
		position: "Chief Minister",
		clearanceLevel: 2
	},
	{
		id: 6,
		did: "did:ssid:Amit",
		position: "Home Minister",
		clearanceLevel: 2
	},
	{
		id: 7,
		did: "did:ssid:Krishna",
		position: "Education Minister",
		clearanceLevel: 2
	},
	{
		id: 8,
		did: "did:ssid:Kisan",
		position: "Agriculture Minister",
		clearanceLevel: 2
	},
	{
		id: 9,
		did: "did:ssid:Adam",
		position: "Governor",
		clearanceLevel: 1
	},
	{
		id: 10,
		did: "did:ssid:Snowden",
		position: "Technology Minister",
		clearanceLevel: 3
	}
]

//yidindji tool
//creator : did:yidindji:rootadmin (Murumu)
//token name 8 characters
//token symbol
//initial amount (Not needed because its decided by Yidindji)
//Approvers did
//Note Description Text Field
//Submit Button Call an api and save to database
//After Approval Yidindji Admin can see the VC in admin tool
//and the SSID App
//Notification will come after approval
//After approved from metamui admin the vc is generated and
//part of blockchain but the status is inactive
//once all approvers approved the vc will be active
//if no approvers then automatically the vc is active



//SWN Admin Tool (Phantom and Council)
//VC requests
//Token VC
//Approve (Pairwise Trust) = Metamui admin
//----------------------

//MINT/Slash VC
//Select the Active token VC
//Dropdown where Admin user can select the VC (API Call)
//Select Action Type MINT and SLASH either one Radio Button
//Amount - 1 billion or i trillion and should be a number
//Amount in text
//Owner DID to which the vc granted only one owner
//Approvers (optional)
//Submit - Trigger Pairwise trust
//approvers need to approve form their ssid app
//once approved vc is active else inactive
//-----------------------

//Select the VC from dropdown (SDK Call)
//all the data will be filled on the component
//Amount
//Submit -> Pairwise  trust trigger for approval
// Successfully minted the new toke
//the new token will be available on admins wallet