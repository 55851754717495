import axios from 'axios';
import {Auth} from 'aws-amplify';
import { KYC_BUCKET_NAME, BASE_URL } from "../config"

export const GetToken = async()=>{
    const pairwiseLogin = localStorage.getItem('login')
    if(pairwiseLogin !== null){
        return pairwiseLogin
    }else{
      const token = await Auth.currentSession();
      return token.idToken.jwtToken
    }   
}

/**
 * Fetch the presigned url to which upload should happen.
 * @param {String} idToken Secure Token
 * @param {String} fileName Name of file to be uploaded
 * @returns {Promise} Presigned url api promise.
 */
 export const getS3PresignedData = (idToken, fileName, support, mimetype, email, phone_number) => {

    let checkUrl = `${BASE_URL}generatePresignedS3Url`;

    return new Promise((resolve, reject) => {
      // Get the presigned object
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        params: {
          file_name: fileName,
          content_type: mimetype,
          bucket: KYC_BUCKET_NAME,
          email: email,
        phone_number: phone_number,
        },
      };
      if (support) {
        config.params.type = "support";
      }
      axios
        .get(`${checkUrl}`, config)
        .then((res) => resolve({ status: res.status, data: res.data.message }))
        .catch((err) => reject(err));
    });
  };

  /**
 * Upload file to S3 with previously received pre-signed POST data.
 * @param presignedPostData
 * @param file
 * @returns {Promise<any>}
 */
export const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("Content-Type", file.type);
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(presignedPostData.url, formData, config)
        .then((res) => {
          const {
            url,
            fields: { key },
          } = presignedPostData;
          const data = `${url}${key}`;
          // Resolve the file url
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  };
  
  // Object
  // message: "No Token VC Request found."

