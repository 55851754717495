import React from "react";
import { NavLink, Link } from "react-router-dom";
import "../styles/Sidemenu_style.css";
import "../styles/Drop_style.css";
import yidindji_logo from "../Assets/icons/yidindji_logo.png";
import search_icon from "../Assets/icons/Search.png";
import bellIcon from "../Assets/icons/bellIcon.png";
import auth from "../services/loginService";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import Search from "./Search";
import { GetCall, PostCall, GetImage } from "../services/KycService";

const tokens = localStorage.getItem("login");
class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: localStorage.getItem("did") || "Admin",
      transfer_access: false,
      token: null,
    };
  }

  async search(key) {
    console.log(key);
    const res = await GetCall("getUserKycDetails/?uid=").then((data) => {
     data.json().then((resp) => {
        console.log("resp", resp);
        if (resp.length > 0) {
          this.setState({ searchData: resp, noData: false });
        } else {
          this.setState({ noData: true, searchData: null });
        }
      });
    });
  }

  async componentDidMount() {
    // const { attributes } = await Auth.currentAuthenticatedUser();
    let name;
    try {
      const resp = await GetCall("getAdminDetails");
      if(resp.hasOwnProperty('message')) {
        if(resp.message.firstName && resp.message.lastName) {
          name = resp.message.firstName + " " + resp.message.lastName;
        } else {
          name = "Fetching name failed";
        }
        this.setState({ name: name});
      }
    } catch (e) {
      name = "Fetching name failed";
      this.setState({ name: "Fetching name failed" });
    }
    // console.log("SideBar.....", attributes);
    // if (attributes.name) {
    //   this.setState({ name: attributes.name });
    // } else {
    //   var email = attributes.email;
    //   var username =
    //     email.charAt(0).toUpperCase() +
    //     email.substring(1, email.lastIndexOf("@"));
    //   this.setState({ name: username });
    // }

    const ssid_name = localStorage.getItem("did");
    const token = localStorage.getItem("login");
    if (ssid_name) {
      this.setState({ name: name, transfer_access: true, token: token });
      console.log("meeks", this.state.transfer_access);
    }
  }

  state = {
    open: false,
  };
  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };
  render() {
    return !this.name ? (
      <>
        <header className="header" id="header">
          <div className="header_toggle">
            {" "}
            <i className="bx bx-menu" id="header-toggle"></i>
          </div>
          {/* <div className="header_item"> <h5 style={{color:'white'}}>Welcome Admin</h5> </div> */}

          <div className="header_item">
          <Link to="/" className="nav-logo">
            <img
              className="img_logo"
              src={yidindji_logo}
              alt=""
              style={{ height: "80px", width: "80px" }}
            />
          </Link>
          {/* <span className="nav_logo-name">CBDC</span> */}
        </div>
        <div className="row" style={{ flexGrow: "2" }}>
          <div className="col-12 text-center">
            <h2>Yidindji Admin Tool</h2>
          </div>
          <div className="col-11" style={{ paddingLeft: "80px" }}>
            <h5>
              <b>Hi {this.state.name === "Fetching name failed" ? "Admin" : this.state.name}</b> <br />
              <span style={{ color: "gray" }}>Welcome back!</span>
            </h5>
          </div>
        </div>
          {/* <Search /> */}

          {/* <div className="nav_input-name">
            <span>
              <input
                className="input_search"
                type="text"
                placeholder="Search"
                onChange={(event) => this.search(event.target.value)}
              />
              <div>
                {this.state.searchData ? (
                  <div>
                    {this.state.searchData.map((item, i) => (
                      <div> {item.name}</div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                {this.state.noData ? <h6>No data found </h6> : null}
              </div>
              <img
                className="search_logo"
                src={search_icon}
                alt="Search"
                style={{
                  height: "20px",
                  width: "auto",
                }}
              />
            </span>
          </div> */}

          {/* <NewModal /> */}

          {/* <div className="nav_logo-name">
              <span>
                <img className="bell_icon" src={bellIcon} alt="" />
              </span>
            </div> */}
        </header>

        <div className="l-navbar" id="nav-bar">
          <nav className="nav">
            <div>
              {/* <NavLink to="/" className="nav_logo"> <img className="img_logo" src={yidindji_logo} alt="" />
              <span className="nav_logo-name">Yidindji</span> </NavLink> */}
              <div className="nav_list">
                {" "}
                <NavLink to="/" className="nav_link">
                  {" "}
                  <i className="bx bx-grid-alt nav_icon"></i>
                  <span className="nav_name">KYC Dashboard</span>{" "}
                </NavLink>
                {/* <NavLink to="/subusers" className="nav_link">
                    {" "}
                    <i className="bx bx-user nav_icon"></i>{" "}
                    <span className="nav_name">Sub Admin Users</span>{" "}
                  </NavLink>
                  <NavLink to="/support_ticket" className="nav_link"> 
                  <i class='bx bx-message-square-detail nav_icon'></i> <span class="nav_name">Support Tickets</span> </NavLink>  */}
                <NavLink to="/restore_vc_request" className="nav_link">
                  {" "}
                  <i className="bx bx-bookmark nav_icon"></i>{" "}
                  <span className="nav_name">Restore VC</span>{" "}
                </NavLink>
                <NavLink to="/btc" className="nav_link">
                  {" "}
                  <i className="bx bx-file nav_icon"></i>{" "}
                  <span className="nav_name">BTC</span>{" "}
                </NavLink>
                 
                  <NavLink to="/vc" className="nav_link">
                    {" "}
                    <i className="bx bx-id-card nav_icon"></i>{" "}
                    <span className="nav_name">CBDC</span>{" "}
                  </NavLink>
                
                <NavLink to="/public_did_details" className="nav_link">
                  {" "}
                  <i className="bx bx-id-card nav_icon"></i>{" "}
                  <span className="nav_name">Public DID</span>{" "}
                </NavLink>
                {/* <NavLink to="/covid" className="nav_link">
                  {" "}
                  <i className="bx bx-id-card nav_icon"></i>{" "}
                  <span className="nav_name">Covid</span>{" "}
                </NavLink> */}
 
                <Drop>
                  {/* <button
                    type="button"
                    className="nav_link"
                    onClick={this.handleButtonClick}
                  >
                    <i className="bx bx-user nav_icon"></i>
                    <span className="nav_name">User Registration</span>
                  </button> */}
                  {this.state.open && (
                    <Dropdown>
                      <ul>
                        <li>
                          {" "}
                          <Link
                            to="/usersignup"
                            className="up-nav"
                            onClick={this.handleButtonClick}
                          >
                            {" "}
                            Sign Up
                          </Link>
                        </li>
                         {/* <li>
                          <Link
                            to="/usersignin"
                            className="in-nav"
                            onClick={this.handleButtonClick}
                          >
                            {" "}
                            Sign In
                          </Link>
                        </li>  
                         <li>
          <Link to="/otpscreen" className="in-nav"> OTP</Link>
        </li> */}
                      </ul>
                    </Dropdown>
                  )}
                </Drop>
                {tokens !== null && (
                  <>
                    <NavLink to={`/transfer/${tokens}`} className="nav_link">
                      {" "}
                      <i className="bx bx-send nav_icon"></i>{" "}
                      <span className="nav_name">Transfer</span>{" "}
                    </NavLink>
                    <NavLink to={`/transaction/${tokens}`} className="nav_link">
                      {" "}
                      <i className="bx bx-book nav_icon"></i>{" "}
                      <span className="nav_name">Transaction</span>{" "}
                    </NavLink>

                    <NavLink
                      to={`/create-token/${tokens}`}
                      className="nav_link"
                    >
                      {" "}
                      <i className="bx bx-send nav_icon"></i>{" "}
                      <span className="nav_name">Create Token</span>{" "}
                    </NavLink>
                    <NavLink to={`/token-list/${tokens}`} className="nav_link">
                      {" "}
                      <i className="bx bx-book nav_icon"></i>{" "}
                      <span className="nav_name">Token List</span>{" "}
                    </NavLink>
                  </>
                )}
                <NavLink
                  to="/login"
                  onClick={() => {
                    localStorage.removeItem("login");
                    localStorage.removeItem("did");
                    auth.logout();
                    window.location = "/login";
                  }}
                  className="nav_link"
                >
                {" "}
                  <i className="bx bx-log-out nav_icon"></i>{" "}
                  <span className="nav_name">Sign Out</span>{" "}
              </NavLink>
              </div>
            </div>{" "}
          </nav>
        </div>
      </>
    ) : (
      <h1> navbar</h1>
    );
  }
}

export default SideBar;
const Drop = styled.div`
  position: relative;
  margin-left: 0.6rem;
  > button {
    padding: 11px;
    margin-top: 15px;
    border: none;
    border-radius: 15px;
    background-color: #fff;
    color: black;
    cursor: pointer;
    outline: 0;
    font-size: 1rem;
    font-weight: 600;
    > button:hover {
      background-color: #e5f6ef;
    }
  }
`;
const Dropdown = styled.div`
  position: absolute;
  width: 10rem;
  height: 3rem;
  z-index: 2;
  border-radius: 1rem;
  margin-left: 1.2rem;
  margin-top: -1rem;
  border: 1px solid rgba(0, 0, 0, 0.04);
  background-color: #2ec4b6;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  > ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  > li {
    padding: 8px 12px;
  }
  > li:hover {
    cursor: pointer;
  }
`;
