import React, { useState, useEffect } from "react";
import UserDataService from "../services/API_urls";
import styled from 'styled-components'

const EditUsers = props => {
  const initialUserState = {
    id: null,
    name: "",
    email: "",
    number: "",
    published: false
  };
  const [currentUser, setCurrentUser] = useState(initialUserState);
  const [message, setMessage] = useState("");

  const getUser = id => {
    UserDataService.get(id)
      .then(response => {
        setCurrentUser(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getUser(props.match.params.id);
  }, [props.match.params.id]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const updatePublished = status => {
    var data = {
      id: currentUser.id,
      name: currentUser.name,
      email: currentUser.email,
      number: currentUser.number,
      published: status
    };

    UserDataService.update(currentUser.id, data)
      .then(response => {
        setCurrentUser({ ...currentUser, published: status });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updateUser = () => {
    UserDataService.update(currentUser.id, currentUser)
      .then(response => {
        console.log(response.data);
        props.history.push("/subusers");
        setMessage("The User was updated successfully!");
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteUser = () => {
    UserDataService.remove(currentUser.id)
      .then(response => {
        console.log(response.data);
        props.history.push("/subusers");
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <>
      <Main>
        {currentUser ? (
          <div className="edit-form">
            <h4>UPDATE USER DETAILS</h4>
            <Input>
              <form>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={currentUser.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    value={currentUser.email}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="number">Mobile Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="number"
                    name="number"
                    value={currentUser.number}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label>
                    <strong>Status:</strong>
                  </label>
                  {currentUser.published ? "Published" : "Pending"}
                </div>
              </form>

              {currentUser.published ? (
                <button
                  className="badge badge-primary mr-2"
                  onClick={() => updatePublished(false)}
                >
                  UnPublish
                </button>
              ) : (
                <button
                  className="badge badge-primary mr-2"
                  onClick={() => updatePublished(true)}
                >
                  Publish
                </button>
              )}

              <button className="badge badge-danger mr-2" onClick={deleteUser}>
                Delete
              </button>

              <button
                type="submit"
                className="badge badge-success"
                onClick={updateUser}
              >
                Update
              </button>

              <p>{message}</p>
            </Input>
          </div>

        ) : (
          <div>
            <br />
            <p>Please click on a User...</p>
          </div>
        )}
      </Main>
    </>
  );
};

export default EditUsers;

const Main = styled.div`
width: 60rem;
height: 40rem;
margin-top: 10rem;
>div {
>h4 {
    width: 30rem;
    height: 4rem;
    text-align: center;
    background-color: #7e5737;
   color: #fff;  
  font-size: 1.7rem;
  padding-top: 1rem;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
  font-weight: 600;
  margin-left: 5rem;
  border-radius: 1.5rem;
}
}
`
const Input = styled.div`
 >form {
   color: blue;
   font-size: 2rem;
   margin-left: 5rem;
   width: 30rem;
   >input {
    
   }
 }
 >button {
    width: 7rem;
    height: 3rem;
    margin-left: 5rem;
    border: none;
    border-radius: 1rem;
    font-size: 1.1rem;
  }
`
