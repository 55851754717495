import React, {useState} from 'react';
import { Form, Field } from "react-final-form";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";

function SignUpForm(props) {

  const [resCall, setRC] = useState(false)

	const onSubmit = async (values) => {
    try {
        const { user } = await Auth.signUp({
          username: values.email,
          password: values.password,
          attributes: {
            name: values.first_name + " " +values.last_name, // optional
            birthdate: values.dob, // optional - E.164 number convention
            // other custom attributes
          },
        });

        Swal.fire(
          "Success!",
          "Please check given Email or Mobile Number and verify OTP",
          "success"
        );
        props.setEmail(values.email)
        props.setOd(true)
      } catch (error) {
        if (error.code === "UsernameExistsException") {
          Swal.fire(
            "Oops...",
            "Email or phone number already exists, Please try with different Email or phone number",
            "error"
          );
        } 
        console.log("ERROR")
      }
	}

  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );


  function resolve(values) {
    setRC(true)
    let vE = emailRegex.test(values.email);
    let vP = strongRegex.test(values.password);

    if(!vE) {
      if(!vP) {
        return {
          email : "Invalid Email",
          password : "Minimum 8 characters. Include lowercase, uppercase,numbers and special charcaters"
        }
      } else {
        return {
          email : "Invalid Email"
        }
      }
    } else if(!vP) {
      return {
        password : "Minimum 8 characters. Include lowercase, uppercase,numbers and special charcaters"
      }
    }

  }

	let formData = {
		first_name: "",
		last_name: "",
    dob: "",
    email: "",
    password : "",
    confirm_password : "",
    mobile_no : ""
	};

	return (
		<div>
			
				<Form
					onSubmit = {onSubmit}
					initialValues = {{...formData}}
					validate = {(values) => {
						const errors = {};
						if (!values.first_name) {
							errors.first_name = "Cannot be blank";
						} 
            if (!values.last_name) {
              errors.last_name = "Cannot be blank"
            }
            if (!values.dob) {
							errors.dob = "Cannot be blank";
						} 
            if (!values.email) {
              errors.email = "Cannot be blank"
            }
            if (!values.password) {
              errors.password = "Cannot be blank"
            }
            if (!values.confirm_password) {
							errors.confirm_password = "Cannot be blank";
						} 
            if(values.password !== values.confirm_password) {
              errors.password = "Password didn't match"
              errors.confirm_password = "Password didn't match";
            }
            return Object.keys(errors).length ? errors : resolve(values);
					}}
					render = {({handleSubmit, form, submitting, pristine, values }) => (
						<form onSubmit = {handleSubmit}>
						    <Field name = "first_name">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "first_name" className = "form-label">Enter First Name</label>
										<input {...input} 
											type = "text" 
											id="first_name"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                      placeholder = "First Name"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
							<Field name = "last_name">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "last_name" className = "form-label">Enter Last Name</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="last_name" 
											placeholder = "Last Name"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
              <Field name = "dob">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "dob" className = "form-label">Enter Date Of Birth</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="dob" 
											placeholder = "DD/MM/YYYY"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
              <Field name = "email">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "email" className = "form-label">Enter Email</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="email" 
											placeholder = "Enter email to recieve otp"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
              <Field name = "password">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "password" className = "form-label">Enter Password</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="password" 
											placeholder = "New Password"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
              <Field name = "confirm_password">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "confirm_password" className = "form-label">Confirm Password</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="confirm_password" 
											placeholder = "Confirm Password"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
							<div className = "row justify-content-center">
								<div className = "col-3 mt-3">
									<button 
										className = "btn btn-outline-primary btn-block"
										type = "submit"
										disabled = {submitting}
									>
										Proceed
									</button>
								</div>
							</div>
						</form>
					)}
				/>		
			</div>
	)
}

export default SignUpForm;