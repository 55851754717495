import { Auth } from "aws-amplify";
import {PERMITTED_USER_GROUP} from "../config"
const awsConfig = require("../config").AWS_COGNITO_CONFIG()

// init amplify with config
Auth.configure(awsConfig);

export async function login(email, password) {
  //console.log(validationData)
  return Auth.signIn(email, password).then(response => {
    //console.log(response);
    
   
    return response;
  }).catch(e => { console.log(e); return e;})
}
