import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { isDev, KYC_STATUS_STRING } from "../config";
import { Button } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Row } from "reactstrap";
import styled from "styled-components";
import StatusCard from "../components/StatusCard";
import { Table } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { GetCall } from "../services/KycService";
import TableGrid from "../components/TableGrid";

class KycDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_kyc_status: "S",
      selected_button: "S",
      R_Button: false,
      S_Button: true,
      D_Button: false,
      A_Button: false,
      users: [],
      filteredUsers: [],
      isLoading: true,
    };
  }

  //    async  componentDidMount(){
  //       const res = await GetCall('/getKycList');
  //       console.log(res)
  //       if(res.message){
  //         this.setState({users:res.message, isLoading:false})
  //         const { users } = this.state;
  //         const submittedUsers = users.filter((e) => e.kyc_status.includes("S"));
  //         this.setState({ filteredUsers: submittedUsers });
  //       }else{
  //         throw new Error('API is not returning the data')
  //       }

  //   }

  // changeButtonstatus(data){
  //   if(data === 'A'){
  //     this.setState({A_Button:true,S_Button:false,D_Button:false,R_Button:false, selected_button:'A'})
  //     const { users } = this.state;
  //     const acceptedUsers = users.filter((e) => e.kyc_status.includes("A"));
  //     console.log(acceptedUsers)
  //     this.setState({ filteredUsers: acceptedUsers });
  //   }else if(data === 'D'){
  //     this.setState({A_Button:false,S_Button:false,D_Button:true,R_Button:false, selected_button:'D'})
  //     const { users } = this.state;
  //     const declinedUsers = users.filter((e) => e.kyc_status.includes("D"));
  // console.log(declinedUsers)
  //     this.setState({ filteredUsers: declinedUsers });
  //   }else if(data === 'S'){
  //     this.setState({A_Button:false,S_Button:true,D_Button:false,R_Button:false , selected_button:'S'})
  //     const { users } = this.state;
  //     const submittedUsers = users.filter((e) => e.kyc_status.includes("S"));
  // console.log(submittedUsers)
  //     this.setState({ filteredUsers: submittedUsers });
  //   }else{
  //     this.setState({A_Button:false,S_Button:false,D_Button:false,R_Button:true, selected_button:'R'})
  //     const { users } = this.state;
  //     const reviewUsers = users.filter((e) => e.kyc_status.includes("R"));
  // console.log(reviewUsers)
  //     this.setState({ filteredUsers: reviewUsers });
  //   }
  // }

  // renderTable = () => {
  //   return this.state.filteredUsers.map((user,index) => {

  //     const dateString = user.timestamp;
  //     const formatDate = (dateString) => {
  //       const options = { year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }
  //       return new Date(dateString).toLocaleDateString(undefined, options)
  //     }
  //     const date = formatDate(dateString);
  //     return (

  //       <tr key={index}>
  //         <td>{index}</td>
  //       <td>{user.givenName ? user.givenName : user.first_name} {user.familyName? user.familyName: user.last_name}</td>
  //         <td>{user.email}</td>
  //         <td>{date}</td>
  //         <td><button className="btn btn-primary" onClick={()=>this.userClick(user.uid)} >View</button></td>
  //       </tr>
  //     )
  //   })
  // }

  // userClick = (data)=>{
  //   console.log('sachin murali',this.props.history)
  //   // this.props.history.push("/kyc_user_details",{'uid':data});
  // }

  render() {
    window.scrollTo({ top: 0 });
    return (
      <React.Fragment>
        {/* <Main>
            <h3 className="text-center">KYC Dashboard</h3>
            <p className="text-center">{(isDev ? "DEVELOPMENT | TESTNET" : "PRODUCTION | MAINNET")}</p>
            <Newkyc>
        <NavLink to="/new_kyc" className="new_link"> New User KYC Form</NavLink>
         </Newkyc>
            </Main> */}
        <StatusCard />

        {/* <StatusButton >
            <Row>

          <h4 className="text-center" style={{color: 'white'}}> {this.state.isLoading?<Loader type="ThreeDots" color="white"/>:'KYC STATUS'}</h4>
          <br />

            </Row>
            </StatusButton> */}

        {/* <select name="" id="" style={dropdown_menu}>
              <option value="">All KYC</option>
              <option value="">Submitted KYC</option>
              <option value="">Rejected KYC</option>
            </select>

            <Button.Group style={btn_group}>
            <Button style={btn_status} toggle active={this.state.S_Button} onClick={() => this.changeButtonstatus('S')}>SUBMITTED</Button>
                <Button toggle active={this.state.R_Button} onClick={() => this.changeButtonstatus('R')}>REVIEW</Button>
                <Button style={btn_status} toggle active={this.state.D_Button} onClick={() => this.changeButtonstatus('D')}>DECLINED</Button>
                <Button style={btn_status} toggle active={this.state.A_Button} onClick={() => this.changeButtonstatus('A')}>ACCEPTED</Button>
            </Button.Group> */}

        <br />
        <br />
        {/* <h6>KYC requests with status : {KYC_STATUS_STRING[this.state.selected_button]}</h6> */}
        {/* <hr /> */}
        {this.state && !this.state.showLoader && (
          //   <Table striped bordered hover>
          //   <thead>
          //     <tr>
          //       <th>#</th>
          //       <th>Name</th>
          //       <th>Email</th>
          //       <th>Date</th>
          //       {/* <th>Country</th> */}
          //       <th>Action</th>
          //     </tr>
          //   </thead>
          //   <tbody>
          //   {this.renderTable()}
          //   </tbody>
          // </Table>
          <TableGrid history={this.props.history} />
        )}
      </React.Fragment>
    );
  }
}

export default KycDashboard;
const Main = styled.div`
  margin-top: 10px;
  height: 200px;
  width: 100%;
  background-color: #fafbfc;
  border-radius: 20px;
  position: relative;
  top: 50px;

  > h3 {
    font-size: 30px;
    font-weight: 600;
    width: 100%;
  }
  > p {
    font-size: 15px;
    color: red;
    width: 100%;
  }
`;

const StatusButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: #7e5737;
  margin-top: 1.7rem;
  padding: 1.3rem;
`;
const Newkyc = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  > a {
    width: 15rem;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    text-align: center;
    background-color: #21ba45;
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
    padding-top: 0.2rem;
  }
`;
const dropdown_menu = {
  background: "#F2F2F2",
  height: "43px",
  borderRadius: "15px",
  border: "none",
  outline: "none",
};
const btn_status = {
  width: "151px",
  height: "43px",
  background: "#2EC4B6",
  borderRadius: "15px",
  color: "white",
  marginRight: "10px",
};
const btn_group = {
  position: "relative",
  left: "35%",
  transform: "translate(-50%)",
};
