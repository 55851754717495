import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Navbar from './Navbar';
import SignUpForm from './SignUpForm';
import SignUpPhone from './SignUpPhone';
import {Auth} from 'aws-amplify'
import Swal from 'sweetalert2'

function SSignUp(props) {

  const [moe, setMoe] = useState("email");

  const [email, setEmail] = useState("")

  const [od, setOd] = useState(false)

  const [otp, setOtp] = useState("");

  const  confirmSignUpOtp = async() => { 
    try {
      await Auth.confirmSignUp(email ,otp);
      props.history.push("/public-kyc-sign-in");
    } catch (error) {
      Swal.fire('Something Went Wrong')
      console.log("ERROR");
    } 
  }


  return (
    <div className = "container bg-light vh-100" style = {{overflow : "auto"}}>
      <div className = "row mx-auto">
        <div className = "card shadow col-lg-6 my-auto mx-auto" style = {{height : "900px"}}>
        <img src = "/512PX-X-512PX.png" className = "card-img" style = {{opacity: 0.2, height : "inherit"}} alt = ""/>
          <div className = "card-body card-img-overlay">
            <div className = "row text-center mt-5 justify-content-center">
              <div className = "col-12 mb-5">
                <h3>Welcome To Registration Portal</h3>
              </div>
              {/* <div className = "col-6 mb-3">
                <button className = {`btn btn-outline-primary ${moe === "mobile_no" ? "active" : ""}`} onClick = {() => setMoe("mobile_no")}>
                  Sign up with phone
                </button>
              </div> */}
              <div className = "col-6 mb-3">
                <button className = {`btn btn-outline-primary ${moe === "email" ? "active" : ""}`} onClick = {() => setMoe("email")}>
                  Sign up with email
                </button>
              </div>
            </div>
            { !od ? (
              moe === "email" ? <SignUpForm type = {moe} setOd = {setOd} setEmail = {setEmail}/> : <SignUpPhone type = {moe} setOd = {setOd} setEmail = {setEmail}/>
             ): 
             <>
             <div className = "row justify-content-center my-5">
               <div className = "col-6">
                 <label className = "form-label" htmlFor = "otp">Enter OTP received</label>
                 <input type="text" className="form-control" id="otp" placeholder = "Enter OTP" onChange = {(e) => setOtp(e.target.value)} />
               </div>
             </div>
              <div className = "row justify-content-center text-center my-5">
              <div className = "col-6">
                <button className = "btn btn-outline-success" onClick = {() => confirmSignUpOtp()}>Submit OTP</button>
              </div>
            </div>
            </>
            }
            <div className = "row mt-3 mb-2 justify-content-center text-center">
              <div className = "col-6">
                <h5>Or</h5>
              </div>
            </div>
            <div className = "row justify-content-center text-center">
              <div className = "col-6 mb-5">
                <Link to = "public-kyc-sign-in" style = {{textDecoration : "none"}}><b>Sign In</b></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function SignUp(props) {
  return (
    <div className = "container-fluid vh-100">
        <header>
          <Navbar history = {props.history}/>
        </header>
        <main>
          <SSignUp history = {props.history} />
        </main>
      </div>
  );
}

export default SignUp