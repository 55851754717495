import React, {useState, useEffect} from 'react';

function CovidYid(props) {

  const [certNo, setCertNo] = useState(null);

  useEffect(() => {
    console.log(certNo)
  }, [certNo])

  function implSearch() {
   props.history.push(`/covid_portal/${certNo}`);
  }

  return (
    <div className  = "container" style = {{paddingTop: "60px"}}>
      <div className = "row text-center">
        <div className = "col">
          <div className = "card">
            <img src = "/512PX-X-512PX.png" className = "card-img" style = {{opacity: 0.2, height: "350px"}} alt = ""/>
            <div className = "card-body card-img-overlay">
              <div className = "row text-center" style = {{height: "350px"}}>
					      <div className = "col my-auto">
						      <h3><b>Sovereign Yidindji Government Covid Portal</b></h3>
					      </div>
				      </div>
            </div>
          </div>
        </div>
      </div>
      <div className = "row mt-3">
        <div className = "col">
          <label htmlFor = "certNo" calssName ="form-label"><b>Enter Certificate Number</b></label>
          <input
            className = "form-control"
            id = "certNo"
            placeholder = "Enter Certificate Number"
            type = "text"
            onChange = {(e) => setCertNo(e.target.value)}
          />
        </div>
      </div>
      <button 
        className = "btn btn-outline-primary mt-3"
        onClick = {() => implSearch()}
      >
        Search
      </button>
    </div>
  );
}

export default CovidYid;